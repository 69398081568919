import { C_COURSES_SET_COURSES } from '../reducers/courses';
import { db } from '../../../utils/fire';

export const setCourses = (payload) => ({
  type: C_COURSES_SET_COURSES,
  payload,
});

const coursesRef = db.collection('courses');

export const setBySlug = (slug) => async (dispatch) => {
  try {
    const coursesResult = await coursesRef.where('slug', '==', slug).get();
    const courses = coursesResult.docs.map((doc) => doc.data());

    dispatch(setCourses(courses));
  } catch (e) {
    console.error(e);
  }
};

export const setCoursesByIds = (ids) => async (dispatch) => {
  try {
    if (!ids || !ids.length) return;

    const coursesResult = await coursesRef.where('courseId', 'in', ids).get();
    const courses = coursesResult.docs.map((doc) => doc.data());

    dispatch(setCourses(courses));
  } catch (e) {
    console.error(e);
  }
};

export const setAllCourses = () => async (dispatch) => {
  try {
    const coursesResult = await coursesRef.get();
    const courses = coursesResult.docs.map((doc) => doc.data());

    dispatch(setCourses(courses));
  } catch (e) {
    console.error(e);
  }
};

export const setCourseById = (id) => async (dispatch, getState) => {
  try {
    const course = await coursesRef.doc(id).get();

    dispatch(setCourses([course.data()]));
  } catch (e) {
    console.error(e);
  }
};
