import React from 'react';
import PropTypes from 'prop-types';
import { Left, Right, TitleWrap } from './styles';

export const TitleSlice = ({
  left, right, small, withoutMargin,
}) => (
  <TitleWrap withoutMargin={withoutMargin}>
    <Left small={small}>{left}</Left>
    <Right small={small} dangerouslySetInnerHTML={{ __html: right }} />
  </TitleWrap>
);

TitleSlice.propTypes = {
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  small: PropTypes.bool,
};
