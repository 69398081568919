import React, { useState } from 'react';
import {
  CERTIFICATE, QUESTIONNAIRE, TEST, TEST_BLOCKED,
} from 'utils/events/types';
import { BLOCKED_HOURS } from 'utils/events/test_blocked';
import moment from 'moment';
import { getFormatedDateFromTimestamp, formatMoment } from 'utils/momentUtils';
import { getFileLinkFromFirebasePath, getObjectURLLink } from 'admin/store/actions/logsActions';
import { Button } from '@material-ui/core';
import { EXAM_TYPE } from 'utils/events/questionnaire';
import {
  Block, DateBlock, Status, Footer, Link,
} from './styles';

const getDateFromTimestamp = (timestamp) => (timestamp
  ? getFormatedDateFromTimestamp(timestamp)
  : 'Невідома дата');

const QuestionnaireLog = ({
  questionnaireTitle, __timestamp, resultId, courseTitle, success, type,
}) => (
  <Block>
    <div>
      <div>
        Анкета:
        {' '}
        {questionnaireTitle}
      </div>
      {
        type === EXAM_TYPE && (
          <div>
            Яка прив'язана до курсу:
            {' '}
            {courseTitle}
          </div>
        )
      }
    </div>
    <Status status={success}>
      {
      success ? 'Пройшов' : 'Відмовився'
    }
    </Status>
    <DateBlock>
      {
          getDateFromTimestamp(__timestamp)
        }
    </DateBlock>
    <Footer>
      Результат:
      {' '}
      {
        success ? (
          <Link to={`/admin/questionnaire-result/${resultId}`}>
            {resultId}
          </Link>
        ) : 'Відмовився'
      }
    </Footer>
  </Block>
);

const TestLog = ({
  status, resultId, title, __timestamp,
}) => (
  <Block>
    <div>{title}</div>
    <Status status={status}>
      {
          status
            ? 'Успішно!'
            : 'Неуспішно!'
        }
    </Status>
    <DateBlock>
      {
          getDateFromTimestamp(__timestamp)
        }
    </DateBlock>
    <Footer>
      Результат:
      {' '}
      <Link to={`/admin/test-result/${resultId}`}>
        {resultId}
      </Link>
    </Footer>
  </Block>
);

const UknownLog = ({ __timestamp }) => (
  <Block>
    <Status status={false}>Помилка</Status>
    <div>Невідомий лог!</div>
    <DateBlock>
      {
        getDateFromTimestamp(__timestamp)
      }
    </DateBlock>
  </Block>
);

const TestBlockedLog = ({ __timestamp }) => (
  <Block>
    <Status status={false}>Блокування доступу до тесту</Status>

    <div>
      Діє з
      {' '}
      {
          formatMoment(moment.unix(__timestamp))
      }
      {' '}
      по
      {' '}
      {
          formatMoment(moment.unix(__timestamp).add(BLOCKED_HOURS, 'h'))
      }
    </div>

    <DateBlock>
      {
        getDateFromTimestamp(__timestamp)
      }
    </DateBlock>
  </Block>
);

const CertificateLog = ({
  __timestamp, title, pathToCertificate, user,
}) => {
  const [link, setLink] = useState(null);

  const getLinkHandler = (e) => {
    getFileLinkFromFirebasePath(pathToCertificate)
      .then((link) => getObjectURLLink(link))
      .then((link) => setLink(link));
  };

  return (
    <Block>
      <Status status>Отримано сертифікат!</Status>

      <div>
        {title}
      </div>

      <DateBlock>
        {
            getDateFromTimestamp(__timestamp)
          }
      </DateBlock>

      <Footer>
        {
          link
            ? (
              <Button
                component="a"
                variant="outlined"
                color="primary"
                href={link}
                download={`${user.name}_${user.lastName}.pdf`}
              >
                Завантажити
              </Button>
            )
            : (
              <Button onClick={getLinkHandler} variant="outlined">
                Отримати посилання
              </Button>
            )
        }
      </Footer>
    </Block>
  );
};

const LogStrategy = {
  [TEST]: TestLog,
  [TEST_BLOCKED]: TestBlockedLog,
  [CERTIFICATE]: CertificateLog,
  [QUESTIONNAIRE]: QuestionnaireLog,
};

export default function Log({ log, user }) {
  const {
    __timestamp, __action,
  } = log;

  const Component = LogStrategy[__action];

  if (!Component) {
    return (
      <UknownLog __timestamp={__timestamp} />
    );
  }

  return (
    <Component user={user} {...log} />
  );
}
