import styled from 'styled-components';
import { FormControlLabel, Radio as UiRadio, Checkbox as UiCheckbox } from '@material-ui/core';

export const ControLabel = styled(FormControlLabel)`
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;

    color: #000000;

    & > span:first-child {
        margin-right: 26px;
    }

    &:not(:last-child) {
        padding-bottom: 24px;
    }
`;

export const Radio = styled(UiRadio)`
    &&.Mui-checked {
        color: #1499FE;
    }
`;

export const Checkbox = styled(UiCheckbox)`
    &&.Mui-checked {
        color: #1499FE;
    }
`;
