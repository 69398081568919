import React from 'react';
import { TableList, TableTitle } from '../Table';
import Page from '../Page';
import { HeaderButton } from '../Header/Header';
import {
  NotElemetsMessage,
} from './styles';

export default function PageWithList({
  listItems = [], tableTitle, title, createButtonProps, filter, pagination
}) {
  return (
    <Page
      title={title}
      headerButton={(<HeaderButton {...createButtonProps}>Створити</HeaderButton>)}
    >
      <TableTitle>{tableTitle}</TableTitle>
      <TableList>
        {filter && (filter)}
        {pagination && (pagination)}
        {
          listItems.length === 0
            ? <NotElemetsMessage>Елементів не знайдено</NotElemetsMessage>
            : (
              <>
                { listItems }
              </>
            )
        }
        {pagination && (pagination)}
      </TableList>
    </Page>
  );
}
