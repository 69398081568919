import { createTestLog, createTestLogTitle } from 'utils/events/test';
import { checkIsTestBlocked, generateTestBlockedLog } from 'utils/events/test_blocked';
import {
  SET_QUESTIONS,
  SET_PASSED_TEST,
  SET_ID_RESULT_TEST,
  SET_LOADER_TEST,
} from '../reducers/test';
import {
  generateQuestionsLecture,
} from '../../../utils/randomFunctions';

import { db } from '../../../utils/fire';
import { saveLog } from '../../../utils/events/utils';
import { setTestBlocked } from './logsAction';

export const setQuestion = (payload) => ({
  type: SET_QUESTIONS,
  payload,
});

export const setIsPassedTest = (payload) => ({
  type: SET_PASSED_TEST,
  payload,
});

export const setIdPassedTest = (payload) => ({
  type: SET_ID_RESULT_TEST,
  payload,
});

export const setLoaderTest = (payload) => ({
  type: SET_LOADER_TEST,
  payload,
});

// получение вопросов для теста
export const getQuestionFromTest = (id, type = 'lecture') => async (dispatch) => {
  try {
    dispatch(setLoaderTest(true));

    let result = [];
    if (type === 'lecture') {
      // eslint-disable-next-line no-use-before-define
      const questions = await loadLectureQuestions(id);
      result = [...questions];
    } else if (type === 'lesson') {
      // eslint-disable-next-line no-use-before-define
      const data = await loadQuestions(id);
      result.push(...data);
    } else if (type === 'course') {
      const data = await loadLessonInCourse(id);
      result.push(...data);
    }
    dispatch(setQuestion(result));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(setLoaderTest(false));
  }
};

// Получение информации прошел ли пользователь тест
export const isPassedTestUser = (id) => async (dispatch, getState) => {
  try {
    // data
    const state = getState();
    const { uid } = state.client.user.user;

    const resultTestRef = db.collection('resultsTest');
    const isHasResultTest = await resultTestRef.where('idUser', '==', uid).where('resourceTestId', '==', id).get();

    let resultTest = {};
    isHasResultTest.forEach((item) => {
      resultTest = {
        ...item.data(),
        id: item.id,
      };
    });
    const hasObject = Object.keys(resultTest).length > 0;

    dispatch(setIsPassedTest(hasObject));
    dispatch(setIdPassedTest(resultTest.id));
  } catch (e) {
    console.log(e);
  }
};

// Отправка теста
export const sendResultTest = ({
  id,
  answersUser,
  questionsAnswers,
  status,
  course,
  lesson,
  lecture,
  type,
}) => async (dispatch, getState) => {
  try {
    // data
    const state = getState();
    const { uid } = state.client.user.user;
    const { questions } = state.client.test;
    const { test_blocked } = state.client.logs;

    const resultTestRef = db.collection('resultsTest');

    const data = {
      status,
      idUser: uid,
      resourceTestId: id,
      questions: JSON.stringify(questions),
      answersUser: JSON.stringify(answersUser),
      questionsAnswers: JSON.stringify(questionsAnswers),
    };

    const result = await resultTestRef.add(data);
    const log = createTestLog({
      status,
      type,
      userId: uid,
      resultId: result.id,
      title: createTestLogTitle({
        course, lesson, lecture, type,
      }),
    });

    await saveLog(log);

    if (test_blocked === true || status !== false) return;

    // check test block
    const test_blocked_result = await checkIsTestBlocked(uid);

    if (test_blocked_result) {
      await saveLog(generateTestBlockedLog(uid));
      dispatch(setTestBlocked(true));
    }
  } catch (e) {
    console.log(e);
  }
};

async function loadLessonInCourse(idCourse) {
  const courseRef = db.collection('courses').doc(idCourse);
  const courseResult = await courseRef.get();
  const courseData = courseResult.data();
  const lessonsId = courseData.lessons;
  const lessonsSlug = [];
  const lessonsRef = db.collection('lessons');
  // const resultsData = [];
  // Получение slug Модуля для дальнейшего поиска в базе
  for (let i = 0, lengthLesson = lessonsId.length; i < lengthLesson; i++) {
    const data = await lessonsRef.doc(lessonsId[i]).get();
    lessonsSlug.push(data.data().slug);
  }

  const totalQuestionCount = 30;
  const counterQuestions = totalQuestionCount / lessonsSlug.length;

  const resultsQuestions = [];
  for (let i = 0, lengthSlug = lessonsSlug.length; i < lengthSlug; i++) {
    const data = await loadQuestions(lessonsSlug[i], counterQuestions);
    resultsQuestions.push(...data);
  }

  const resultsLength = resultsQuestions.length;
  if (resultsLength < totalQuestionCount) {
    return generateQuestionsLecture(resultsQuestions, resultsLength);
  }
  return generateQuestionsLecture(resultsQuestions, totalQuestionCount);
}

// Загрузка вопросов для лекции
async function loadQuestions(idLesson, totalValueQuestion = 10) {
  const lecturesList = await loadAllLectureFromLesson(idLesson);
  const questions = [];
  let totalCountQuestionInArray = 0; // Количество всех вопросов из лекций

  for (let i = 0, lengthLecture = lecturesList.length; i < lengthLecture; i++) {
    // eslint-disable-next-line no-use-before-define,no-await-in-loop
    const questionData = await loadQuestionsFromLecture(lecturesList[i]);
    questions.push(questionData);
    totalCountQuestionInArray += questionData.length;
  }

  // Если елементов не хватает для заполнение необходимого количества
  if (totalCountQuestionInArray < totalValueQuestion) {
    const allQuestions = [];
    questions.forEach((item) => {
      allQuestions.push(...item);
    });

    return generateQuestionsLecture(allQuestions, totalCountQuestionInArray);
  }

  // Количество вопросов сколько нужно взять для заполнения нужного количества
  const counterQuestions = totalValueQuestion / questions.length;

  // Проверка есть ли необходимое количество в каждом из масивов
  const findElementIndex = questions.findIndex((item) => item.length < counterQuestions);
  if (findElementIndex > -1) {
    const totalArray = questions[findElementIndex];
    const findElementLength = questions[findElementIndex].length;
    const difference = counterQuestions - findElementLength;

    questions.forEach((item, index) => {
      if (index !== findElementIndex) {
        const resultGenerate = generateQuestionsLecture(item, counterQuestions + difference);
        totalArray.push(...resultGenerate);
      }
    });
    return generateQuestionsLecture(totalArray, totalValueQuestion);
  }

  const totalArray = []; // финальный масив вопросов
  questions.forEach((item) => {
    const resultGenerate = generateQuestionsLecture(item, counterQuestions);
    totalArray.push(...resultGenerate);
  });

  return generateQuestionsLecture(totalArray, totalValueQuestion);
}

async function loadAllLectureFromLesson(idLesson) {
  // Получение лекций из всех Модулей
  const lessonRef = db.collection('lessons').where('slug', '==', idLesson);
  const resultSearch = await lessonRef.get();
  const lecturesList = [];
  resultSearch.forEach((lecture) => {
    lecture.data().lectures.forEach((lectureItem) => {
      lecturesList.push(lectureItem);
    });
  });

  return lecturesList;
}

// Загрузка всех вопросов для лекции
async function loadLectureQuestions(id, count = 3) {
  // eslint-disable-next-line no-use-before-define
  const questionData = await loadQuestionsFromLecture(id, true);

  return generateQuestionsLecture(questionData, count);
}

async function loadQuestionsFromLecture(id, hasSlug = false) {
  let idLecture = id;
  if (hasSlug) {
    const searchLecture = await db.collection('lectures').where('slug', '==', id).get();
    searchLecture.forEach((item) => {
      idLecture = item.data().id;
    });
  }

  const resultData = await db.collection('questions').where('lectureId', '==', idLecture).get();
  const questionsArray = [];
  resultData.forEach((item) => {
    questionsArray.push({
      ...item.data(),
      id: item.id,
    });
  });

  return questionsArray;
}
