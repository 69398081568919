import styled from 'styled-components';
import CustomInput from 'cabinet/components/ui/CustomInput/CustomInput';
import { makeStyles } from '@material-ui/core';
import { CardUI } from '../Profile/styles';

export const useStyles = makeStyles({
  inputControl: {
    margin: 0,
    padding: 0,
  },
  starLabel: {
    color: 'inherit',
  },
  buttonsBox: {
    '@media (max-width: 550px)': {
      flexFlow: 'column wrap',
    },
  },
});

export const Input = CustomInput;

export const Card = styled(CardUI)`
  padding: 20px;
`;

export const CardTitle = styled.h4`

`;
