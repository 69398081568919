import {
  Box,
  Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import AccordionDialog from 'admin/components/AccordionDialog';
import UserTable from 'admin/components/UserTable';
import { getAbsolutePathToFile, getUserData } from 'admin/store/actions/logsActions';
import React from 'react';
import { useDispatch } from 'react-redux';

const BlockTitle = (props) => (<Box paddingLeft={2} component="h4" {...props} />);

export default function Certificate({
  renderData, email, user, showUser,
}) {
  const dispatch = useDispatch();

  const getLinkHandler = (index) => () => {
    dispatch(getAbsolutePathToFile(index, email));
  };

  const loadUserHandler = () => {
    dispatch(getUserData(email));
  };

  return (
    <AccordionDialog
      title={email}
    >
      <Box paddingBottom={2}>
        {
          showUser
            ? (
              <>
                <BlockTitle>Користувач:</BlockTitle>
                <UserTable {...user} />
              </>
            )
            : (
              <Grid container justify="flex-end">
                <Box paddingRight={2}>
                  <Button variant="outlined" onClick={loadUserHandler}>Запросити користувача</Button>
                </Box>
              </Grid>
            )
        }
      </Box>
      <BlockTitle>Сертифікати:</BlockTitle>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Дата</TableCell>
              <TableCell>Назва</TableCell>
              <TableCell>Завантажити</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              renderData.map(({
                title, absolutePathToFile, fileName, date,
              }, i) => (
                <TableRow key={i}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{title}</TableCell>

                  <TableCell>
                    {
                      absolutePathToFile
                        ? (
                          <Button
                            component="a"
                            variant="outlined"
                            color="primary"
                            href={absolutePathToFile}
                            download={fileName}
                          >
                            Завантажити
                          </Button>
                        )
                        : (
                          <Button onClick={getLinkHandler(i)} variant="outlined">
                            Отримати посилання
                          </Button>
                        )
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionDialog>
  );
}
