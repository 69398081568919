import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  tabs: {
    paddingBottom: 2,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  mainInputs: {
    '& .MuiTextField-root:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
  wrap: {
    '& .ql-tooltip.ql-editing': {
      zIndex: 1201,
    },
  },
  boxBottom: {
    '&': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));
