import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Page from '../Page';
import { LoaderWrapper } from './styles';

export default function ContentLoader(props) {
  return (
    <Page {...props}>
      <LoaderWrapper>
        <CircularProgress size={40} />
      </LoaderWrapper>
    </Page>
  );
}
