import Modal from 'react-modal';
import styled, { css } from 'styled-components';

Modal.setAppElement('#root');

export const MyModal = styled(Modal)`
  position: absolute;
  top: 38px;
  left: 50%;

  width: 51.875%;
  max-width: 747px;
  max-height: 625px;
  transform: translate(-50%);

  overflow: auto;
  outline: none;

  background: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);

  @media (max-height: 900px ) and (min-width: 1279.95px) {
    bottom: 20px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 70%;
    }

    ${theme.breakpoints.down('xs')} {
      width: 90%;
    }
  `}
`;

MyModal.defaultProps = {
  style: {
    overlay: {
      zIndex: 1200,
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
  },
};

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 26px;

  border: none;
  outline: none;
  background: transparent;

  cursor: pointer;

  &:hover > svg > path {
    fill: rgba(20,153,254,0.2);
  }
`;

export const Wrap = styled.div`
  position: relative;

  padding: 50px 78px 73px;

  
  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      padding: 38px 50px 45px;
    }

    ${theme.breakpoints.down('xs')} {
      padding: 25px 39px 36px;
    }
  `}
`;

export const Title = styled.div`
  padding-bottom: 28px;

  font-family: "Roboto Slab", sans-serif;
  font-size: 25px;
  line-height: 33px;
  font-style: normal;
  font-weight: normal;

  text-align: center;

  color: #000000;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      font-size: 24px;
      line-height: 24px;
    }

    ${theme.breakpoints.down('xs')} {
      padding-bottom: 14px;
    }
  `}
`;

export const Description = styled.div`
  padding-bottom: 34px;

  font-family: "Roboto Slab", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;

  color: #000000;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      font-size: 16px;
      line-height: 18px;
    }

    ${theme.breakpoints.down('xs')} {
      padding-bottom: 17px;
    }
  `}
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 337px;

  border: none;

  ${({ theme }) => css`
  ${theme.breakpoints.down('md')} {
    height: 300px;
  }

  ${theme.breakpoints.down('xs')} {
    height: 180px;
  }
`}
`;
