import * as yup from 'yup';
import { db } from '../../../utils/fire';

export const title = yup.string().required('Найменування обов\'язковий параметр');
export const subTitle = yup.string().required('Супроводжуючий текст обов\'язковий параметр!');
export const description = yup.string().required('Короткий опис обов\'язковий параметр!');

export const slug = yup.string().required('Slug-id обов\'язковий параметр!').notOneOf(['test'], 'Slug-id не може бути значеням test!').matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Не валідний Slug-id!');

export const validate = (afteSchema, beforeSchema) => async (data, isAfter) => {
  try {
    if (isAfter) {
      await afteSchema.validate(data, { abortEarly: false });
    } else {
      await beforeSchema.validate(data, { abortEarly: false });
    }

    return { success: true };
  } catch (e) {
    return { success: false, errors: e.inner };
  }
};

export const slugWithCheckDb = (collection) => slug.test('slugIsNotAvailable', 'Такий Slug-id не унікальний!', async (slug) => {
  try {
    const dbResult = await db.collection(collection).where('slug', '==', slug).get();
    const result = dbResult.docs.map((doc) => doc.data());

    return !result.length;
  } catch (e) {
    console.error(e);
  }
});
