import moment from 'moment';
import { mapResultToDocs } from 'utils/fire';
import { TEST } from './types';
import { generateLog, getSearchDate, whereUserId } from './utils';

const createCourseLogTitle = ({ course }) => `Іспит-${course.title}`;

const createLessonLogTitle = ({ course, lesson }) => `Контрольний Тест-${course.title}-${lesson.title}`;

const createLectureLogTitle = ({ course, lesson, lecture }) => `Тест-${course.title}-${lesson.title}-${lecture.title}`;

const logTitleStrategy = {
  course: createCourseLogTitle,
  lesson: createLessonLogTitle,
  lecture: createLectureLogTitle,
};

/**
 *
 * @param {{ course, lesson, lecture, type: 'course' | 'lesson' | 'lecture' }} titleData
 */
export const createTestLogTitle = (titleData) => {
  const handler = logTitleStrategy[titleData.type];

  if (!handler) {
    throw new Error('Error: invalid type');
  }

  return handler(titleData);
};

export const createTestLog = ({
  title, type, resultId, userId, status,
}) => {
  const data = {
    type,
    resultId,
    status,
    title,
  };

  return generateLog(data, TEST, userId);
};

export const get12hTimestampt = () => {
  const momentDate = moment();
  momentDate.subtract(12, 'hour');

  return momentDate.unix();
};

export const get12hTestLogsByUserId = async (userId) => {
  const eventsResult = await whereUserId(userId)
    .where('__timestamp', '>=', get12hTimestampt())
    .get();

  const docs = mapResultToDocs(eventsResult);

  return docs;
};

export const getTodayTestLogsByUserId = async (userId) => {
  const eventsResult = await whereUserId(userId)
    .where('__searchDate', '==', getSearchDate())
    .get();

  const docs = mapResultToDocs(eventsResult);

  return docs;
};
