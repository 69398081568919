import { v4 as uuid } from 'uuid';
import { SET_NOTIFICATION, REMOVE_NOTIFICATION, SET_NOTIFICATIONS } from '../reducers/notification';

/**
 *
 * @param {{ text: string, variant?: "default" | "success" | "error" | "warning" | "info" }} param0
 */
export const setNotification = ({ text, variant }) => ({
  type: SET_NOTIFICATION,
  payload: {
    data: {
      text,
      variant,
    },
    id: uuid(),
  },
});

export const setNotifications = (payload) => ({
  type: SET_NOTIFICATIONS,
  payload,
});

export const removeNotification = (payload) => ({
  type: REMOVE_NOTIFICATION,
  payload,
});
