import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  border-width: 1px;
`;

export const Legend = styled.legend`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.60);
`;
