import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  TableItem,
  TableItemTitle,
  TableItemButtons,
  TableEditButton,
} from '../../components/Table';
import { getCoursesFromDataBase } from '../../store/actions/coursesAction';
import PageWithList from '../../components/PageWithList';

export default function Courses() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { coursesList } = useSelector((state) => state.admin.courses);

  useEffect(() => {
    dispatch(getCoursesFromDataBase());

    // dispatch
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWithList
        title="Курси"
        tableTitle="Список курсів"
        createButtonProps={{
          onClick: () => history.push('/admin/course/created'),
          style: {
            display: 'none',
          },
        }}
        listItems={coursesList.map((item, i) => (
          <TableItem key={i}>
            <TableItemTitle>
              { item.title }
            </TableItemTitle>

            <TableItemButtons>
              <Link to={{ pathname: `/admin/course/${item.courseId}/edit` }}>
                <TableEditButton />
              </Link>
              {/* <TableDeleteButton onClick={() => confirmDeleteCours(item)} /> */}
            </TableItemButtons>
          </TableItem>
        ))}
      />
    </>
  );
}
