import { setNotification } from 'store/actions/notificationAction';
import { questionnaireResultsCollection } from 'utils/fire';
import { error } from 'utils/logger';
import { A_SET_QUESTIONNAIRE_RESULT } from '../reducers/questionnaireResult';

const setQuestionnaireResult = (payload) => ({
  type: A_SET_QUESTIONNAIRE_RESULT,
  payload,
});

export const setQuestionnaireResultById = (id) => async (dispatch) => {
  try {
    const surveResult = await questionnaireResultsCollection.doc(id).get();
    const data = { ...surveResult.data(), id: surveResult.id };

    dispatch(setQuestionnaireResult(data));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час загрузки результата анкети', variant: 'error' }));
  }
};
