import React from 'react';
import PropTypes from 'prop-types';
import subLogo1 from '../../../assets/img/sub_logo_1.png';
import subLogo2 from '../../../assets/img/sub_logo_2.png';
import {
  Wrap, Container, SupTitle, SubTitle, SecondTitle,
} from './styles';

export const PageSubTitle = ({ children, subWithoutLogos }) => (
  <SubTitle>
    {
      subWithoutLogos
        ? children
        : (
          <>
            <span>{children}</span>
          </>
        )
    }
  </SubTitle>
);

export const Page = ({
  children, supTitle, subTitle, supStyles, supBig, paddingBottom, header, secondTitle, subWithoutLogos,
}) => (
  <Wrap paddingBottom={paddingBottom}>
    <Container>
      {
          header && header
        }
      {
            supTitle && (
            <SupTitle padding={!subTitle || subTitle == ''} styles={supStyles} big={supBig}>
              {supTitle}
            </SupTitle>
            )
        }

      {
            subTitle && subTitle !== '' && (
              <PageSubTitle subWithoutLogos={subWithoutLogos}>
                {subTitle}
              </PageSubTitle>
            )
        }

      {
            secondTitle && (
              <SecondTitle>
                {secondTitle}
              </SecondTitle>
            )
      }

      {children}
    </Container>
  </Wrap>
);

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,

  subTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  supTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  supStyles: PropTypes.string,
  supBig: PropTypes.bool,
  paddingBottom: PropTypes.string,
};
