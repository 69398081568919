import React from 'react';
import { FormControl, RadioGroup } from '@material-ui/core';
import Input from '../../../components/Input';
import { Container, Title } from './styles';

export default function Question({
  index = 0,
  question = '',
  type = 'radio',
  responses = [],
  value = [null],
  onChange,
}) {
  const radioHandleChange = (e, value) => {
    onChange(type, value);
  };

  const checkboxHandleChange = (e, value) => {
    const isCheck = e.target.checked;
    const resultValue = isCheck ? value : '';
    onChange(type, resultValue, value);
  };

  /* if key === (question + i) => view bug */
  const inputs = responses.map((answer, i) => (
    <Input
      type={type}
      key={index + question + i}
      label={answer}
      value={i}
      onChange={(e) => checkboxHandleChange(e, i)}
    />
  ));

  return (
    <Container>
      <Title>
        {index + 1}
        .
        {' '}
        {question}
      </Title>
      <FormControl component="fieldset">
        {
          type === 'radio'
            ? (
              <RadioGroup aria-label="responses" name="responses" value={value[0]} onChange={radioHandleChange}>
                {inputs}
              </RadioGroup>
            )
            : inputs
        }
      </FormControl>
    </Container>
  );
}
