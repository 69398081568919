import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(RouterLink)`
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-variant: small-caps;
    text-transform: uppercase;

    color: #000000;

    &:hover {
        color: inherit;
    }
`;

export const ButtonWrap = styled.div`
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-variant: small-caps;
    text-transform: uppercase;

    color: #000000;

    &:hover {
        color: inherit;
    }
`;
