export const SET_USER_LOGS = 'SET_USER_LOGS';
export const SET_DASHBOARD_STATE = 'SET_DASHBOARD_STATE';

const initialState = {
  user: null,
  dashboard: null,
};

export const logsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_LOGS:
      return { ...state, user: [...payload] };
    case SET_DASHBOARD_STATE:
      return { ...state, dashboard: { ...payload } };
    default:
      return state;
  }
};
