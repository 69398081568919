import {
  SET_UN_SELECTED_QUESTION,
  SET_LECTURE_QUESTIONS, SET_ADD_QUESTION_FROM_LECTURE,
} from '../reducers/tests';

import firebase from '../../../utils/fire';

const db = firebase.firestore();

export const setUnSelectedQuestion = (payload) => ({
  type: SET_UN_SELECTED_QUESTION,
  payload,
});

export const setLectureQuestions = (payload) => ({
  type: SET_LECTURE_QUESTIONS,
  payload,
});

export const removeQuestionUnSelected = (payload) => ({
  type: SET_ADD_QUESTION_FROM_LECTURE,
  payload,
});

export const getAllUnSelectedQuestions = () => async (dispatch) => {
  try {
    const questionRef = db.collection('questions');
    const dataQuestion = await questionRef.get();
    const resultQuestion = [];
    dataQuestion.forEach((question) => {
      const data = question.data();

      if (data.lectureId === 0 || !!data.lectureId === false) {
        resultQuestion.push({
          ...data,
          id: question.id,
        });
      }
    });

    dispatch(setUnSelectedQuestion(resultQuestion));
  } catch (e) {
    console.log(e);
  }
};

export const loadSelectedQuestion = (lectureId) => async (dispatch) => {
  try {
    const resultData = await db.collection('questions').where('lectureId', '==', lectureId).get();
    const resultQuestions = [];
    resultData.forEach((item) => {
      resultQuestions.push({
        ...item.data(),
        id: item.id,
      });
    });

    dispatch(setLectureQuestions(resultQuestions));
  } catch (e) {
    console.log(e);
  }
};

export const addQuestionFromLecture = (idLecture, questionId) => async () => {
  try {
    const lectureRef = db.collection('lectures').doc(idLecture);
    const lecture = await lectureRef.get();
    const data = lecture.data();
    const uniqueQuestion = data.questions || [];

    const searchIndex = uniqueQuestion.findIndex((item) => item === questionId);
    if (searchIndex === -1) {
      uniqueQuestion.push(questionId);
    }
    await lectureRef.update({
      ...data,
      questions: uniqueQuestion,
    });

    // update data question
    const questionRef = db.collection('questions').doc(questionId);
    await questionRef.update({
      lectureId: idLecture,
    });

    // dispatch(removeQuestionUnSelected(questionId));
  } catch (e) {
    console.log(e);
  }
};

export const removeQuestionInLecture = (lectureId, questionId) => async (dispatch) => {
  try {
    const lectureRef = await db.collection('lectures').doc(lectureId);
    const lectureResponse = await lectureRef.get();
    const questionRef = db.collection('questions').doc(questionId);
    await questionRef.update({
      lectureId: 0,
    });

    // data lectures
    const lecturesData = lectureResponse.data();
    const filterQuestions = lecturesData.questions.filter((item) => item !== questionId);
    await lectureRef.update({
      questions: filterQuestions,
    });

    // dispatch(removeQuestionUnSelected(questionId));
  } catch (e) {
    console.log(e);
  }
};
