import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';

import { Alert } from '@material-ui/lab';
import {
  useStyles,
} from './styles';
import {
  addQuestionFromLecture,
  getAllUnSelectedQuestions,
  removeQuestionInLecture,
  setLectureQuestions,
} from '../../../../store/actions/createdTestAction';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  marginBottom: '10px',
  // change background colour if dragging
  background: isDragging ? 'silver' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'transparent' : 'transparent',
  width: '100%',
  height: '100%',
});

const DragContainer = ({ lecture }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { unSelectedQuestion, lectureQuestions } = useSelector((state) => state.admin.test);

  const [state, setState] = useState({
    items: [],
    selected: [],
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(getAllUnSelectedQuestions());

    return () => dispatch(setLectureQuestions([]));

    // dispatch
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setState({
      items: unSelectedQuestion,
      selected: lectureQuestions,
    });
  }, [unSelectedQuestion, lectureQuestions]);

  const id2List = {
    droppable: 'items',
    droppable2: 'selected',
  };

  const getList = (id) => state[id2List[id]];

  const addToLecture = (questionId) => {
    dispatch(addQuestionFromLecture(lecture.id, questionId));
  };

  const removeToLecture = (questionId) => {
    dispatch(removeQuestionInLecture(lecture.id, questionId));
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      let data = { items };
      if (source.droppableId === 'droppable2') {
        data = { selected: items };
      }

      setState({
        ...state,
        ...data,
      });
    } else {
      if (lecture === '') {
        setError('Оберіть лекцію');
        return;
      }

      if (source.droppableId === 'droppable2') {
        removeToLecture(draggableId);
      } else {
        addToLecture(draggableId);
      }

      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );

      setState({
        items: result.droppable,
        selected: result.droppable2,
      });
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <>
      {
        error && (
          <Alert severity="error" className={classes.alert}>
            { error }
          </Alert>
        )
      }
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Grid item lg={5}>
              <Box p={1} className={classes.box}>
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {
                    state.items.length === 0
                      ? (
                        <strong>Запитань більше немає</strong>
                      )
                      : (
                        state.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Card
                                className={classes.card}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                <CardContent className={classes.cardContent}>
                                  {item.question}
                                </CardContent>
                              </Card>
                            )}
                          </Draggable>
                        ))
                      )
                  }

                  {provided.placeholder}
                </div>
              </Box>
            </Grid>
          )}
        </Droppable>
        <Grid item lg={1} />
        <Droppable droppableId="droppable2">
          {(provided, snapshot) => (
            <Grid item lg={5}>
              <Box p={1} className={classes.box}>
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {
                    state.selected.length === 0 && (
                      <strong>Перетягніть питання сюди</strong>
                    )
                  }
                  {state.selected.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Card
                          className={classes.card}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <CardContent className={classes.cardContent}>
                            {index + 1}
                            .
                            {item.question}
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </Box>
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DragContainer;
