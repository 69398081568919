import {
  SET_GET_ALL_QUESTION,
  SET_NEW_QUESTION,
  SET_QUESTION_MESSAGE,
  SET_QUESTION_DATA,
  SET_LOADING_QUESTION,
  SET_REMOVE_QUESTION,
  SET_LOADIND_QUESTIONS,
} from '../reducers/tests';
import firebase from '../../../utils/fire';

const db = firebase.firestore();

export const setAllQuestion = (payload) => ({
  type: SET_GET_ALL_QUESTION,
  payload,
});

export const setLoadingQuestions = (payload) => ({
  type: SET_LOADIND_QUESTIONS,
  payload,
});

export const addNewQuestion = (payload) => ({
  type: SET_NEW_QUESTION,
  payload,
});

export const setMessageQuestion = (payload) => ({
  type: SET_QUESTION_MESSAGE,
  payload,
});

export const setQuestionData = (payload) => ({
  type: SET_QUESTION_DATA,
  payload,
});

export const setQuestionLoading = (payload) => ({
  type: SET_LOADING_QUESTION,
  payload,
});

export const setRemoveQuestion = (payload) => ({
  type: SET_REMOVE_QUESTION,
  payload,
});

export const getAllQuestions = () => async (dispatch) => {
  try {
    dispatch(setLoadingQuestions(true));

    const questionRef = db.collection('questions');
    const resultResponse = await questionRef.get();
    const resultQuestions = [];
    resultResponse.forEach((question) => {
      resultQuestions.push({
        ...question.data(),
        id: question.id,
      });
    });

    dispatch(setAllQuestion(resultQuestions));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(setLoadingQuestions(false));
  }
};

export const addQuestion = (data) => async (dispatch) => {
  try {
    dispatch(setQuestionLoading(true));

    const result = await db.collection('questions').add(data);

    dispatch(addNewQuestion({
      ...data,
      id: result.id,
    }));
    dispatch(setMessageQuestion({
      message: 'Питання успішно додано',
    }));
    return result;
  } catch (e) {
    console.log(e);
    dispatch(setMessageQuestion({
      type: 'error',
      message: 'Сталась помилка при створення, спробуйте ще раз',
    }));
  } finally {
    dispatch(setQuestionLoading(false));
    setTimeout(() => {
      dispatch(setMessageQuestion({
        type: 'success',
        message: null,
      }));
    }, 2500);
  }
};

export const getQuestion = (id) => async (dispatch) => {
  try {
    const questionRef = db.collection('questions').doc(id);
    const result = await questionRef.get();
    dispatch(setQuestionData({
      ...result.data(),
      id: result.id,
    }));
  } catch (e) {
    console.log(e);
  }
};

export const updateQuestionInDataBase = (id, data) => async (dispatch) => {
  try {
    dispatch(setQuestionLoading(true));

    const questionRef = db.collection('questions').doc(id);
    await questionRef.update(data);
    dispatch(setMessageQuestion({
      message: 'Дані успішно оновлені',
    }));
  } catch (e) {
    console.log(e);
    dispatch(setMessageQuestion({
      type: 'error',
      message: 'Сталась помилка при оновленні, спробуйте ще раз',
    }));
  } finally {
    dispatch(setQuestionLoading(false));

    setTimeout(() => {
      dispatch(setMessageQuestion({
        type: 'success',
        message: null,
      }));
    }, 2500);
  }
};

export const removeQuestion = (id) => async (dispatch) => {
  try {
    const lectureRef = db.collection('lectures');
    const lectureData = await lectureRef.where('questions', 'array-contains', id).get();
    const questionLecture = [];
    let idLecture = '';
    lectureData.forEach((item) => {
      idLecture = item.id;
      questionLecture.push(...item.data().questions);
    });

    const newQuestionsId = questionLecture.filter((item) => item !== id);
    if (idLecture !== '') {
      await lectureRef.doc(idLecture).update({
        questions: newQuestionsId,
      });
    }
    const questionRef = db.collection('questions').doc(id);
    const result = await questionRef.delete();

    dispatch(setRemoveQuestion(id));
    dispatch(setMessageQuestion({
      message: 'Питання видалено',
    }));

    return result;
  } catch (e) {
    console.log(e);
  } finally {
    setTimeout(() => {
      dispatch(setMessageQuestion({
        type: 'success',
        message: null,
      }));
    }, 2500);
  }
};
