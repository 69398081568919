import styled, { css } from 'styled-components';
import Card from 'cabinet/components/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ERROR, SEMIERROR, SUCCESS } from './ResultQuestion';

export const useStyles = makeStyles({
  root: {
    '&.Mui-checked svg': {
      fill: '#1e98fd',
    },
  },
  rootGreen: {
    '&.Mui-checked svg': {
      fill: '#29804b',
    },
  },
  user_success: {
    '&.Mui-checked svg': {
      fill: 'rgba(41, 128, 75, .7)',
    },
  },
  success: {
    '&.Mui-checked svg': {
      fill: '#29804b',
    },
  },
  error: {
    '&.Mui-checked svg': {
      fill: 'red',
    },
  },
  icon: {
    backgroundColor: 'red',
  },
});

export const Container = styled(Card)`
  .MuiFormControlLabel-label.Mui-disabled {
    color: #aaaaaa;
  }

  && {
    margin: 0;
    padding: 38px 86px 32px 86px;

    ${(props) => props.theme.breakpoints.down('xs')} {
      padding-left: 46px;
      padding-right: 46px;
    }
  }
`;

export const Title = styled.div`
  font-family: "Roboto Slab", "Roboto", sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
`;

export const Message = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;

  font-size: 14px;
  font-weight: 500;

  ${({ status }) => status === ERROR && css`
    color: red;
  `}
  
  ${({ status }) => status === SEMIERROR && css`
  color: red;
`}

  ${({ status }) => status === SUCCESS && css`
    color: #29804b;
  `}
`;
