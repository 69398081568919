import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import Header from '../../components/Header';
import { Container } from '../../components/Container/Container';
import {
  Wrapper,
  NumberBlock,
  Title,
  Description,
  BottomWrapper,
} from './styles';

const PageNotFound = () => (
  <>
    <Header />
    <Container>
      <Wrapper>
        <NumberBlock>
          404
        </NumberBlock>
        <Title>
          Сторінку не знайдено
        </Title>
        <Description>
          Нажаль сторінка яку ви запрошуєте не знайдено
        </Description>
        <BottomWrapper>
          <Link to="/dashboard">
            <HomeIcon />
          </Link>
        </BottomWrapper>
      </Wrapper>
    </Container>
  </>
);

export default PageNotFound;
