import React from 'react';
import bg from 'assets/img/content-lecture-bg.png';
import { ReactComponent as CheckIcon } from 'assets/img/check.svg';
import { Link, Redirect, useParams } from 'react-router-dom';
import { PageSubTitle } from 'cabinet/components/Page';
import ContentLoader from '../../components/ContentLoader';
import Header from '../../components/Header';
import {
  Wrapper,
  Container,
  FrameBlock,
  CardLecture,
  TitleBlock,
  TitleValue,
  TitleDescription,
  LectureList,
  LectureItem,
  LectureName,
  CheckBlock,
  CheckIconBlock,
  Description,
  ControllTest,
  ContentBlock,
} from './styles';
import { SupTitle } from '../../components/Page/styles';
import { useLesson } from './useLesson';
import { getCourseState, getLessonState } from '../../../utils/userStoryUtils';

export default function Lesson() {
  const params = useParams();

  const { lessonSlug, courseSlug } = params;

  const {
    loading, lesson, course, lectures, userStory, redirect, lessons, user,
  } = useLesson(courseSlug, lessonSlug);

  if (redirect) return (<Redirect to="/" />);

  if (loading) return (<ContentLoader />);

  const { title, subTitle, courseId } = course;
  const { lectures: lecturesIds, id: lessonId } = lesson;
  const { isSuperUser } = user;

  const courseState = getCourseState(course, lessons, userStory, isSuperUser);

  // if dont have access
  if (courseState[lessonId].status === 'disabled') return (<Redirect to="/" />);

  const courseStory = userStory.courses[courseId] || {};
  const lessonsStory = courseStory.lessons || {};
  const lessonStory = !lessonsStory[lessonId] ? null : lessonsStory[lessonId];

  const lessonState = getLessonState(course, lesson, lectures, userStory, isSuperUser);

  const isTestInActive = courseStory?.complete
    ? false
    : lessonStory?.complete
      ? false
      : isSuperUser
        ? false
        : !!lecturesIds.find((id) => lessonState[id].status !== 'done');

  return (
    <>
      <Header />

      <Wrapper $paddingBottom>

        <Container>
          <SupTitle>{title}</SupTitle>
          <PageSubTitle>{subTitle}</PageSubTitle>
        </Container>

        <FrameBlock url={bg}>
          <CardLecture>

            <TitleBlock>
              <TitleValue>Зміст</TitleValue>
              <TitleDescription>
                {
                  lecturesIds.length > 1
                    ? `${lecturesIds.length} лекції`
                    : `${lecturesIds.length} лекція`
                }
              </TitleDescription>
            </TitleBlock>

            <LectureList>
              {
                lecturesIds.map((id, i) => {
                  const { title, slug, status } = lessonState[id];

                  return (
                    <LectureItem status={status} key={id}>
                      <Link to={`/${courseSlug}/${lessonSlug}/${slug}`}>
                        <CheckBlock>
                          <CheckIconBlock>
                            <CheckIcon />
                          </CheckIconBlock>
                        </CheckBlock>

                        <ContentBlock>
                          <LectureName>
                            Лекція
                            {' '}
                            {''}
                            {i + 1}
                          </LectureName>
                          <Description>{title}</Description>
                        </ContentBlock>
                      </Link>
                    </LectureItem>
                  );
                })
              }
            </LectureList>

            <ControllTest status={isTestInActive && 'disabled'}>
              <Link to={`/${courseSlug}/${lessonSlug}/test`}>Контрольний тест</Link>
            </ControllTest>
          </CardLecture>
        </FrameBlock>
      </Wrapper>
    </>
  );
}
