import React, { createContext, useContext, useState } from 'react';
import {
  Paper, makeStyles, createStyles, Box, Tabs,
} from '@material-ui/core';
import clsx from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
  tabs: {
    paddingBottom: 2,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  wrap: {
    '& .ql-tooltip.ql-editing': {
      zIndex: 1201,
    },
  },
}));

export const TabContext = createContext();

export const CustomTabPanel = (props) => {
  const { children, index, ...other } = props;

  const value = useContext(TabContext);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const CustomTabsContent = (props) => {
  const cls = useStyles();

  const {
    className, value, children, ...other
  } = props;

  return (
    <Paper
      square
      variant="outlined"
      {...other}
      className={clsx(cls.wrap, className)}
    >
      <TabContext.Provider value={value}>
        {children}
      </TabContext.Provider>
    </Paper>
  );
};

export const CustomTabsWrap = (props) => {
  const cls = useStyles();

  const { className, ...other } = props;

  return (
    <Paper square variant="outlined">
      <Tabs
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
        {...other}
        className={clsx(cls.tabs, className)}
      />
    </Paper>
  );
};

export default function CustomTabs(props) {
  const [value, setValue] = useState(0);
  const valueChangeHandler = (e, value) => setValue(value);

  return (
    <>
      <CustomTabsWrap onChange={valueChangeHandler} value={value}>
        {props.tabs}
      </CustomTabsWrap>
      <CustomTabsContent value={value}>
        {props.children}
      </CustomTabsContent>
    </>
  );
}
