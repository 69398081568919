import React from 'react';
import {
  CardCourse,
  CardDescription,
  CardName,
  DownloadButton,
} from './styles';

const CardDownloadFile = ({
  disabled,
  href,
  name,
  desciption,
  buttonText = 'Завантажити',
  flexGrow,
}) => (
  <CardCourse
    flexGrow={flexGrow}
  >
    <CardName>
      { name }
    </CardName>
    <CardDescription>
      { desciption }
    </CardDescription>
    <DownloadButton
      color="inherit"
      variant="text"
      href={href}
      target="_blank"
      disabled={disabled}
      component="a"
      download
    >
      { buttonText }
    </DownloadButton>
  </CardCourse>
);

export default CardDownloadFile;
