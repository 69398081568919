import { mapResultToDocs } from 'utils/fire';
import moment from 'moment';
import { TEST, TEST_BLOCKED } from './types';
import { generateLog, whereUserId } from './utils';

export const BLOCKED_HOURS = 12;
const MAX_ERRORS = 5;

export const getMinTimestamp = () => moment().subtract(BLOCKED_HOURS, 'h').unix();

export const generateTestBlockedLog = (userId) => {
  const data = {
    actual: true,
  };

  return generateLog(data, TEST_BLOCKED, userId);
};

export const findActualTestBlockedLog = async (userId) => {
  const eventsResult = await whereUserId(userId)
    .where('__action', '==', TEST_BLOCKED)
    .where('__timestamp', '>=', getMinTimestamp())
    .where('actual', '==', true)
    .get();

  const eventsDocs = mapResultToDocs(eventsResult);

  return eventsDocs;
};

export const checkIsTestBlocked = async (userId) => {
  const eventsResult = await whereUserId(userId)
    .where('__action', '==', TEST)
    .where('__timestamp', '>=', getMinTimestamp())
    .get();

  const eventsDocs = mapResultToDocs(eventsResult);

  const errors = eventsDocs.filter((event) => event.status === false);

  if (errors.length >= MAX_ERRORS) {
    return true;
  }

  return false;
};
