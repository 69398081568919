import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setBySlug } from '../../store/actions/coursesActions';
import { setLessonsByIds } from '../../store/actions/lessonsAction';
import { setLecturesByIds } from '../../store/actions/lecturesActions';

export const useTest = (courseSlug, lessonSlug, lectureSlug) => {
  const dispatch = useDispatch();

  const {
    courses, lessons, lectures, user,
  } = useSelector((state) => state.client);

  const type = lectureSlug
    ? 'lecture'
    : lessonSlug
      ? 'lesson'
      : 'course';

  const course = courses && courses.find((course) => course.slug === courseSlug);
  const lesson = lessons && lessons.find((lesson) => lesson.slug === lessonSlug);
  const lecture = lectures && lectures.find((lecture) => lecture.slug === lectureSlug);

  // set course
  useEffect(() => {
    dispatch(setBySlug(courseSlug));

    // dispatch
    // eslint-disable-next-line
  }, [courseSlug]);

  // set lessons by course
  useEffect(() => {
    if (!course) return;

    dispatch(setLessonsByIds(course.lessons));

    // dispatch
    // eslint-disable-next-line
  }, [course]);

  // set all lectures by lesson
  useEffect(() => {
    if (!lesson) return;

    dispatch(setLecturesByIds(lesson.lectures));

    // dispatch
    // eslint-disable-next-line
  }, [lesson]);

  const isCourseUndefined = !!courses && !course;
  const isLessonUndefined = lessonSlug && !!lessons && !lesson;
  const isLectureUndefined = lectureSlug && !!lectures && !lecture;
  const isNotConfirm = user.userProfileData && user.userProfileData.isConfirmAdmin === false;

  const redirect = isCourseUndefined || isLessonUndefined || isLectureUndefined || isNotConfirm;

  const courseLoading = !course
    ? true
    : !lessons;

  const lessonLoading = courseLoading
    ? true
    : !lesson
      ? true
      : !lectures;

  const lectureLoading = lessonLoading
    ? true
    : !lecture;

  const loading = type === 'course'
    ? courseLoading
    : type === 'lesson'
      ? lessonLoading
      : lectureLoading;

  return {
    loading, course, lesson, lessons, lectures, lecture, redirect, type,
  };
};
