export const SET_COURSES_LIST = 'SET_COURSES_LIST';
export const SET_DELETE = 'SET_DELETE';
export const SET_ADD_COURSE = 'SET_ADD_COURSE';
export const SET_UPDATE_COURSE = 'SET_UPDATE_COURSE';

const initialState = {
  coursesList: [],
};

const courserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSES_LIST:
      return {
        ...state,
        coursesList: action.payload,
      };
    case SET_DELETE: {
      // eslint-disable-next-line max-len
      const filterDeleteCourser = state.coursesList.filter((item) => item.courseId !== action.payload);
      return {
        ...state,
        coursesList: filterDeleteCourser,
      };
    }
    case SET_ADD_COURSE: {
      const newCourse = [...state.coursesList];
      newCourse.unshift(action.payload);
      return {
        ...state,
        coursesList: newCourse,
      };
    }
    case SET_UPDATE_COURSE: {
      const updateCourses = state.coursesList.map((item) => {
        if (item.courseId === action.payload.id) {
          return action.payload.data;
        }
        return item;
      });
      return {
        ...state,
        coursesList: updateCourses,
      };
    }
    default:
      return state;
  }
};

export default courserReducer;
