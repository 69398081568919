export const C_NEW_USERS_SET = 'C_NEW_USERS_SET';

const initialState = null;

export const newUsersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case C_NEW_USERS_SET:
      return [...payload];
    default:
      return state;
  }
};
