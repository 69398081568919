import React from 'react';
import Box from '@material-ui/core/Box';
import ResultQuestion from './ResultQuestion';

const ResultListQuestions = ({
  questions,
  userAnswer,
}) => (
  <Box mt={3} mb={2}>
    {
      questions.map((item, index) => (
        <Box mb={2} key={index}>
          <ResultQuestion
            index={index}
            question={item}
            type={item.type}
            answersUser={userAnswer[index]}
          />
        </Box>
      ))
    }
  </Box>
);

export default ResultListQuestions;
