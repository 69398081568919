export const A_LESSON_SET = 'A_LESSON_SET';
export const A_LESSON_CLEAR = 'A_LESSON_CLEAR';

export const A_LESSON_SET_LECTURES = 'A_LESSON_SET_LECTURES';

export const A_LESSON_SUCCES_CREATE = 'A_LESSON_SUCCES_CREATE';

export const A_LESSON_SET_ERRORS = 'A_LESSON_SET_ERRORS';

const initialState = {
  lesson: null,
  errors: {},
  successCreate: null,
};

export const lessonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case A_LESSON_SET:
      return { ...state, lesson: { ...payload } };
    case A_LESSON_CLEAR:
      return { ...initialState };
    case A_LESSON_SET_ERRORS:
      return { ...state, errors: { ...payload } };
    case A_LESSON_SUCCES_CREATE:
      return { ...state, successCreate: true };
    default:
      return state;
  }
};
