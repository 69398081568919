import styled, { css } from 'styled-components';

export const Description = styled.div`
  padding-top: 2px;

  overflow-wrap: anywhere;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding-top: 10px;
    }
  `}
`;
