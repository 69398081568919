import React, { useEffect } from 'react';
import { logoutUser } from '../../store/actions/userActions';

const Logout = () => {
  useEffect(() => {
    logoutUser();
  }, []);
  return <div />;
};

export default Logout;
