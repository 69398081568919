export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';
export const SET_UPDATE_USER = 'SET_UPDATE_USER';
export const SET_DELETE_USER = 'SET_DELETE_USER';
export const SET_ADD_USER = 'SET_ADD_USER';

const initialState = {
  users: [],
  user: {},
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_UPDATE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case SET_DELETE_USER: {
      const resultUsers = state.users.filter((item) => item.id !== action.payload);
      return {
        ...state,
        users: resultUsers,
      };
    }
    case SET_ADD_USER: {
      const users = [...state.users];
      const newUsers = users.push(action.payload);
      return {
        ...state,
        users: newUsers,
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
