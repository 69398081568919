import _ from 'lodash';

/**
 * types not full
 * @param {
    {
      courseId: string,
      lessons: string[],
    }
  } course

 * @param {{
    id: string,
    title: string,
    description: string,
    slug: string,
    lectures: string[],
  }[]} lessons

 * @param {
    {
      courses: {
        [courseId: string]: {
            lessons: {
                [lessonId: string]: {
                    lectures: string[],
                    complete: boolean,
                };
            };
            complete: boolean,
        },
      }
    }
  } userStory

 * @param {boolean} isSuperUser
 */
export const getCourseState = (course, lessons, userStory, isSuperUser) => {
  const { courseId, lessons: courseLessonsIds } = course;

  const storyCourse = userStory.courses[courseId];

  // if no lessons, set empty object.
  const storyCourseLessons = storyCourse.lessons || {};
  // contains array like that [[lessonId, [lectureId]], [lessonId, [lectureId]]]
  const storyLessons = Object.entries(storyCourseLessons);

  /**
   * type not full
   * @type {
     {
       [lessonId: string]: {
         lectures: string[],
         status: "done" | "inProgress" | "disabled",
         steps?: [number, number],
         id: string,
         title: string,
         description: string,
         slug: string,
        }
      }
   }
   */
  const state = {};

  // filter lessons for optimization
  const filterLessons = lessons.filter((lesson) => courseLessonsIds.includes(lesson.id));
  // set all filtered lesson to state
  filterLessons.forEach((lesson) => {
    state[lesson.id] = { ...lesson };
  });

  // set right status to lessons from userStory
  storyLessons.forEach(([id, storyLessonRoot]) => {
    const { lectures, complete } = storyLessonRoot;
    const lesson = state[id];

    // if in userStory invalid data.
    // TODO clear invalid data
    if (!lesson) return;

    const invalidLectures = _.difference(lectures, lesson.lectures);

    /** lectures ids
     * @type {string[]}
     */
    let correctLectures;

    // TODO clear invalidData from userStory.
    if (invalidLectures.length) {
      // remove invalidLectures
      correctLectures = _.remove(lectures, (n) => invalidLectures.includes(n));
    } if (!lectures) {
      correctLectures = [];
    } else {
      correctLectures = [...lectures];
    }

    // get diff (step).
    const diff = _.difference(lesson.lectures, correctLectures);
    // create steps array.
    const steps = [lesson.lectures.length - diff.length, lesson.lectures.length + 1];

    // if complete finale test progress + 1
    // can be use in future
    // const isCompleteSteps = complete ? steps[0] + 1 === steps[1] : steps[0] === steps[1];

    if (complete) {
      state[id] = { ...lesson, status: 'done' };
    } else {
      state[id] = { ...lesson, status: 'inProgress', steps };
    }
  });

  // mutate state to create status. Use courseLessonsIds for right order.
  courseLessonsIds.forEach((key, i, array) => {
    const lesson = state[key];

    // if lesson have status
    if (lesson.status) return;

    // if first element without status -> open lesson
    if (i - 1 < 0) {
      lesson.status = 'inProgress';
      lesson.steps = [0, lesson.lectures.length + 1];

      return;
    }

    const prevLesson = state[array[i - 1]];

    // if prev item done -> open lesson or course complete
    if (prevLesson.status === 'done' || storyCourse?.complete || isSuperUser) {
      lesson.status = 'inProgress';
      lesson.steps = [0, lesson.lectures.length + 1];
    } else if (prevLesson.status === 'disabled' || prevLesson.status === 'inProgress') {
      lesson.status = 'disabled';
    }
  });

  return state;
};
