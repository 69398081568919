import { RATING_TYPE as ratingType, TEXT_TYPE as textType } from 'utils/questionnaire';
import * as yup from 'yup';

export const RATING_TYPE = ratingType;
export const TEXT_TYPE = textType;

const questionSchema = yup.object().shape({
  question: yup
    .string()
    .min(3, 'Мінімальна довжина питання 3 символа')
    .max(120, 'Максимальна довжина заголовку 120 символа')
    .required('Запитання це обов`язкове поле'),
  answer: yup
    .string()
    .oneOf([TEXT_TYPE, RATING_TYPE], 'Невідомий тип')
    .required('Тип це обов`язкове поле'),
});

export const questionnaireSchema = yup.object().shape({
  title: yup
    .string()
    .min(3, 'Мінімальна довжина заголовку 3 символа')
    .max(120, 'Максимальна довжина заголовку 120 символа')
    .required('Заголовок це обов`язкове поле'),

  description: yup
    .string()
    .min(3, 'Мінімальна довжина опису 3 символа')
    .max(300, 'Максимальна довжина опису 120 символів')
    .required('Опис це обов`язкове поле'),

  questions: yup
    .array()
    .of(questionSchema)
    .min(1, 'Мінімум одне питання')
    .required('Необхідно хоча б одне запитання'),
});
