import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import LogOutModal from '../../../cabinet/components/LogOutModal';
import { HandleDrawerContext } from '../Layout/Layout';
import { logoutUser } from '../../../cabinet/store/actions/userActions';
import {
  styles,
} from './styles';

export const HeaderButton = (props) => {
  const cls = styles();

  return (
    <Button
      {...props}
      className={clsx(cls.button, props.className)}
      variant="outlined"
      color="inherit"
      size="small"
    >
      {props.children || 'Створити'}
    </Button>
  );
};

function Header(props) {
  const dispatch = useDispatch();
  const { classes, title, button } = props;
  const [showModal, setShowModal] = useState(false);

  const onDrawerToggle = useContext(HandleDrawerContext);

  const logOut = () => {
    dispatch(logoutUser());
  };

  return (
    <AppBar
      color="primary"
      position="sticky"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Hidden smUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>
            Адмін
          </Grid>
          <Grid item>
            <IconButton color="inherit" className={classes.iconButtonAvatar}>
              <Avatar src="/static/images/avatar/1.jpg" alt="My Avatar" />
            </IconButton>
          </Grid>
          <Grid item>
            <ExitToAppIcon onClick={() => setShowModal(true)} />
          </Grid>
        </Grid>
      </Toolbar>
      {/* second header */}
      <Toolbar>
        <Grid container justify="space-between">
          <Typography
            color="inherit"
            variant="h5"
            component="h1"
          >
            {title}
          </Typography>

          {button}
        </Grid>
      </Toolbar>

      <LogOutModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={logOut}
      />
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
