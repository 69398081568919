import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTabPanel } from '../../../../components/CustomTabs';
import { getAllLectureInBase } from '../../../../store/actions/lectureAction';
import SortableSelect, { arrayMove, SortableMultiValue } from '../../../../components/SortableSelect';

export default function Lecture({ lessonData, changeHandler }) {
  const dispatch = useDispatch();
  const lesson = lessonData;
  const lecturesIds = lesson.lectures;

  const lectures = useSelector((state) => state.admin.lecture.lectures);

  useEffect(() => {
    dispatch(getAllLectureInBase());

    // dispatch
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!lesson || !lectures.length) return;

    const lessonLectures = lesson.lectures ? lesson.lectures : [];

    // filter invalid lectures
    const lessonsIds = [];

    lessonLectures.forEach((id) => {
      const lecture = lectures.find((lecture) => lecture.id === id);
      if (!lecture) return;
      lessonsIds.push(id);
    });

    changeHandler('lectures', lessonsIds);

    // dispatch
    // eslint-disable-next-line
  }, [lectures]);

  // create state with lectures without binding
  const state = lectures.reduce((state, { title, id }) => {
    state[id] = { label: title, value: id };

    return state;
  }, {});

  const options = lectures.filter((lecture) => !lecture.lessonId).map((lecture) => state[lecture.id]);
  const selected = lecturesIds ? lecturesIds.map((id) => state[id]) : [];

  const onChange = (values) => changeHandler(
    'lectures',
    values
      ? values.map(({ value }) => value)
      : [],
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);

    changeHandler('lectures', newValue.map(({ value }) => value));
  };

  return (
    <CustomTabPanel index={1}>
      {
        !lectures.length
          ? (
            <strong>Лекцій не додано</strong>
          )
          : (
            <SortableSelect
            // react-sortable-hoc props:
              axis="xy"
              onSortEnd={onSortEnd}
              distance={4}
            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            // react-select props:
              isMulti
              options={options}
              value={selected}
              onChange={onChange}
              components={{
                MultiValue: SortableMultiValue,
              }}
              closeMenuOnSelect={false}
            />
          )
      }
    </CustomTabPanel>
  );
}
