import * as yup from 'yup';
import {
  title, description, slug, slugWithCheckDb, validate,
} from './base';

const baseLessonSchema = { title, description };

export const beforeLessonSchema = yup.object().shape({ ...baseLessonSchema, slug });
export const afterLessonSchema = yup.object().shape({ ...baseLessonSchema, slug: slugWithCheckDb('lessons') });

export const lessonValidate = validate(afterLessonSchema, beforeLessonSchema);
