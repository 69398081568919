export const A_SET_QUESTIONNAIRES = 'A_SET_QUESTIONNAIRES';

const initialState = [];

export const questionnairesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case A_SET_QUESTIONNAIRES:
      return [...payload];
    default:
      return state;
  }
};
