import React from 'react';
import { TextField, makeStyles, createStyles } from '@material-ui/core';
import { CourseTextArea } from '../../styles';
import { CustomTabPanel } from '../../../../components/CustomTabs';
import { mapErrorsToHelperText } from '../../../../../utils/yupUtils';
import { replaceInvalidSlug } from '../../../../../utils/validate';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& .MuiTextField-root:not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Main({ courseData, changeHandler, errors }) {
  const cls = useStyles();

  const {
    title, subTitle, description, slug,
  } = courseData;

  const changeTitle = (e) => changeHandler('title', e.target.value);

  const changeSubTitle = (e) => changeHandler('subTitle', e.target.value);

  const changeDescription = (e) => changeHandler('description', e.target.value);

  const changeSlug = (e) => {
    const value = replaceInvalidSlug(e.target.value);

    // optimization
    if (slug === value) return;

    changeHandler('slug', e.target.value);
  };

  return (
    <CustomTabPanel className={cls.root} index={0}>
      <TextField
        fullWidth

        value={title}
        onChange={changeTitle}

        variant="outlined"
        label="Найменування"

        error={!!errors.title}
        helperText={mapErrorsToHelperText(errors.title)}
      />
      <TextField
        fullWidth

        value={subTitle}
        onChange={changeSubTitle}

        variant="outlined"
        label="Супроводжуючий текст"

        error={!!errors.subTitle}
        helperText={mapErrorsToHelperText(errors.subTitle)}
      />
      <TextField
        fullWidth

        value={slug}
        onChange={changeSlug}

        variant="outlined"
        label="Slug-id"

        error={!!errors.slug}
        helperText={mapErrorsToHelperText(errors.slug)}
      />
      <CourseTextArea
        value={description}
        onChange={changeDescription}

        label="Короткий опис"

        error={!!errors.description}
        helperText={mapErrorsToHelperText(errors.description)}
      />
    </CustomTabPanel>
  );
}
