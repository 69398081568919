import firebase from 'utils/fire';
import { setNotification } from 'store/actions/notificationAction';

const requestResetPasswordFunc = firebase.functions().httpsCallable('requestResetPassword');
const resetPasswordFunc = firebase.functions().httpsCallable('resetPassword');

// i18n
const errors = {
  'auth/invalid-email': 'Не валідний e-mail!',
  'auth/user-not-found': 'Користувача за таким e-mail не знайдено!',
  'server-error': 'Помилка на сервері! Спробуйте пізніше!',
  'invalid-token': 'Строк дії заяви закінчився! Запросіть нову зміну пароля!',
  default: 'Невідома помилка!',
};

export const resetPasswordByEmail = (email) => async (dispatch) => {
  try {
    const { data } = await requestResetPasswordFunc(email);
    const { isSuccess, error } = data;

    if (isSuccess) {
      dispatch(setNotification({ text: 'На ваш e-mail прийшло повідомлення з інструкцією!', variant: 'success' }));
    } else {
      const pickedError = errors[error] || errors.default;

      dispatch(setNotification({ text: pickedError, variant: 'error' }));
    }

    return isSuccess;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const confirmResetPassword = ({ token, password }) => async (dispatch) => {
  try {
    const { data } = await resetPasswordFunc({ token, password });
    const { isSuccess, error } = data;

    if (isSuccess) {
      dispatch(setNotification({ text: 'Пароль успішно змінено!', variant: 'success' }));
    } else {
      const pickedError = errors[error] || errors.default;

      dispatch(setNotification({ text: pickedError, variant: 'error' }));
    }

    return isSuccess;
  } catch (e) {
    console.log(e);
    return false;
  }
};
