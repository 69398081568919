export const COURSE_SET_COURSE = 'COURSE_SET_COURSE';
export const COURSE_CLEAR_COURSE = 'COURSE_CLEAR_COURSE';

export const COURSE_SET_ERRORS = 'COURSE_SET_ERRORS';

/**
 * {
 *  course: Course,
 *  messages: {
 *    [id: string]: Message,
 *  },
 *  errors: YupMappedErrors
 * }
 */
const initialState = {
  course: null,
  errors: {},
};

export const courseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case COURSE_SET_COURSE:
      return { ...state, initialSlug: payload.slug, course: { ...payload } };
    case COURSE_CLEAR_COURSE:
      return { ...state, course: null };
    case COURSE_SET_ERRORS:
      return { ...state, errors: { ...payload } };
    default:
      return state;
  }
};
