export const A_SET_QUESTIONNAIRE = 'A_SET_QUESTIONNAIRE';

const initialState = {};

export const questionnaireReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case A_SET_QUESTIONNAIRE:
      return { ...payload };
    default:
      return state;
  }
};
