export const A_LESSONS_SET_LESSONS = 'A_LESSONS_SET_LESSONS';
export const A_LESSONS_SET_LESSON = 'A_LESSONS_SET_LESSON';
export const A_LESSONS_DELETE_LESSON = 'A_LESSONS_DELETE_LESSON';

const initialState = {};

export const lessonsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case A_LESSONS_SET_LESSONS:
      return { ...payload };
    case A_LESSONS_SET_LESSON:
      return { ...state, [payload.id]: { ...payload.data } };
    case A_LESSONS_DELETE_LESSON: {
      const copy = { ...state };
      delete copy[payload];

      return copy;
    }
    default:
      return state;
  }
};
