import React from 'react';
import {
  ControLabel, Radio, Checkbox,
} from './styles';

export default function Input({
  value, label, type, onChange,
}) {
  let control = null;

  if (type === 'radio') control = (<Radio color="primary" />);
  if (type === 'checkbox') control = (<Checkbox onChange={onChange} color="primary" />);

  return (
    <ControLabel className="test" value={`${value}`} control={control} label={label} />
  );
}
