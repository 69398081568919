export const links = [
  {
    text: 'Панель керування',
    to: '/admin',
  },
  {
    text: 'Глобальні налаштування',
    to: '/admin/config',
  },
  {
    text: 'Курси',
    to: '/admin/courses',
  },
  {
    text: 'Модулі',
    to: '/admin/lessons',
  },
  {
    text: 'Лекції',
    to: '/admin/lectures',
  },
  {
    text: 'Керування користувачами',
    to: '/admin/users',
  },
  {
    text: 'Тести',
    to: '/admin/tests',
  },
  {
    text: 'Анкети',
    to: '/admin/questionnaires',
  },
];
