import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
}));

export default function Content({ children }) {
  const cls = useStyles();

  return (
    <main className={cls.main}>
      {children}
    </main>
  );
}
