import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from 'cabinet/components/ui/CustomButtons/Button.js';
import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import { Link, ButtonWrap } from './styles';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();

  const { links, buttons } = props;

  return (
    <List className={classes.list}>
      {
        links.map((link, i) => (
          <ListItem key={i} className={classes.listItem}>
            <Link to={link.to}>
              <Button color="transparent" className={classes.navLink}>
                {link.content}
              </Button>
            </Link>
          </ListItem>
        ))
      }
      {
        buttons.map(({ text, onClick, ref }, i) => (
          <ListItem key={i} className={classes.listItem}>
            <ButtonWrap>
              <Button ref={ref} onClick={onClick} color="transparent" className={classes.navLink}>
                {text}
              </Button>
            </ButtonWrap>
          </ListItem>
        ))
      }
    </List>
  );
}
