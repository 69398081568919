import React, { useEffect, useState } from 'react';
import Page from 'admin/components/Page';
import { HeaderButton } from 'admin/components/Header';
import { saveQuestionnaire, setQuestionnaireById, saveNewQuestionnaire } from 'admin/store/actions/questionnaireAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Paper, Button } from '@material-ui/core';
import { questionnaireSchema, TEXT_TYPE } from './validate';
import Form from './Form';

const EditQuestionnaire = () => {
  const history = useHistory();
  const { id } = useParams();
  const isCreateMode = !id;

  const dispatch = useDispatch();
  const { questionnaire } = useSelector((state) => state.admin);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isCreateMode) return;

    dispatch(setQuestionnaireById(id));

    // dispatch,isCreateMode
    // eslint-disable-next-line
  }, [id]);

  const validate = async (formData) => {
    try {
      await questionnaireSchema.validate(formData, { abortEarly: false });

      setErrors({});
      return true;
    } catch (e) {
      const state = e.inner.reduce((state, current) => {
        const { path, message } = current;

        if (state[path]) {
          state[path].messages.push(message);
        } else {
          state[path] = { messages: [message] };
        }

        return state;
      }, {});

      setErrors(state);
      return false;
    }
  };

  useEffect(() => {
    if (isCreateMode) return;

    setFormData(questionnaire);
    validate(questionnaire);

    // isCreateMode
    // eslint-disable-next-line
  }, [questionnaire]);

  const changeHandler = (param, value) => {
    const data = { ...formData };
    data[param] = value;

    setFormData(data);
    validate(data);
  };

  const saveHandler = () => {
    if (Object.keys(errors).length) return;

    if (isCreateMode) {
      validate(formData).then((isSuccess) => {
        if (isSuccess) {
          dispatch(saveNewQuestionnaire(formData, history));
        }
      });
    } else {
      dispatch(saveQuestionnaire(formData));
    }
  };

  const addQuestion = () => {
    const questions = formData.questions ? formData.questions : [];

    const newQuestions = [...questions, { answer: TEXT_TYPE, question: '' }];
    changeHandler('questions', newQuestions);
  };

  return (
    <Page
      headerButton={(
        <HeaderButton onClick={saveHandler}>
          {
            isCreateMode ? 'Створити' : 'Зберегти'
          }
        </HeaderButton>
      )}
      title="Анкета"
    >
      <Box
        padding={3}
        component={Paper}
        variant="outlined"
      >
        <Form
          formData={formData}
          changeHandler={changeHandler}
          errors={errors}
        />

        <Box paddingTop={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={addQuestion}
          >
            Додати запитання
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

export default EditQuestionnaire;
