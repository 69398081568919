import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// for future development
// import logger from 'redux-logger';
import { notification } from 'store/reducers/notification';
import { config } from 'store/reducers/config';
import cabinetReducer from './cabinet/store/reducers';
import adminReducer from './admin/store/reducers';

const rootReducer = combineReducers({
  notification,
  config,
  client: cabinetReducer,
  admin: adminReducer,
});

export const getStore = () => createStore(rootReducer, applyMiddleware(
  // for future development
  // logger,
  thunk,
));

export const store = getStore();
