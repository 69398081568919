import styled from 'styled-components';
import { deepPurple } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const Placeholder = styled.div`
    width: 100%;
    height: 96px;

    ${(props) => props.theme.breakpoints.down('xs')} {
        height: 71px;
    }
`;

export const Brand = styled.img`
    display: block;

    width: 123px;
    height: 76px;

    ${(props) => props.theme.breakpoints.down('xs')} {
        width: 66px;
        height: 41px;

        margin-top: 5px;
        margin-bottom: 5px;
    }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));
