import React, { useEffect, useState, useRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Person from '@material-ui/icons/Person';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// store
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../components/ui/Select';
import {
  ControlBlock, SelectBlock, Checkbox, FormControlLabel,
} from '../Registration/styles';
import CustomInput from '../../components/ui/CustomInput/CustomInput';
import {
  Title,
  InfoWrapper,
  CardUI,
  FlexContainer,
  ColInputs,
  CourseWrapper,
  CourseTitle,
  CourseCol,
  CourseRow,
  SendWrapper,
} from './styles';
import CardDownloadFile from '../../components/CardDownloadFile';
import Page from '../../components/Page';
import styles from '../../../assets/jss/material-kit-react/views/loginPage';
import Button from '../../components/Button';

// hook
import { useSetUserData } from '../../../hooks/useSetUserData';
// actions
import { updateInformationUser } from '../../store/actions/userActions';
import {
  validateEmailField,
  validateFieldIsRequired,
  validatePasswordField,
  validateYearOfBirth,
  validatePhone,
} from '../../../utils/validate';
import Header from '../../components/Header';
import { selectCities } from '../../../const';
import { setCoursesByIds } from '../../store/actions/coursesActions';
import FireBaseApp from '../../../utils/fire';

const useStyles = makeStyles(styles);

const parseCourseIds = (certifications) => (certifications
  ? certifications.map((fileName) => fileName.match(/.*?_(.*?).pdf/)[1])
  : []);

const Profile = () => {
  const dispatch = useDispatch();
  const { user, userProfileData, updateMessage } = useSelector((state) => state.client.user);
  const { certifications } = useSelector((state) => state.client.userStory);
  const { courses: allCourses } = useSelector((state) => state.client);
  const inputsData = useSetUserData(userProfileData);
  const [inputs, setInputs] = useState(inputsData);
  const [certificationData, setCertificationData] = useState([]);

  useEffect(() => {
    dispatch(setCoursesByIds(parseCourseIds(certifications)));

    // dispatch
    // eslint-disable-next-line
  }, [certifications]);

  useEffect(() => {
    if (!allCourses || !allCourses.length) return;

    const coursesIds = parseCourseIds(certifications);
    const courseState = allCourses.reduce((state, course) => {
      state[course.courseId] = course;

      return state;
    }, {});

    (async () => {
      const data = await Promise.all(coursesIds.map((id, i) => {
        const course = courseState[id];
        const path = certifications[i];

        if (!course) return null;

        const { title, description } = course;

        return new Promise(async (res) => {
          const url = await FireBaseApp.storage().ref(path).getDownloadURL();

          return res({ title, description, url });
        });
      }));

      setCertificationData(data.filter((data) => data !== null));
    })();
  }, [certifications, allCourses]);

  // regions and cities
  const region = inputs.region.value ? inputs.region.value.value : null;
  const citiesOptions = region !== null && selectCities.cities[region];
  const isCityDisabled = region === null;
  const isCityInput = !!inputs.isCityInput.value;

  const cityCheckboxRef = useRef();

  useEffect(() => {
    setInputs(inputsData);
  }, [inputsData]);

  const validationField = () => {
    const newInputs = {};
    let countValidFiled = 0;
    Object.keys(inputs).forEach((item) => {
      let validValue = null;
      if (inputs[item].validate) {
        if (item === 'password') {
          validValue = validatePasswordField(inputs[item].value);
        } else if (item === 'email') {
          validValue = validateEmailField(inputs[item].value);
        } else if (item === 'yearOfBirth') {
          validValue = validateYearOfBirth(inputs[item].value);
        } else if (item === 'city') {
          validValue = validateFieldIsRequired(inputs[item].value?.value);
        } else if (item === 'phone') {
          validValue = validatePhone(inputs[item].value);
        } else {
          validValue = validateFieldIsRequired(inputs[item].value);
        }

        if (validValue.length > 0) {
          countValidFiled++;
        }

        newInputs[item] = {
          value: inputs[item].value,
          error: validValue.length === 0 ? null : validValue,
          validate: true,
        };
      } else {
        newInputs[item] = {
          value: inputs[item].value,
          error: null,
        };
      }
    });
    setInputs(newInputs);
    return countValidFiled === 0;
  };

  const validateIsTeacherForm = () => {
    let newInputs = {};
    let countValidFiled = 0;
    if (inputs.isClassTeacher.value) {
      ['class', 'countOfStudents'].forEach((item) => {
        const validValue = validateFieldIsRequired(inputs[item].value);
        if (validValue.length > 0) {
          countValidFiled++;
        }

        newInputs = {
          ...inputs,
          [item]: {
            value: inputs[item].value,
            error: validValue.length === 0 ? null : validValue,
          },
        };
      });
      setInputs(newInputs);
    }

    return countValidFiled === 0;
  };

  const changeInput = (e, key) => {
    if (e !== null && e.target) {
      setInputs({
        ...inputs,
        [e.target.name]: {
          ...inputs[e.target.name],
          value: e.target.value,
        },
      });
    } else {
      if (key === 'region' || key === 'isCityInput') {
        setInputs({
          ...inputs,
          [key]: {
            ...inputs[key],
            value: e,
          },
          city: {
            ...inputs.city,
            value: null,
          },
        });
        return;
      }

      setInputs({
        ...inputs,
        [key]: {
          ...inputs[key],
          value: e,
        },
      });
    }
  };

  const changeIsTeacher = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: {
        value: e.target.checked,
      },
    });
  };

  const clearErrors = () => {
    const data = {};
    Object.keys(inputs).forEach((item) => {
      data[item] = {
        ...inputs[item],
        error: null,
      };
    });

    setInputs(data);
  };

  const sendForm = () => {
    clearErrors();
    if (validationField() && validateIsTeacherForm()) {
      const sendData = {};
      const inputData = { ...inputs };
      Object.keys(inputData).forEach((item) => {
        sendData[item] = inputData[item].value;
      });
      dispatch(updateInformationUser(sendData, user.uid));
    }
  };

  const classes = useStyles();

  return (
    <>
      <Header />
      <Page>
        <Title>
          Мой профиль
        </Title>
        <InfoWrapper>
          <CardUI>
            <FlexContainer>
              {/* <ColLogo url={userLogo} /> */}
              <ColInputs>
                <CustomInput
                  labelText="Ім'я"
                  id="first"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'name',
                    value: inputs.name.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.name.error && (
                    <Alert severity="error">{ inputs.name.error[0] }</Alert>
                  )
                }
                <CustomInput
                  labelText="Прізвище"
                  id="second"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'lastName',
                    value: inputs.lastName.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.lastName.error && (
                    <Alert severity="error">{ inputs.lastName.error[0] }</Alert>
                  )
                }
                <Alert severity="warning">Для отримання сертифікату будь ласка вказуйте правильне прізвище та ім'я українською мовою.</Alert>
                <ControlBlock>
                  <CustomInput
                    labelText="Рік народження"
                    id="age"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'number',
                      name: 'yearOfBirth',
                      value: inputs.yearOfBirth.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                </ControlBlock>
                {
                  inputs.yearOfBirth.error && (
                    <Alert severity="error">{ inputs.yearOfBirth.error[0] }</Alert>
                  )
                }

                <ControlBlock>
                  <CustomInput
                    labelText="Номер телефону"
                    type="tel"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'tel',
                      name: 'phone',
                      value: inputs.phone.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                </ControlBlock>
                {
                  inputs.phone.error && (
                    <Alert severity="error">{ inputs.phone.error[0] }</Alert>
                  )
                }

                <SelectBlock>
                  <Select
                    label="Область"
                    id="region"
                    name="region"
                    placeholder="Виберіть область"
                    noOptionsMessage={() => 'Нічого не знайдено'}
                    options={selectCities.regions}
                    value={inputs.region.value}
                    onChange={(select) => {
                      changeInput(select, 'region');
                    }}
                  />
                </SelectBlock>
                {
                    isCityInput
                      ? (
                        <SelectBlock style={{ marginBottom: 0 }}>
                          <CustomInput
                            labelText="Місто"
                            id="city"
                            formControlProps={{
                              fullWidth: true,
                              style: {
                                marginBottom: 8,
                              },
                            }}
                            inputProps={{
                              value: inputs.city.value?.label,
                              inputRef: cityCheckboxRef,
                              onChange: (e) => changeInput({ label: e.target.value, value: `${region}-${e.target.value}` }, 'city'),
                            }}
                          />
                        </SelectBlock>
                      )
                      : (
                        <SelectBlock>
                          <Select
                            label="Місто"
                            id="city"
                            name="city"
                            noOptionsMessage={() => 'Нічого не знайдено'}
                            isDisabled={isCityDisabled}
                            options={citiesOptions}
                            value={inputs.city.value}
                            placeholder={isCityDisabled ? 'Спочатку виберіть область!' : 'Виберіть місто'}
                            onChange={(select) => {
                              changeInput(select, 'city');
                            }}
                          />
                        </SelectBlock>
                      )

                }
                {
                  inputs.city.error && (
                    <Alert severity="error">{ inputs.city.error[0] }</Alert>
                  )
                }
                <FormControlLabel
                  label="Мого міста немає у списку"
                  disabled={isCityDisabled}
                  control={(
                    <Checkbox
                      checked={isCityInput}
                      color="primary"
                      onChange={(e, value) => {
                        changeInput(value, 'isCityInput');

                        if (value === true) {
                          setTimeout(() => {
                            cityCheckboxRef.current && cityCheckboxRef.current.focus();
                          }, 0);
                        }
                      }}
                    />
                  )}
                />
                <ControlBlock>
                  <CustomInput
                    id="school"
                    labelText="Школа"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'school',
                      value: inputs.school.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                </ControlBlock>
                {
                    inputs.school.error && (
                      <Alert severity="error">{ inputs.school.error[0] }</Alert>
                    )
                  }
                <FormControlLabel
                  control={<Checkbox color="primary" checked={inputs.isClassTeacher.value} onChange={(e) => changeIsTeacher(e)} name="isClassTeacher" />}
                  label="Класний керівник"
                />
                {
                  inputs.isClassTeacher.value && (
                    <Grid container spacing={3}>
                      <Grid item lg={6}>
                        <CustomInput
                          labelText="Клас"
                          id="class"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'text',
                            name: 'class',
                            value: inputs.class.value,
                            onChange: (e) => changeInput(e),
                          }}
                        />
                      </Grid>
                      <Grid item lg={6}>
                        <CustomInput
                          labelText="Кількість учнів"
                          id="countOfStudents"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: 'number',
                            name: 'countOfStudents',
                            value: inputs.countOfStudents.value,
                            onChange: (e) => changeInput(e),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {
                          inputs.class.error && (
                            <Alert severity="error">{ inputs.class.error[0] }</Alert>
                          )
                        }
                        {
                          inputs.countOfStudents.error && (
                            <Alert severity="error">{ inputs.countOfStudents.error[0] }</Alert>
                          )
                        }
                      </Grid>
                    </Grid>
                  )
                }
                {
                  updateMessage.message && (
                    <Alert severity={updateMessage.type}>{updateMessage.message}</Alert>
                  )
                }
                <SendWrapper>
                  <Button $secondPadding onClick={sendForm}>Змінити</Button>
                </SendWrapper>
              </ColInputs>
            </FlexContainer>
          </CardUI>
        </InfoWrapper>
        <CourseWrapper>
          <CourseTitle>
            Мої сертифікати
          </CourseTitle>
          <CourseRow>
            <CourseCol>
              {
                !certificationData.length
                  ? 'Не знайдено сертифікатів!'
                  : certificationData.map((data, i) => {
                    const { title, description, url } = data;

                    return (
                      <CardDownloadFile
                        key={i}
                        name={title}
                        desciption={description}
                        href={url}
                      />
                    );
                  })

              }
            </CourseCol>
          </CourseRow>
        </CourseWrapper>
      </Page>
    </>
  );
};

export default Profile;
