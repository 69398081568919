import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import SortableSelect, { arrayMove, SortableMultiValue } from '../../../../components/SortableSelect';
import { CustomTabPanel } from '../../../../components/CustomTabs';
import { useLessons } from '../../../Lessons/useLessons';
import { setAllLesons } from '../../../../store/actions/lessonsAction';

export default function Lessons({ courseData, changeHandler }) {
  const dispatch = useDispatch();
  const lessons = useLessons();
  const course = courseData;
  const courseLessons = course.lessons;

  useEffect(() => {
    dispatch(setAllLesons());

    // dispatch
    // eslint-disable-next-line
  }, []);

  const lessonsValues = Object.values(lessons);

  useEffect(() => {
    if (!course || !Object.values(lessons).length) return;

    // filter invalid lessons
    const lessonsIds = [];

    course.lessons.forEach((id) => {
      const lesson = lessons[id];
      if (!lesson) return;

      lessonsIds.push(id);
    });

    changeHandler('lessons', lessonsIds);

    // dispatch
    // eslint-disable-next-line
  }, [lessons]);

  if (!lessonsValues.length) {
    return (
      <CustomTabPanel index={2}>
        <CircularProgress />
      </CustomTabPanel>
    );
  }

  const lessonsOptions = lessonsValues.filter((lesson) => !lesson.courseId);

  const options = lessonsOptions.map(({ title, id }) => ({ label: title, value: id }));

  // fix undefined bug.
  const selected = courseLessons.filter((id) => !!lessons[id])
    .map((id) => ({ label: lessons[id].title, value: id }));

  const mapSelected = ({ value }) => value;

  // when select lectures => set it to state.
  const onChange = (selectedOptions) => changeHandler(
    'lessons',

    selectedOptions
      ? selectedOptions.map(mapSelected)
      : [],
  );

  // when sort end, set lectures id.
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);

    changeHandler('lessons', newValue.map(mapSelected));
  };

  return (
    <CustomTabPanel index={2}>
      <SortableSelect
        // react-sortable-hoc props:
        axis="xy"
        onSortEnd={onSortEnd}
        distance={4}
        // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        // react-select props:
        isMulti
        options={options}
        value={selected}
        onChange={onChange}
        components={{
          MultiValue: SortableMultiValue,
        }}
        closeMenuOnSelect={false}
      />
    </CustomTabPanel>
  );
}
