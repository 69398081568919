import React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

export default function UserTable({
  name,
  lastName,
  city,
  isClassTeacher,
  countOfStudents,
  yearOfBirth,
  id,
  region,
  school,
}) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Параметр</TableCell>
            <TableCell>Значення</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>Ім'я</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Прізвище</TableCell>
            <TableCell>{lastName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Рік народження</TableCell>
            <TableCell>{yearOfBirth}</TableCell>
          </TableRow>
          {
            region && (
              <TableRow>
                <TableCell>Область</TableCell>
                <TableCell>{region.label}</TableCell>
              </TableRow>
            )
          }
          <TableRow>
            <TableCell>Місто</TableCell>
            <TableCell>{city?.label || ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Школа</TableCell>
            <TableCell>{school}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Вчитель</TableCell>
            <TableCell>{isClassTeacher ? 'Taк' : 'Ні'}</TableCell>
          </TableRow>
          {
            isClassTeacher && (
              <TableRow>
                <TableCell>Кількість студентів</TableCell>
                <TableCell>{countOfStudents}</TableCell>
              </TableRow>
            )
          }
          <TableRow>
            <TableCell>Ідентифікатор</TableCell>
            <TableCell>{id}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
