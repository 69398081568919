// admin app config
export const SET_CONFIG = 'SET_CONFIG';

const initialState = {};

export const config = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONFIG:
      return { ...payload };
    default:
      return state;
  }
};
