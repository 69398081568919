import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification, setNotifications } from 'store/actions/notificationAction';

const NotificationWrap = ({ children }) => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.notification);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!messages) return;

    const haveNewMessages = !!Object.values(messages).find((msg) => msg.showed !== true);

    if (!haveNewMessages) return;

    const state = { ...messages };

    Object.entries(messages).forEach(([id, message]) => {
      state[id] = { ...message, showed: true };

      enqueueSnackbar(message.text, {
        onClose: () => dispatch(removeNotification(id)),
        variant: message.variant,
        // anchorOrigin: {
        //   horizontal: 'center',
        //   vertical: 'top',
        // },
      });
    });

    dispatch(setNotifications(state));

    // dispatch, enqueueSnackbar
    // eslint-disable-next-line
  }, [messages]);

  return children;
};

export default NotificationWrap;
