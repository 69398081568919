import { useEffect, useState } from 'react';

export const defaultInputs = {
  name: {
    value: '',
    error: null,
    validate: true,
  },
  lastName: {
    value: '',
    error: null,
    validate: true,
  },
  yearOfBirth: {
    value: '',
    error: null,
    validate: true,
  },
  phone: {
    value: '',
    error: null,
    validate: true,
  },
  region: {
    value: null,
  },
  city: {
    value: {
      value: null,
      label: '',
    },
    error: null,
    validate: true,
  },
  isCityInput: {
    value: false,
  },
  school: {
    value: '',
    error: null,
    validate: true,
  },
  class: {
    value: '',
    error: null,
  },
  isClassTeacher: {
    value: false,
    error: null,
  },
  countOfStudents: {
    value: '',
    error: null,
  },
  email: {
    value: '',
    error: null,
    validate: true,
  },
  personalData: {
    value: false,
    error: null,
  },
};

export const useSetUserData = (watchVariable) => {
  const [inputs, setInputs] = useState(defaultInputs);

  useEffect(() => {
    if (watchVariable !== undefined && watchVariable !== null) {
      const data = {};
      Object.keys(inputs).forEach((item) => {
        if (item === 'city' || item === 'region') {
          data[item] = {
            ...inputs[item],
            value: {
              ...watchVariable[item],
            },
            error: null,
          };
        } else {
          data[item] = {
            ...inputs[item],
            value: watchVariable[item],
            error: null,
          };
        }
      });
      setInputs(data);
    }

    // inputs
    // eslint-disable-next-line
  }, [watchVariable]);

  return inputs;
};
