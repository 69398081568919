export const C_COURSES_SET_COURSES = 'C_COURSES_SET_COURSES';

const intitialState = null;

export const coursesReducer = (state = intitialState, { payload, type }) => {
  switch (type) {
    case C_COURSES_SET_COURSES:
      return [...payload];
    default:
      return state;
  }
};
