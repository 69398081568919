import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setBySlug } from 'cabinet/store/actions/coursesActions';
import firebase from 'utils/fire';
import { FIREBASE, LINK } from 'admin/store/actions/courseAction';
import { Container } from '../../components/Container/Container';
import CardDownloadFile from '../../components/CardDownloadFile';
import Page from '../../components/Page';
import Header from '../../components/Header';

const typeToLabel = {
  [FIREBASE]: 'Завантажити',
  [LINK]: 'Посилання',
};

const storage = firebase.storage();

const Library = () => {
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.client.courses);

  const [course, setCourse] = useState(null);
  const [cards, setCards] = useState(null);

  useEffect(() => {
    dispatch(setBySlug(courseSlug));
    // dispatch
    // eslint-disable-next-line
  }, [courseSlug]);

  useEffect(() => {
    if (!courses) return;

    const course = courses.find((course) => course.slug === courseSlug);
    if (!course) return;

    setCourse(course);
    // courseSlug
    // eslint-disable-next-line
  }, [courses]);

  useEffect(() => {
    if (!course || !course.library) return;

    (async () => {
      const cards = await Promise.all(course.library.map((card) => new Promise(async (res) => {
        if (card.type !== FIREBASE) return res(card);

        const copy = { ...card };
        const url = await storage.ref(copy.input.path).getDownloadURL();

        copy.input.path = url;

        return res(copy);
      })));

      setCards(cards);
    })();
  }, [course]);

  const mapCards = ({
    title, description, type, input,
  }, i) => (
    <Grid
      key={i}

      item
      container
      lg={3}
      md={6}
      sm={6}
      xs={12}
    >
      <CardDownloadFile
        name={title}
        desciption={description}
        buttonText={typeToLabel[type]}
        href={input.path}

        flexGrow
      />
    </Grid>
  );

  return (
    <>
      <Header />
      <Page
        supTitle="Бібліотека"
        subTitle="Додаткові матеріали"
        subWithoutLogos
      >
        <Container>
          <Grid container spacing={2}>
            {
            cards
              ? cards.map(mapCards)
              : course && 'Завантаження'
          }
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default Library;
