import React from 'react';
import {
  Box, MenuItem, TextField, makeStyles, Button,
} from '@material-ui/core';
import { mapErrorsToHelperText } from 'utils/yupUtils';
import { Alert } from '@material-ui/lab';
import { TEXT_TYPE, RATING_TYPE } from '../validate';
import { Fieldset, Legend } from './styles';

const CustomTextField = (props) => (
  <TextField
    multiline
    fullWidth
    InputLabelProps={{
      shrink: true,
    }}
    variant="outlined"

    {...props}
  />
);

const useStyles = makeStyles({
  question: {
    '& > *:not(:last-child)': {
      marginBottom: 20,
    },
  },
});

const Form = ({ formData, changeHandler, errors }) => {
  const { questions, title, description } = formData;
  const cls = useStyles();

  const changeQuestion = (index, question) => {
    const copyQuestions = [...questions];
    copyQuestions[index] = question;

    changeHandler('questions', copyQuestions);
  };

  const changeQuestionParam = (index, key) => (e) => {
    const copyQuestion = { ...questions[index] };
    copyQuestion[key] = e.target.value;

    changeQuestion(index, copyQuestion);
  };

  const deleteQuestion = (index) => (e) => {
    const copy = [...formData.questions];
    const filtered = copy.filter((_, i) => !(i === index));

    changeHandler('questions', filtered);
  };

  return (
    <Box>
      <CustomTextField
        label="Заголовок"
        value={title}
        onChange={(e) => changeHandler('title', e.target.value)}

        error={!!errors.title}
        helperText={mapErrorsToHelperText(errors.title && errors.title.messages)}
      />

      <Box marginTop={2}>
        <CustomTextField
          label="Опис"
          value={description}
          onChange={(e) => changeHandler('description', e.target.value)}

          error={!!errors.description}
          helperText={mapErrorsToHelperText(errors.description && errors.description.messages)}
        />
      </Box>

      {
        !!errors.questions && (
          <Box paddingTop={2} paddingBottom={2}>
            <Alert severity="error">{mapErrorsToHelperText(errors.questions?.messages)}</Alert>
          </Box>
        )
      }

      {
        !!questions && questions.map(({ question, answer }, i) => (
          <Box
            key={i}
            paddingTop={2}
            paddingBottom={2}
          >
            <Fieldset>
              <Legend>
                №
                {i + 1}
              </Legend>

              <Box
                className={cls.question}
                padding={1}
              >
                <CustomTextField
                  label="Запитання"

                  value={question}
                  onChange={changeQuestionParam(i, 'question')}

                  error={!!errors[`questions[${i}].question`]}
                  helperText={mapErrorsToHelperText(errors[`questions[${i}].question`] && errors[`questions[${i}].question`].messages)}
                />

                <CustomTextField
                  select
                  label="Відповідь"

                  value={answer}
                  onChange={changeQuestionParam(i, 'answer')}

                  error={!!errors[`questions[${i}].answer`]}
                  helperText={mapErrorsToHelperText(errors[`questions[${i}].answer`] && errors[`questions[${i}].answer`].messages)}
                >
                  <MenuItem value={TEXT_TYPE}>Текстом</MenuItem>
                  <MenuItem value={RATING_TYPE}>Оцінкою</MenuItem>
                </CustomTextField>

                <Box paddingTop={1} display="flex" justifyContent="flex-end">
                  <Button variant="contained" color="secondary" onClick={deleteQuestion(i)}>
                    Видалити
                  </Button>
                </Box>
              </Box>
            </Fieldset>
          </Box>
        ))
      }
    </Box>
  );
};

export default Form;
