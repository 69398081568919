import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  inputBackground: {
    backgroundColor: 'white',
    borderRadius: '8px',
    width: '100%',
  },
  radioButton: {
    marginRight: '20px',
  },
}));

export const WrapperInput = styled.div`
  flex-grow: 1;
  margin-right: 15px;
  
  input {
    width: 100%;
  }
`;
