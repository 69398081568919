import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/img/check.svg';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Progress,
  ProgressBar,
  ProgressWrap,
  SubmitButton,
  ButtonWrap,
  CompleteIcon,
  Title,
  Text,
} from './styles';

const DashboardCard = ({
  status = 'disabled', // oneOf(['done', 'inProgress', 'disabled']
  title = '',
  text = '',
  steps = '',
  to = '',
}) => {
  const renderSubmitButton = (
    <SubmitButton
      disabled={status === 'disabled'}
      color="inherit"
      variant="text"
    >
      Обрати
    </SubmitButton>
  );

  return (
    <Card>
      {status === 'done' && (
        <CompleteIcon>
          <CheckIcon />
        </CompleteIcon>
      )}

      <CardBody>
        <Title>{title}</Title>
        <Text>{text}</Text>

        <ButtonWrap>
          {
            (status === 'disabled' || to === '')
              ? renderSubmitButton
              : (
                <Link to={to}>
                  {renderSubmitButton}
                </Link>
              )
          }
        </ButtonWrap>
      </CardBody>

      {status === 'inProgress' && (
        <ProgressWrap>
          <Progress>
            {steps[0]}
            /
            {steps[1]}
          </Progress>
          <ProgressBar percent={(steps[0] * 100) / steps[1]} />
        </ProgressWrap>
      )}
    </Card>
  );
};

export default DashboardCard;
