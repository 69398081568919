import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

// created from suneditor-react { buttonList }
const buttons = [
  ['undo', 'redo'],
  ['fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['link', 'image', 'video'],
  ['fullScreen', 'showBlocks', 'codeView'],
];

export default function Editor(props) {
  const { setOptions, id, ...other } = props;

  let options = {
    buttonList: buttons,
    height: '100vh',
    maxHeight: '500px',
  };

  if (setOptions) {
    options = { ...options, ...setOptions };
  }

  return (
    <SunEditor
      lang="ru"
      setOptions={options}
      name={`editor${id}`}
      {...other}
    />
  );
}
