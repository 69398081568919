export const A_SET_QUESTIONNAIRE_RESULT = 'A_SET_QUESTIONNAIRE_RESULT';

const intitalState = {};

export const questionnaireResultReducer = (state = intitalState, { type, payload }) => {
  switch (type) {
    case A_SET_QUESTIONNAIRE_RESULT:
      return { ...payload };
    default:
      return state;
  }
};
