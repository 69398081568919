import { makeStyles } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const useStyles = makeStyles({
  select: {
    '& > div': {
      borderColor: '#2684FF',
    },
  },
});

export const DragContextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  
  ${({ disabled }) => (
    disabled
      ? css`
          pointer-events: none;
          div {
            pointer-events: none;
          }
        `
      : null
  )}
`;
