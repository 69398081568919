import styled from 'styled-components';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  box: {
    height: '100%',
    backgroundColor: '#e2e2e2',
    borderRadius: '0.3rem',
    marginLeft: '8px',
    minHeight: '250px',
  },
  card: {
    padding: '10px',
  },
  cardContent: {
    padding: '0 !important',
  },
  alert: {
    width: '100%',
    marginBottom: '15px',
  },
}));

export const Col = styled.div``;
