import axios from 'axios';
import firebase from '../../../utils/fire';
import {
  SET_USER,
  SET_USERS,
  SET_UPDATE_USER,
  SET_DELETE_USER,
  SET_ADD_USER,
} from '../reducers/users';
import { SET_UPDATE_USERS } from '../reducers/newUsers';
import { activeUserById } from './newUsersAction';

const db = firebase.firestore();

export const setUsers = (payload) => ({
  type: SET_USERS,
  payload,
});

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const updateUser = (payload) => ({
  type: SET_UPDATE_USER,
  payload,
});

export const deleteUser = (payload) => ({
  type: SET_DELETE_USER,
  payload,
});

export const createNewUser = (payload) => ({
  type: SET_ADD_USER,
  payload,
});

export const setUpdateUsers = (payload) => ({
  type: SET_UPDATE_USERS,
  payload,
});

const usersRef = db.collection('users');

export const deactivateUser = (id, isConfirmAdmin) => async (dispatch, getState) => {
  try {
    if (isConfirmAdmin) {
      await usersRef.doc(id).update({ isConfirmAdmin: false });
    }

    await dispatch(activeUserById(id));
  } catch (e) {
    console.error(e);
  }
};

export const getAllUsersFromDateBase = (page = 1, name='', lastName='', city='', email='') => async (dispatch) => {
  try {
    const usersRef = db.collection('users');
    const num = (parseInt(page) - 1) * 25;
    let usersRequest = usersRef;
    if(city) usersRequest = usersRequest.where('city.label', '==', city);
    if(name) usersRequest = usersRequest.where('name', '==', name);
    if(lastName) usersRequest = usersRequest.where('lastName', '==', lastName);
    if(email) usersRequest = usersRequest.where('email', '==', email);
    let preResultUsers = usersRequest.orderBy('yearOfBirth');
    if(num > 0){
      const first = await usersRequest.orderBy('yearOfBirth').limit(num).get();
      const last = first.docs[first.docs.length - 1];
      preResultUsers = preResultUsers.startAfter(last.data().yearOfBirth);
    }
    const resultUsers = await preResultUsers.limit(25).get();
    
    const usersData = [];
    resultUsers.forEach((user) => {
      usersData.push({
        ...user.data(),
        id: user.id,
      });
    });
    dispatch(setUsers(usersData));
  } catch (error) {
    console.log(error);
  }
};

export async function getAllUsersCountFromDateBase (name='', lastName='', city='', email='') {
  try {
    const usersRef = db.collection('users');
    let usersRequest = usersRef;
    if(city) usersRequest = usersRequest.where('city.label', '==', city);
    if(name) usersRequest = usersRequest.where('name', '==', name);
    if(lastName) usersRequest = usersRequest.where('lastName', '==', lastName);
    if(email) usersRequest = usersRequest.where('email', '==', email);
    let preResultUsers = usersRequest.orderBy('yearOfBirth');
    const resultUsers = await preResultUsers.get();
    
    const usersData = [];
    resultUsers.forEach((user) => {
      usersData.push({
        ...user.data(),
        id: user.id,
      });
    });
    const count = Math.ceil(usersData.length / 25);
    return count;
  } catch (error) {
    console.log(error);
  }
};

export const addNewUser = (data) => async (dispatch) => {
  try {
    const res = await db.collection('users').add(data);
    dispatch(createNewUser({
      ...data,
      id: res.id,
    }));
  } catch (error) {
    console.log(error);
  }
};

export const getInformationUser = (id) => async (dispatch) => {
  try {
    const userRef = db.collection('users').doc(id);
    const result = await userRef.get();
    dispatch(setUser({
      ...result.data(),
      id: result.id,
    }));
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInBase = (data, id) => async (dispatch) => {
  try {
    const { isConfirmAdmin } = data;
    if (isConfirmAdmin) {
      const newUserRef = db.collection('newusers').doc(id);
      const findUser = await newUserRef.get();
      if (findUser.data()) {
        const findUserInBase = await db.collection('users').doc(id).get();
        const dataUser = findUserInBase.data();
        await newUserRef.delete();
        await axios.post('https://us-central1-sappers-4605c.cloudfunctions.net/sendEmail', {
          email: dataUser.email,
          name: `${dataUser.name} ${dataUser.lastName}`,
          type: 'update',
        });
      }
    }

    await db.collection('users').doc(id).update(data);

    dispatch(updateUser({
      ...data,
      id,
    }));
    // update list newUser
    dispatch(setUpdateUsers({
      ...data,
      id,
    }));
  } catch (error) {
    console.log(error);
  }
};

const removeUser = firebase.functions().httpsCallable('removeUser');

export const deleteUserInBase = (id) => async (dispatch) => {
  try {
    const { data } = await removeUser({ id });

    if (!data.success) {
      console.log('error with deleting from firebase.auth');
      return;
    }

    await db.collection('newusers').doc(id).delete();
    await db.collection('users').doc(id).delete();
    await db.collection('userstory').doc(id).delete();
    dispatch(deleteUser(id));
  } catch (error) {
    console.log(error);
  }
};
