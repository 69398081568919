import { db } from '../../../utils/fire';
import {
  A_LESSON_SET,
  A_LESSON_SUCCES_CREATE,
  A_LESSON_CLEAR,
  A_LESSON_SET_ERRORS,
} from '../reducers/lesson';
import { lessonValidate } from '../validations/lesson';
import { mapYupInnerToState } from '../../../utils/yupUtils';
import { setMessage } from './messagesAction';

export const setLesson = (payload) => ({
  type: A_LESSON_SET,
  payload,
});

export const clearLesson = () => ({
  type: A_LESSON_CLEAR,
});

export const successCreate = () => ({
  type: A_LESSON_SUCCES_CREATE,
});

export const setErrors = (payload) => ({
  type: A_LESSON_SET_ERRORS,
  payload,
});

const lessonsRef = db.collection('lessons');
const lecturesRef = db.collection('lectures');

export const saveLesson = (lesson, initialSlug, errors) => async (dispatch, getState) => {
  try {
    if (Object.values(errors).length) {
      dispatch(setMessage({
        text: 'Не збережено! У вас помилки!',
        variant: 'error',
      }));

      return;
    }

    const { lectures } = lesson;

    if (initialSlug !== lesson.slug) {
      const result = await lessonValidate(lesson, true);

      if (!result.success) {
        dispatch(setErrors(mapYupInnerToState(result.errors)));

        dispatch(setMessage({
          text: 'Не збережено! У вас помилки!',
          variant: 'error',
        }));

        return;
      }
    }

    // clear binding where have.
    const lecturesResult = await lecturesRef.where('lessonId', '==', lesson.id).get();
    await Promise.all(lecturesResult.docs.map((doc) => doc.data())
      .map((lecture) => lecturesRef.doc(lecture.id).update({ lessonId: null })));

    // update binding
    await Promise.all(lectures.map((id) => lecturesRef.doc(id).update({ lessonId: lesson.id })));

    // save doc
    await lessonsRef.doc(lesson.id).update({ ...lesson, lectures });

    dispatch(setMessage({
      text: 'Збережено!',
      variant: 'success',
    }));
  } catch (e) {
    dispatch(setMessage({
      text: 'Сталась невідома помилка при зберіганні',
      variant: 'error',
    }));

    console.error(e);
  }
};

export const createNewLesson = () => (dispatch) => {
  const lesson = {};

  dispatch(setLesson(lesson));
};

export const setLessonById = (id) => async (dispatch) => {
  try {
    const lessonResult = await lessonsRef.doc(id).get();
    const lesson = { ...lessonResult.data(), id };

    if (!lesson.slug) {
      lesson.slug = lesson.id;
    }

    dispatch(setLesson(lesson));
  } catch (e) {
    dispatch(setMessage({
      text: 'Сталась невідома помилка при завантаженні модуля!',
      variant: 'error',
    }));

    console.error(e);
  }
};

export const saveNewLesson = (lesson, initialSlug, errors) => async (dispatch) => {
  try {
    const result = await lessonValidate(lesson, true);

    if (!result.success) {
      dispatch(setErrors(mapYupInnerToState(result.errors)));

      dispatch(setMessage({
        text: 'Не збережено! У вас помилки!',
        variant: 'error',
      }));

      return;
    }

    const { id } = await lessonsRef.add({});

    const copy = { ...lesson, id };

    await dispatch(setLesson(copy));
    await saveLesson(copy, initialSlug, errors)(dispatch);
    await new Promise((res) => setTimeout(res, 2000));
    dispatch(successCreate());
  } catch (e) {
    dispatch(setMessage({
      text: 'Сталась невідома помилка при зберіганні',
      variant: 'error',
    }));

    console.error(e);
  }
};
