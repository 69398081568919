import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLessonsByIds } from '../../store/actions/lessonsAction';
import { setBySlug } from '../../store/actions/coursesActions';
import { setLecturesByIds } from '../../store/actions/lecturesActions';
import { checkIds } from '../../../utils/checkIds';

export const useLesson = (courseSlug, lessonSlug) => {
  const dispatch = useDispatch();

  const {
    lessons, courses, lectures, userStory, user,
  } = useSelector((state) => state.client);

  const lesson = lessons ? lessons.find((lesson) => lesson.slug === lessonSlug) : null;
  const course = courses ? courses.find((course) => course.slug === courseSlug) : null;

  // load course
  useEffect(() => {
    dispatch(setBySlug(courseSlug));

    // dispatch
    // eslint-disable-next-line
  }, []);

  // set all lessons from course
  useEffect(() => {
    if (!course) return;

    dispatch(setLessonsByIds(course.lessons));

  // dispatch
  // eslint-disable-next-line
  }, [course]);

  // load all lesson lectures
  useEffect(() => {
    if (!lesson) return;

    dispatch(setLecturesByIds(lesson.lectures));

    // dispatch
    // eslint-disable-next-line
  }, [lesson]);

  const isCourseUndefined = !!courses && !course;
  const isLessonUndefined = !!lessons && !lesson;
  const isNotConfirm = user.userProfileData && user.userProfileData.isConfirmAdmin === false;

  // if course not found or lesson not found redirect.
  const redirect = isNotConfirm || isCourseUndefined || isLessonUndefined;

  const loading = !lesson
    ? true
    : !course
      ? true
      : !checkIds(course.lessons, lessons)
        ? true
        : !checkIds(lesson.lectures, lectures);

  return {
    loading, lesson, lessons, course, lectures, userStory, redirect, user,
  };
};
