import styled, { css } from 'styled-components';

export const TitleWrap = styled.div`
    display: flex;

    margin-bottom: 39px;

    ${(props) => props.withoutMargin && css`
        margin-bottom: 0;
    `}

    ${({ theme }) => css`
        ${theme.breakpoints.down('sm')} {
            flex-flow: column wrap;

            text-align: center;
        }
    `}
`;

const TitleStyle = css`
    margin: 0;

    font-family: "Roboto Slab", "Roboto", sans-serif;

    font-size: 25px;
    line-height: 33px;

    color: #000000;
`;

export const Left = styled.h2`
    ${TitleStyle}

    padding-right: 48px;

    white-space: nowrap;

    height: 69px;

    ${(props) => props.small && css`
        padding-right: 71px;

        font-size: 20px;
        line-height: 26px;
    `}
    
    ${(props) => css`${props.theme.breakpoints.down('sm')} {
        padding-right: 0;
    }`}

    ${(props) => `${props.theme.breakpoints.down('xs')} {
        pading-bottom: 10px;

        font-size: 20px;
    }`}
`;

export const Right = styled.h3`
    ${TitleStyle}

    ${(props) => props.small && css`
        font-size: 20px;
        line-height: 26px;
    `}

    ${(props) => `${props.theme.breakpoints.down('xs')} {
        font-size: 20px;
        
        text-align: center;
    }`}
    
    p {
      ${TitleStyle}
    
      ${(props) => props.small && css`
        font-size: 20px;
        line-height: 26px;
      `}
    
      ${(props) => `${props.theme.breakpoints.down('xs')} {
        font-size: 20px;
        text-align: center;
      }`}
    }
`;
