import styled from 'styled-components';
import Card from 'cabinet/components/Card';

export const Container = styled(Card)`
    && {
        margin: 0;
        padding: 38px 86px 32px 86px;

        ${(props) => props.theme.breakpoints.down('xs')} {
          padding-left: 46px;
          padding-right: 46px;
        }
    }
`;

export const Title = styled.div`
    font-family: "Roboto Slab", "Roboto", sans-serif;

    font-weight: bold;
    font-size: 20px;
    line-height: 26px;

    color: rgba(0, 0, 0, 0.87);

    margin-bottom: 24px;
`;
