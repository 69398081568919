import styled from 'styled-components';

export const Questions = styled.div`
  && > *:not(:last-child) {
    margin-bottom: 22px;
  }
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
`;

export const BottomActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  
  & > button.MuiButtonBase-root.MuiButton-root {
    background-color: #29804b;
  }
`;

export const NextQuestions = styled.div`
  margin-left: 25px;
`;
