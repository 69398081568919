import React, { createContext, useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { useTheme } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import NotificationWrap from 'components/NotificationWrap';
import AppAdmin from './admin';
import AppCabinet from './cabinet';
import Preloader from './cabinet/components/Preloader';
import ScrollToTop from './cabinet/components/ScrollToTop';

// modalWindow
// eslint-disable-next-line
import FirstEnterModal from './components/FirstEnterModal';
import { isLoginUser } from './cabinet/store/actions/userActions';
import { removeFirstEnter } from './cabinet/store/actions/userStoryActions';
import './globalStyles.scss';

export const FirstEnterModalContext = createContext(null);

const App = () => {
  const theme = useTheme();
  const { user, userStory } = useSelector((state) => state.client);
  const { loading } = user;
  const dispatch = useDispatch();
  const history = useHistory();

  // modalWindow
  // eslint-disable-next-line
  const [show, setShow] = useState(!!(userStory && userStory.isFirstEnter));

  useEffect(() => {
    dispatch(isLoginUser());

    // dispatch
    // eslint-disable-next-line
  }, []);

  // redirects
  useEffect(() => {
    if (!user || !userStory) return;

    // invalid data
    if (user.userProfileData === undefined || userStory.isUndefined) {
      history.push('/invalid-data');
    }

    // reg questionnaire
    if (userStory.regQuestionnaire === undefined) {
      history.push('/reg-questionnaire');
    }

    // history
    // eslint-disable-next-line
  }, [user, userStory]);

  useEffect(() => {
    if (!userStory) return;

    setShow(userStory.isFirstEnter);
  }, [userStory]);

  const showModal = () => setShow(true);

  // modalWindow
  // eslint-disable-next-line
  const enterModalCloseHandler = () => {
    if (userStory.isFirstEnter) {
      dispatch(removeFirstEnter());
    }

    setShow(false);
  };

  return (
    <ThemeProvider
      theme={{
        breakpoints: theme.breakpoints,
      }}
    >
      <SnackbarProvider maxSnack={3}>
        <NotificationWrap>
          <FirstEnterModalContext.Provider value={{ showModal }}>
            <ScrollToTop />
            {
              loading
                ? <Preloader />
                : (
                  <Switch>
                    <Route path="/admin" component={AppAdmin} />
                    <Route path="/" component={AppCabinet} />
                  </Switch>
                )
            }

            {/* <FirstEnterModal
            isOpen={show}
            onRequestClose={enterModalCloseHandler}
            shouldCloseOnOverlayClick
          /> */}
          </FirstEnterModalContext.Provider>
        </NotificationWrap>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
