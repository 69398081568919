import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import brandLogo from 'assets/img/brand-without-bg.png';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import {
  styles,
} from './styles';
import { links } from './links';

function Navigator(props) {
  const { classes, ...other } = props;
  const { location } = useHistory();
  const { pathname } = location;

  return (
    <Drawer variant="permanent" {...other}>
      <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
        <Box className={classes.logoBox}>
          <Link to="/admin">
            <img src={brandLogo} alt="brand" className={classes.logoImage} />
          </Link>
        </Box>
      </ListItem>

      <List className={classes.list} disablePadding>
        {
          links.map(({ to, text }, i) => (
            <Link
              key={i}
              to={to}
            >
              <ListItem
                button
                className={clsx(classes.item, {
                  [classes.itemActiveItem]: pathname === to,
                })}
              >
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {text}
                </ListItemText>
              </ListItem>
            </Link>
          ))
        }
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
