import { setNotification } from 'store/actions/notificationAction';
import { questionnairesCollection } from 'utils/fire';
import { error } from 'utils/logger';
import { A_SET_QUESTIONNAIRE } from '../reducers/questionnaire';
import { setAllQuestionnaires } from './questionnairesAction';

const setQuestionnaire = (payload) => ({
  type: A_SET_QUESTIONNAIRE,
  payload,
});

export const setQuestionnaireById = (id) => async (dispatch) => {
  try {
    const questionnaireResult = await questionnairesCollection.doc(id).get();
    const questionnaire = { ...questionnaireResult.data(), id: questionnaireResult.id };

    dispatch(setQuestionnaire(questionnaire));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час завантаження анкети', variant: 'error' }));
  }
};

export const saveQuestionnaire = (formData) => async (dispatch) => {
  try {
    const copy = { ...formData };

    await questionnairesCollection.doc(copy.id).update(copy);

    dispatch(setQuestionnaire(copy));
    dispatch(setNotification({ text: 'Анкету успішно збережено', variant: 'success' }));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час зберігання анкети', variant: 'error' }));
  }
};

export const saveNewQuestionnaire = (formData, history) => async (dispatch) => {
  try {
    const copy = { ...formData };

    const docResult = await questionnairesCollection.add({});
    const { id } = docResult;

    await questionnairesCollection.doc(id).update({ ...copy, id });

    history.push(`/admin/questionnaire/${id}/edit`);
    dispatch(setNotification({ text: 'Анкету успішно збережено', variant: 'success' }));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час створення анкети', variant: 'error' }));
  }
};

export const deleteQuestionnaire = (id) => async (dispatch) => {
  try {
    await questionnairesCollection.doc(id).delete();
    dispatch(setAllQuestionnaires());
    dispatch(setNotification({ text: 'Анкету успішно видалено', variant: 'success' }));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час видалення анкети', variant: 'error' }));
  }
};
