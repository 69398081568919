const cardBodyStyle = {
  cardBody: {
    padding: '0.9375rem 1.875rem',
    flex: '1 1 auto',
  },
  textRight: {
    textAlign: 'right',
  },
};

export default cardBodyStyle;
