import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-kit-react/components/customInputStyle.js';
import { InputLabel } from './style';

const useStyles = makeStyles(styles);

const selectStyles = {
  control: (styles) => ({ ...styles, fontSize: 14 }),
};

const CustomSelect = ({ label, createMode, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <InputLabel
          className={`${classes.labelRoot}`}
        >
          {label}
        </InputLabel>
      )}
      {
        createMode
          ? <CreatableSelect styles={selectStyles} {...props} />
          : <ReactSelect styles={selectStyles} {...props} />
      }
    </>
  );
};

export default CustomSelect;
