import * as yup from 'yup';
import {
  title, subTitle, description, slug, slugWithCheckDb, validate,
} from './base';

const baseCourseSchema = {
  title,
  // subTitle,
  description,
  library: yup.array().of(
    yup.object({
      title,
      description,
      input: yup.object({
        path: yup.string().required('Файл обов\'язковий параметр!'),
      }),
    }),
  ),
};

export const beforeCourseSchema = yup.object().shape({ ...baseCourseSchema, slug });
export const afterCourseSchema = yup.object().shape({ ...baseCourseSchema, slug: slugWithCheckDb('courses') });

export const courseValidate = validate(afterCourseSchema, beforeCourseSchema);
