// jsx-factory
import React from 'react';

// style
import AuthLayout from 'cabinet/layout/Auth';
import CustomInput from 'cabinet/components/ui/CustomInput/CustomInput';
import Button from 'cabinet/components/ui/CustomButtons/Button';
import CardBody from 'cabinet/components/ui/Card/CardBody';
import CardHeader from 'cabinet/components/ui/Card/CardHeader';
import CardFooter from 'cabinet/components/ui/Card/CardFooter';
import { Grid } from '@material-ui/core';

// router
import { Link, useParams, useHistory } from 'react-router-dom';

// <head /> editor
import { Helmet } from 'react-helmet';

// business logic
import { useDispatch } from 'react-redux';
import { confirmResetPassword } from 'cabinet/store/actions/resetPassword';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStyles } from '../Login/Login';
import { AlertBox, InputBox } from '../Login/styles';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Пароль не може бути меньше 6 символів!')
    .max(25, 'Пароль не може бути більше 25 символів!')
    .required('Пароль це обов`язкове поле!'),
  confirm: yup.string().oneOf([yup.ref('password'), null], 'Паролі повинні співпадати!'),
});

const ConfirmResetPassword = () => {
  const { token } = useParams();
  const history = useHistory();
  const cls = useStyles();
  const dispatch = useDispatch();

  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const submitHandler = async ({ password }) => {
    const isSuccess = await dispatch(confirmResetPassword({ token, password }));

    if (isSuccess) {
      history.push('/');
    }
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Зміна пароля | Навчальна платформа</title>
      </Helmet>

      <form className={cls.form} onSubmit={handleSubmit(submitHandler)}>
        <CardHeader color="primary" className={cls.cardHeader}>
          <h4>Зміна пароля</h4>
        </CardHeader>

        <CardBody>
          <InputBox>
            <CustomInput
              labelText="Пароль"
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'password',
                name: 'password',
                inputRef: register(),
              }}
            />
            {
              errors.password && <AlertBox severity="error">{errors.password.message}</AlertBox>
            }
          </InputBox>
          <InputBox>
            <CustomInput
              labelText="Підтвердження паролю"
              id="confirm"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'password',
                name: 'confirm',
                inputRef: register(),
              }}
            />
            {
            errors.confirm && <AlertBox severity="error">{errors.confirm.message}</AlertBox>
          }
          </InputBox>
        </CardBody>

        <CardFooter className={cls.cardFooter}>
          <Button
            simple
            id="login-btn"
            color="primary"
            size="lg"
            type="submit"
          >
            Підтвердити
          </Button>
          <Grid container>
            <Grid item xs={12} />
            <Grid item className={cls.textAlignRight} xs={12}>
              <Link to="/">Вхід</Link>
            </Grid>
          </Grid>
        </CardFooter>
      </form>
    </AuthLayout>
  );
};

export default ConfirmResetPassword;
