import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { CourseTextArea } from '../../styles';
import { CustomTabPanel } from '../../../../components/CustomTabs';
import Editor from '../../../../../components/Editor/Editor';

export const useStyles = makeStyles((theme) => createStyles({
  textarea: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Glosariy({ courseData, changeHandler }) {
  const cls = useStyles();

  const { glosariyDescription, glosariyHtml } = courseData;

  const [glosariyHtmlInit] = useState(glosariyHtml);

  const changeGlosariyHtml = (html) => changeHandler('glosariyHtml', html);
  const changeGlosariyDescription = (e) => changeHandler('glosariyDescription', e.target.value);

  return (
    <CustomTabPanel index={4}>
      <CourseTextArea
        className={cls.textarea}
        value={glosariyDescription}
        onChange={changeGlosariyDescription}
        label="Короткий опис"
      />
      <Editor
        onChange={changeGlosariyHtml}
        setContents={glosariyHtmlInit}
      />
    </CustomTabPanel>
  );
}
