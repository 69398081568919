import { setBySlug } from 'cabinet/store/actions/coursesActions';
import { setQuestionnaireById } from 'cabinet/store/actions/questionnaireActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useCourseQuestionnaire = () => {
  const { courseSlug } = useParams();
  const dispatch = useDispatch();
  const {
    courses, questionnaire, user, userStory,
  } = useSelector((state) => state.client);

  useEffect(() => {
    dispatch(setBySlug(courseSlug));
    // courseSlug, dispatch
    // eslint-disable-next-line
  }, []);

  const course = !!courses && courses.find(({ slug }) => slug === courseSlug);

  useEffect(() => {
    if (!course) return;

    dispatch(setQuestionnaireById(course.questionnaire));

    // dispatch
    // eslint-disable-next-line
  }, [course]);

  const isLoading = course
    ? questionnaire
      ? !userStory
      : true
    : true;

  return {
    isLoading, questionnaire, course, user, dispatch, userStory,
  };
};
