import React from 'react';
import { Link } from 'react-router-dom';

const About = () => (
  <div>
    about page
    <Link to="/admin">home admin</Link>
  </div>
);

export default About;
