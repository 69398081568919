import React from 'react';
import styles from 'assets/jss/material-kit-react/components/headerStyle.js';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    ...styles.container,
    display: 'block',
  },
});

export const Container = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {children}
    </div>
  );
};
