import React from 'react';
import Editor from '../../../../components/Editor/Editor';
import {
  Wrapper,
} from './styles';

// eslint-disable-next-line react/prefer-stateless-function
const EditorBlock = ({
  changeLectureValue,
  id,
  defaultValue,
}) => (
  <Wrapper>
    <Editor
      id={id}
      setContents={defaultValue}
      onChange={changeLectureValue}
    />
  </Wrapper>
);

export default EditorBlock;
