import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import Alert from '@material-ui/lab/Alert';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
// core components
import Button from 'cabinet/components/ui/CustomButtons/Button';
import CardBody from 'cabinet/components/ui/Card/CardBody';
import CardHeader from 'cabinet/components/ui/Card/CardHeader';
import CardFooter from 'cabinet/components/ui/Card/CardFooter';
import CustomInput from 'cabinet/components/ui/CustomInput/CustomInput';
import styles from 'assets/jss/material-kit-react/views/loginPage';
import AuthLayout from 'cabinet/layout/Auth';
// store
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

// router
import { Link, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { loginUser } from '../../store/actions/userActions';

// validation function
import { validateEmailField, validatePasswordField } from '../../../utils/validate';
import {
  AlertBox, InputBox, ResetPasswordContainer, ResetPasswordLink,
} from './styles';

export const useStyles = makeStyles(styles);

export default function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, isLogin, error } = useSelector((state) => state.client.user);
  // state components
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const classes = useStyles();

  const validateEmail = () => {
    const resultValidateEmail = validateEmailField(email);

    if (resultValidateEmail.length === 0) {
      setEmailError(false);
    } else {
      setEmailError(resultValidateEmail);
    }

    return resultValidateEmail.length === 0;
  };

  const validatePassword = () => {
    const resultValidatePassword = validatePasswordField(password);

    if (resultValidatePassword.length === 0) {
      setPasswordError(false);
    } else {
      setPasswordError(resultValidatePassword);
    }

    return resultValidatePassword.length === 0;
  };

  const sendData = () => {
    if (validateEmail() && validatePassword()) {
      dispatch(loginUser({ email, password }));
    }
  };

  useEffect(() => {
    const redirectIsLogin = () => {
      if (isLogin) {
        history.push('/');
      }
    };

    redirectIsLogin();

    // history
    // eslint-disable-next-line
  }, [isLogin]);

  return (
    <AuthLayout>
      <Helmet>
        <title>Вхід до системи | Навчальна платформа</title>
      </Helmet>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          sendData();
        }}
      >
        <CardHeader color="primary" className={classes.cardHeader}>
          <h4>Вхід до системи</h4>
        </CardHeader>
        <CardBody>
          <InputBox>

            <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'email',
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                value: email,
                onChange: (e) => setEmail(e.target.value),
                onBlur: () => validateEmail(),
                error: Boolean(emailError),
              }}
            />
            {
              emailError && (
                <AlertBox id="email-error" severity="error">{ emailError[0] }</AlertBox>
              )
            }
          </InputBox>
          <InputBox>
            <CustomInput
              labelText="Пароль"
              id="pass"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                value: password,
                autoComplete: 'off',
                onChange: (e) => setPassword(e.target.value),
                onBlur: () => validatePassword(),
                error: Boolean(passwordError),
              }}
            />
            {
              passwordError && (
                <AlertBox severity="error">{ passwordError[0] }</AlertBox>
              )
            }
          </InputBox>
          <ResetPasswordContainer>
            <ResetPasswordLink to="/reset-password">Забули пароль?</ResetPasswordLink>
          </ResetPasswordContainer>
        </CardBody>
        {
          error && (
            <Alert id="form-error" severity="error">{ error }</Alert>
          )
        }
        <CardFooter className={classes.cardFooter}>
          {loading ? (
            <CircularProgress color="primary" size={25} />
          ) : (
            <Button
              simple
              id="login-btn"
              color="primary"
              size="lg"
              type="submit"
            >
              Вхід
            </Button>
          )}
          <Grid container>
            <Grid item xs={12} />
            <Grid item className={classes.textAlignRight} xs={12}>
              <Link to="/signup">Реєстрація</Link>
            </Grid>
          </Grid>
        </CardFooter>
      </form>
    </AuthLayout>
  );
}
