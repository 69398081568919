export const styles = (theme) => ({
  iconButtonAvatar: {
    padding: 4,
  },
  appBar: {
    zIndex: 1203,
  },
  button: {
    borderColor: 'rgba(255, 255, 255, 0.7)',
  },
});
