export const styles = (theme) => ({
  item: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  logoImage: {
    maxWidth: '100px',
  },
});
