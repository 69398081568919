import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllCourses } from '../../store/actions/coursesActions';
import { setLessonsByIds } from '../../store/actions/lessonsAction';
import { checkIds } from '../../../utils/checkIds';
import { getInformationUser } from '../../store/actions/userActions';
import { setAllNewUsers } from '../../store/actions/newUsersAction';

export const useDashboard = () => {
  const dispatch = useDispatch();

  const {
    courses, lessons, newUsers, user, userStory,
  } = useSelector((state) => state.client);
  const isConfirmAdmin = !!(user.userProfileData && user.userProfileData.isConfirmAdmin);
  const [lessonsLoading, setLessonsLoading] = useState(true);

  useEffect(() => {
    dispatch(getInformationUser());
    dispatch(setAllNewUsers());
    dispatch(setAllCourses());

    // dispatch
    // eslint-disable-next-line
  }, []);

  const course = courses ? courses.find(({ courseId }) => courseId === 'dashboard') : null;
  const loading = lessonsLoading
    ? true
    : !course
      ? true
      : !userStory
        ? true
        : !userStory.courses;

  const isNotConfirm = newUsers ? !!newUsers.find((id) => id === user.user.uid) : false;

  useEffect(() => {
    if (!course) return;
    dispatch(setLessonsByIds(course.lessons));

    // dispatch
    // eslint-disable-next-line
  }, [course]);

  useEffect(() => {
    if (!lessons || !course) return;

    if (checkIds(course.lessons, lessons)) {
      setLessonsLoading(false);
    }
  }, [lessons, course]);

  return {
    course, loading, lessons, isNotConfirm, userStory, verifyUser: isConfirmAdmin, user,
  };
};
