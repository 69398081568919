export const USER_STORY_SET = 'USER_STORY_SET';

const initialState = null;

export const userStoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_STORY_SET:
      // if state === undefined => redux throw error
      return payload === undefined
        ? { isUndefined: true }
        : { ...payload };
    default:
      return state;
  }
};
