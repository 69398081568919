import { config } from 'dotenv';
import React from 'react';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter } from 'react-router-dom';
import { store } from './globalStore';
import App from './App';

config();

const initApp = () => (
  <Provider store={store}>
    <React.StrictMode>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </React.StrictMode>
  </Provider>
);

export default initApp;
