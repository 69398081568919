import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HeaderButton } from '../../components/Header';
import Page from '../../components/Page';
import {
  useStyles,
} from './styles';
import ThemeItem from './ThemeItem';
import EditorBlock from './EditorBlock';
import { replaceInvalidSlug, validateFieldIsRequired } from '../../../utils/validate';
import {
  getLectureData,
  saveLectureInDataBase,
  setLectureItemData,
  updatedLecture,
} from '../../store/actions/lectureAction';
import { slugWithCheckDb } from '../../store/validations/base';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const CreatedLecture = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, lectureItemData } = useSelector((state) => state.admin.lecture);
  const cls = useStyles();

  // state components
  const isEditLecture = !!id;

  // ???
  const [value, setValue] = useState(0);

  const [initialSlug, setInitialSlug] = useState('');
  const [description, setDescription] = useState('');
  const [nameLecture, setNameLecture] = useState('');
  const [nameLectureError, setNameLectureError] = useState(false);
  const [contentLecture, setContentLecture] = useState('');
  const [defaultContentLecture, setDefaultContentLecture] = useState('');
  const [themeLecture, setThemeLecture] = useState([]);
  const [slug, setSlug] = useState('');
  const [slugError, setSlugError] = useState(null);
  const [dataState, setDataState] = useState({});

  useEffect(() => {
    if (isEditLecture) {
      dispatch(getLectureData(id));
    }

    return () => {
      dispatch(setLectureItemData({}));
      setDefaultContentLecture('');
      setDataState({});
      setThemeLecture([]);
    };

    // dispatch, isEditLecture
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isEditLecture) {
      const {
        title = '',
        content = '',
        themes = [],
        slug = lectureItemData.id,
        description,
      } = lectureItemData;

      setInitialSlug(slug);
      const stateDefault = {};
      const editThemes = themes.map((item, index) => {
        stateDefault[index] = item.content;
        return {
          ...item,
          defaultValue: item.content,
          error: [],
        };
      });

      setDataState(stateDefault);
      setNameLecture(title);
      setThemeLecture(editThemes);
      setSlug(slug);
      setDescription(description);
      setContentLecture(content);
      setDefaultContentLecture(content);
    }

    // isEditLecture
    // eslint-disable-next-line
  }, [lectureItemData]);

  const validationField = () => {
    const nameValid = validateFieldIsRequired(nameLecture);
    setNameLectureError(nameValid);

    const lectureContent = [];
    let countValid = 0;
    themeLecture.forEach((item, index) => {
      const resultValid = validateFieldIsRequired(item.title);
      lectureContent[index] = {
        ...item,
        error: resultValid,
      };

      if (resultValid.length > 0) {
        countValid++;
      }
    });

    setThemeLecture(lectureContent);
    return nameValid.length === 0 && countValid === 0;
  };

  const changeLectureValue = (lectureValue) => {
    setContentLecture(lectureValue);
  };

  const addNewTheme = () => {
    const newTheme = themeLecture.map((item) => item);
    newTheme.push({
      title: '',
      content: '',
      defaultValue: '',
      index: newTheme.length,
      error: [],
    });

    setThemeLecture(newTheme);
  };

  const removeTheme = (index) => {
    const filterTheme = themeLecture.filter((item, ind) => index !== ind);
    setThemeLecture(filterTheme);
  };

  const changeValueTheme = (e, index) => {
    const key = e.target.name;
    const newValue = {
      ...themeLecture[index],
      [key]: e.target.value,
    };
    const newValueArray = themeLecture.map((item, ind) => {
      if (index === ind) {
        return newValue;
      }
      return item;
    });
    setThemeLecture(newValueArray);
  };

  const changeContentTheme = (editValue, index) => {
    const newValue = {
      ...dataState,
      [index]: editValue,
    };
    setDataState(newValue);
  };

  const validateSlug = async () => {
    let notValid = false;

    await slugWithCheckDb('lectures').validate(slug).catch((e) => {
      notValid = true;
      setSlugError(e.errors[0]);
    });

    return notValid;
  };

  const sendData = async () => {
    const isValid = validationField();

    if (initialSlug !== slug || slug === '') {
      if (await validateSlug()) {
        return;
      }
    } else {
      setSlugError(null);
    }

    if (isValid) {
      const sendData = {
        title: nameLecture,
        content: contentLecture,
        themes: [],
        slug,
        description,
      };
      themeLecture.forEach((item, index) => {
        sendData.themes[index] = {
          title: item.title,
          content: dataState[index],
        };
      });

      if (isEditLecture) {
        dispatch(updatedLecture({
          ...sendData,
          id: lectureItemData.id,
        }));
      } else {
        dispatch(saveLectureInDataBase(sendData))
          .then(() => {
            setNameLecture('');
            setThemeLecture([]);
            setDefaultContentLecture('');
            setSlug('');
            changeLectureValue('');
            setDescription('');
          });
      }
    }
  };

  const slugChangeHandler = (e) => {
    const value = replaceInvalidSlug(e.target.value);

    // optimization
    if (value === slug) return;

    setSlug(e.target.value);
  };

  return (
    <Page
      title={isEditLecture ? 'Редагування лекції' : 'Створити лекцію'}
      headerButton={(
        <HeaderButton
          onClick={sendData}
        >
          { isEditLecture ? 'Оновити' : 'Зберегти'}
        </HeaderButton>
      )}
    >
      {
        !isEditLecture && (
          <Paper variant="outlined" square>
            <Tabs
              variant="standart"
              className={cls.tabs}
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={(e, value) => setValue(value)}
            >
              <Tab label="Створити" />
            </Tabs>
          </Paper>
        )
      }
      <Paper className={cls.wrap} variant="outlined" square>

        {/* ??? */}
        <TabPanel className={cls.mainInputs} value={value} index={0}>

          <Box mb={3}>
            <Box mb={2}>
              <TextField
                fullWidth
                label="Назва лекції"
                value={nameLecture}
                error={nameLectureError.length > 0}
                helperText={nameLectureError[0]}
                onChange={(e) => setNameLecture(e.target.value)}
              />
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                label="Slug-id"
                value={slug}
                onChange={slugChangeHandler}
                error={!!slugError}
                helperText={slugError}
              />
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                label="Адмінський опис"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Box>
          <div>
            <div>Вступ</div>
            <EditorBlock
              id="description"
              defaultValue={defaultContentLecture}
              contentLecture={contentLecture}
              changeLectureValue={changeLectureValue}
            />
          </div>
          <Divider />
          <Box mt={3}>
            {
              themeLecture.map((item, index) => (
                <ThemeItem
                  key={index}
                  item={item}
                  index={index}
                  removeTheme={removeTheme}
                  changeValueTheme={(e, index) => changeValueTheme(e, index)}
                  setContentLecture={changeContentTheme}
                />
              ))
            }
          </Box>
          <Box mb={2} mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={addNewTheme}
            >
              Додати тему
            </Button>
          </Box>

          <Box mt={3} className={cls.boxBottom}>
            {
              loading
                ? <CircularProgress />
                : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={sendData}
                  >
                    { isEditLecture ? 'Оновити' : 'Зберегти'}
                  </Button>
                )
            }
          </Box>

        </TabPanel>

      </Paper>
    </Page>
  );
};

export default CreatedLecture;
