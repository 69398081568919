import React, { useEffect } from 'react';
import Content from 'components/Content';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import Header from '../../components/Header';
import ContentLoader from '../../components/ContentLoader';
import { setBySlug } from '../../store/actions/coursesActions';

export default function Glosariy(props) {
  const { courseSlug } = props.match.params;

  const dispatch = useDispatch();
  const courses = useSelector((state) => state.client.courses);

  const course = courses && courses.find((course) => course.slug === courseSlug);

  useEffect(() => {
    dispatch(setBySlug(courseSlug));

    // dispatch, courseSlug
    // eslint-disable-next-line
  }, []);

  if (!courses || !course) return (<ContentLoader />);

  const { title, subTitle, glosariyHtml } = course;

  return (
    <>
      <Header />
      <Page
        supTitle={title}
        subTitle={subTitle}
      >
        <Content dangerouslySetInnerHTML={{ __html: glosariyHtml }} />
      </Page>
    </>
  );
}
