export const TEST_RESULT_SET = 'TEST_RESULT_SET';

const initialState = null;

export const testResultReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TEST_RESULT_SET:
      return { ...state, ...payload };
    default:
      return state;
  }
};
