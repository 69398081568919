import { setMessages, removeMessage } from 'admin/store/actions/messagesAction';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Header from '../Header';
import Content from './Content';

const Page = ({ title, children, headerButton }) => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.admin.messages);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!messages) return;

    const haveNewMessages = !!Object.values(messages).find((msg) => msg.showed !== true);

    if (!haveNewMessages) return;

    const state = { ...messages };

    Object.entries(messages).forEach(([id, message]) => {
      state[id] = { ...message, showed: true };

      enqueueSnackbar(message.text, {
        onClose: () => dispatch(removeMessage(id)),
        variant: message.variant,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    });

    dispatch(setMessages(state));

    // dispatch, enqueueSnackbar
    // eslint-disable-next-line
  }, [messages]);

  return (
    <>
      <Header title={title} button={headerButton} />
      <Content>
        {children}
      </Content>
    </>
  );
};

export default Page;
