import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import {
  WrapperInput,
  useStyles,
} from './styles';

const Answer = ({
  type = 'radio',
  removeAnswer,
  handleChange,
  changeField,
  value,
  valueField,
  answers,
  error = [],
}) => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between">
      {
        type === 'radio' && (
          <Radio
            className={classes.radioButton}
            onChange={() => handleChange(type, value)}
            color="primary"
            name="answer"
            value={value}
          />
        )
      }
      {
        type === 'checkbox' && (
          <Checkbox
            onChange={() => handleChange(type, value)}
            checked={answers.includes(value)}
            color="primary"
            className={classes.radioButton}
          />
        )
      }
      <WrapperInput>
        <TextField
          label="Варіант"
          size="small"
          variant="outlined"
          color="primary"
          value={valueField}
          error={error.length > 0}
          helperText={error[0]}
          onChange={(e) => changeField(e.target.value)}
          className={classes.inputBackground}
        />
      </WrapperInput>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        onClick={removeAnswer}
      >
        Видалити
      </Button>
    </Grid>
  );
};

export default Answer;
