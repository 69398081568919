import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 40px 0;
`;

export const NumberBlock = styled.div`
  text-align: center;
  font-size: 170px;
  line-height: 180px;
  color: red;
  font-weight: 600;
  margin-bottom: 25px;
  
  @media screen and (max-width: 576px) {
    font-size: 100px;
    line-height: 110px;
  }
`;

export const Title = styled.h2`
  text-align: center;
  color: black;
  
  @media screen and (max-width: 576px) {
    font-size: 30px;
    line-height: 38px;
  }
`;

export const Description = styled.strong`
  display: block;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
