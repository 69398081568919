import { A_LESSONS_SET_LESSONS, A_LESSONS_SET_LESSON, A_LESSONS_DELETE_LESSON } from '../reducers/lessons';
import { db } from '../../../utils/fire';

export const setLessons = (payload) => ({
  type: A_LESSONS_SET_LESSONS,
  payload,
});

export const setLesson = (payload) => ({
  type: A_LESSONS_SET_LESSON,
  payload,
});

export const deleteLesson = (payload) => ({
  type: A_LESSONS_DELETE_LESSON,
  payload,
});

const lessonsRef = db.collection('lessons');
const coursesRef = db.collection('courses');
const lecturesRef = db.collection('lectures');

export const deleteLessonById = (id) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { lessons } = state.admin;
    const lesson = lessons[id];

    if (lesson.courseId) {
      const courseResult = await coursesRef.doc(lesson.courseId).get();
      const course = courseResult.data();
      course.lessons = course.lessons.filter((id) => id !== lesson.id);

      await coursesRef.doc(lesson.courseId).set(course);
    }

    if (lesson.lectures.length) {
      const lecturesResult = await lecturesRef.where('lessonId', '==', lesson.id).get();
      const lectures = lecturesResult.docs.map((doc) => doc.data());

      await Promise.all(lectures.map((lecture) => lecturesRef.doc(lecture.id).update({ lessonId: null })));
    }

    await lessonsRef.doc(id).delete();

    dispatch(deleteLesson(id));
  } catch (e) {
    console.error(e);
  }
};

export const setLessonById = (id) => async (dispatch) => {
  try {
    const lessonResult = await lessonsRef.doc(id).get();
    const lesson = { id: lessonResult.id, ...lessonResult.data() };

    dispatch(setLesson({ id: lesson.id, data: lesson }));
  } catch (e) {
    console.error(e);
  }
};

export const setAllLesons = () => async (dispatch) => {
  try {
    const lessonsResult = await lessonsRef.get();
    const lessons = {};

    lessonsResult.forEach((lesson) => lessons[lesson.id] = { ...lesson.data(), id: lesson.id });

    dispatch(setLessons(lessons));
  } catch (e) {
    console.error(e);
  }
};
