import { C_LESSONS_SET_LESSONS } from '../reducers/lessons';
import { db } from '../../../utils/fire';

export const setLessons = (payload) => ({
  type: C_LESSONS_SET_LESSONS,
  payload,
});

const lessonsRef = db.collection('lessons');

export const setLessonBySlug = (slug) => async (dispatch) => {
  try {
    const lessonsResult = await lessonsRef.where('slug', '==', slug).get();
    const lessons = lessonsResult.docs.map((doc) => doc.data());

    dispatch(setLessons(lessons));
  } catch (e) {
    console.error(e);
  }
};

export const setLessonsByIds = (ids) => async (dispatch) => {
  try {
    const lessonsResult = await lessonsRef.where('id', 'in', ids).get();
    const lessons = lessonsResult.docs.map((doc) => doc.data());

    dispatch(setLessons(lessons));
  } catch (e) {
    console.error(e);
  }
};

export const setLessonById = (id) => async (dispatch) => {
  try {
    const lessonResult = await lessonsRef.doc(id).get();
    const lesson = lessonResult.data();

    dispatch(setLessons([lesson]));
  } catch (e) {
    console.error(e);
  }
};
