import { v4 as uuid } from 'uuid';
import { A_M_REMOVE_MESSAGE, A_M_SET_MESSAGE, A_M_SET_MESSAGES } from '../reducers/messages';

export const setMessage = ({ text, variant }) => ({
  type: A_M_SET_MESSAGE,
  payload: {
    data: {
      text,
      variant,
    },
    id: uuid(),
  },
});

export const removeMessage = (payload) => ({
  type: A_M_REMOVE_MESSAGE,
  payload,
});

export const setMessages = (payload) => ({
  type: A_M_SET_MESSAGES,
  payload,
});
