import React from 'react';
import { Link } from 'react-router-dom';
import sad from 'assets/img/sad.svg';
import emoji from 'assets/img/emoji.svg';
import SecondaryButton from '@material-ui/core/Button';
import {
  Wrapper,
  Title,
  Description,
  TotalCount,
  Image,
  ImageWrapper,
  ResultDescription,
  Actions,
} from './styles';

const ResultTest = ({
  counter = 0,
  totalQuestion,
  isComposed = false,
  reloadTest,
  type,
  nextLink,
  isLastlectureInLesson,
  islectureInUserStory,
  lessonSlug,
  courseSlug,
}) => (
  <Wrapper>
    <Title>
      Результати тесту:
    </Title>
    <Description>
      Набрано балів:
      <br />
      { counter }
      {' '}
      /
      {' '}
      <TotalCount>
        { totalQuestion }
      </TotalCount>
    </Description>
    <ImageWrapper>
      <Image src={isComposed ? emoji : sad} />
    </ImageWrapper>
    <ResultDescription composed={isComposed}>
      {
          isComposed ? 'Тест складено успішно' : 'Тест провалено'
        }
    </ResultDescription>
    <Actions>
      <SecondaryButton
        color="secondary"
        variant="contained"
        onClick={reloadTest}
      >
        Пройти тест ще раз
      </SecondaryButton>
      {
        (type === 'lecture' && isComposed && !isLastlectureInLesson && nextLink !== null) && (
          <Link to={nextLink}>
            <SecondaryButton color="primary" variant="contained">Перейти до наступної лекції</SecondaryButton>
          </Link>
        )
      }

      {
        (type === 'lecture' && isComposed && isLastlectureInLesson && islectureInUserStory) && (
          <Link to={`/${courseSlug}/${lessonSlug}/test`}>
            <SecondaryButton color="primary" variant="contained">Перейти до контрольного тесту</SecondaryButton>
          </Link>
        )
      }
    </Actions>
  </Wrapper>
);

export default ResultTest;
