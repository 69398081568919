import { Box, Paper, Typography } from '@material-ui/core';
import Page from 'admin/components/Page';
import { setQuestionnaireResultById } from 'admin/store/actions/questionnaireResultAction';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const Item = ({ title, value }) => (
  <Box
    padding={3}
    marginBottom={2}
    component={Paper}
    variant="outlined"
  >
    <Typography variant="h5">{title}</Typography>

    <Box marginTop={1}>
      <Typography variant="body1" component="div">{value}</Typography>
    </Box>
  </Box>
);

const QuestionnaireResult = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { questionnaireResult } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(setQuestionnaireResultById(id));

    // dispatch,id
    // eslint-disable-next-line
  }, []);

  const title = `Результат анкети ${id}`;

  const {
    questionnaire,
    courseId,
    answers,
    courseTitle,
    questionnaireTitle,
    userId,
  } = questionnaireResult;

  return (
    <Page title={title}>
      { courseTitle && (<Item title="Заголовок курсу" value={courseTitle} />) }
      { questionnaireTitle && (<Item title="Заголовок анкети" value={questionnaireTitle} />) }
      { answers && answers.map(({ question, value }, i) => (
        <Item
          key={i}
          title={`${question} (Питання номер ${i + 1})`}
          value={value}
        />
      ))}
      { userId && (<Item title="Id користовача" value={userId} />) }
      { courseId && (<Item title="Id курсу" value={courseId} />) }
      { questionnaire && (<Item title="Id анкети" value={questionnaire} />) }
    </Page>
  );
};

export default QuestionnaireResult;
