import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  TableItem, TableItemTitle, TableDeleteButton, TableItemButtons, TableEditButton,
} from '../../components/Table';
// eslint-disable-next-line import/no-cycle
import PageWithList from '../../components/PageWithList';
import { getAllLectureInBase, removeLectureInDateBase } from '../../store/actions/lectureAction';
import ConfirmDelete from '../../components/ConfirmDelete';
import { Description } from './styles';

const Lectures = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { lectures } = useSelector((state) => state.admin.lecture);

  useEffect(() => {
    dispatch(getAllLectureInBase());

    // dispatch
    // eslint-disable-next-line
  }, []);

  // state components
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteLecture, setDeleteLecture] = useState({});

  const deleteLectuteAction = () => {
    dispatch(removeLectureInDateBase(deleteLecture.id));
    setShowConfirm(false);
    setDeleteLecture({});
  };

  const confirmDeleteLecture = (lecture) => {
    setShowConfirm(true);
    setDeleteLecture(lecture);
  };

  return (
    <>
      <PageWithList
        title="Лекції"
        tableTitle="Список лекцій"
        createButtonProps={{
          onClick: () => history.push('/admin/lecture-created'),
        }}
        listItems={
          lectures.map((item, i) => (
            <TableItem key={i}>
              <div>
                <TableItemTitle>
                  { item.title }
                </TableItemTitle>
                {
                  item.description && (
                    <Description>
                      {item.description}
                    </Description>
                  )
                }
              </div>

              <TableItemButtons>
                <Link to={{ pathname: `/admin/lecture/${item.id}/edit` }}>
                  <TableEditButton />
                </Link>
                <TableDeleteButton onClick={() => confirmDeleteLecture(item)} />
              </TableItemButtons>
            </TableItem>
          ))
        }
      />
      <ConfirmDelete
        title="Ви впевнені, що бажаєте видалити лекцію?"
        description="Після видалення лекціі ії відновити буде неможливо"
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={deleteLectuteAction}
      />
    </>
  );
};

export default Lectures;
