import React from 'react';
import { Switch, Route } from 'react-router-dom';
import InvalidData from 'cabinet/views/InvalidData';
import ResetPassword from 'cabinet/views/ResetPassword';
import ConfirmResetPassword from 'cabinet/views/ConfirmResetPassword';
import CourseQuestionnaire from 'cabinet/views/CourseQuestionnaire';
import RegQuestionnaire from 'cabinet/views/RegQuestionnaire';
import ProtectedRoute from './ProtectedRoute';
import UnregisteredRoute from './UnregisteredRoute';
import Login from '../views/Login';
import Lecture from '../views/Lecture';
import Logout from '../views/Logout';
import Registration from '../views/Registration';
import Dashboard from '../views/Dashboard';
import Test from '../views/Test';
import Lesson from '../views/Lesson';
import Profile from '../views/Profile';
import Glosariy from '../views/Glosariy';
import Structure from '../views/Structure/Structure';
import PageNotFound from '../views/PageNotFound';
import Library from '../views/Library';

const Router = () => (
  <Switch>
    <UnregisteredRoute exact path="/" component={Login} />
    <UnregisteredRoute exact path="/logout" component={Logout} />
    <UnregisteredRoute exact path="/singin" component={Login} />
    <UnregisteredRoute exact path="/signup" component={Registration} />
    <UnregisteredRoute exact path="/reset-password" component={ResetPassword} />
    <UnregisteredRoute exact path="/reset-password/:token" component={ConfirmResetPassword} />
    <ProtectedRoute exact path="/dashboard" component={Dashboard} />

    <ProtectedRoute exact path="/reg-questionnaire" component={RegQuestionnaire} />

    <ProtectedRoute exact path="/profile" component={Profile} />
    <ProtectedRoute exact path="/:courseSlug/questionnaire" component={CourseQuestionnaire} />
    <ProtectedRoute exact path="/:courseSlug/glosariy" component={Glosariy} />
    <ProtectedRoute exact path="/:courseSlug/library" component={Library} />
    <ProtectedRoute exact path="/:courseSlug/structure" component={Structure} />
    <ProtectedRoute exact path="/:courseSlug/test" component={Test} />
    <ProtectedRoute exact path="/:courseSlug/:lessonSlug" component={Lesson} />
    <ProtectedRoute exact path="/:courseSlug/:lessonSlug/test" component={Test} />
    <ProtectedRoute exact path="/:courseSlug/:lessonSlug/:lectureSlug" component={Lecture} />
    <ProtectedRoute exact path="/:courseSlug/:lessonSlug/:lectureSlug/test" component={Test} />
    <ProtectedRoute exact path="/invalid-data" component={InvalidData} />
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default Router;
