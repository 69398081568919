import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, TextField } from '@material-ui/core';
import EditorBlock from '../EditorBlock';

const ThemeItem = ({
  item = {},
  removeTheme,
  changeValueTheme,
  setContentLecture,
  index,
}) => (
  <Box mt={3}>
    <Grid container justify="space-between">
      <h4>
        Тема
        {' '}
        {index + 1}
        :
      </h4>
      <IconButton aria-label="delete" onClick={() => removeTheme(index)}>
        <DeleteIcon />
      </IconButton>
    </Grid>
    <div>
      <Box mb={3}>
        <TextField
          fullWidth
          value={item.title}
          name="title"
          error={item.error.length > 0}
          helperText={item.error[0]}
          onChange={(e) => changeValueTheme(e, index)}
          label="Назва теми"
        />
      </Box>
      <EditorBlock
        id={index}
        contentLecture={item.content}
        defaultValue={item.defaultValue}
        changeLectureValue={(value) => setContentLecture(value, index)}
      />
    </div>
  </Box>
);

export default ThemeItem;
