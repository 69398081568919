import { eventsCollection, mapResultToDocs } from 'utils/fire';
import { CERTIFICATE } from './types';
import { generateLog } from './utils';

export const generateCertificateLog = ({
  email, userId, pathToCertificate, title,
}) => {
  const data = {
    pathToCertificate,
    email,
    title,
    isNew: true,
  };

  return generateLog(data, CERTIFICATE, userId);
};

export const getAllCertificatesLogs = async () => {
  const eventsResult = await eventsCollection
    .where('__action', '==', CERTIFICATE)
    // .orderBy('__timestamp', 'desc')
    .get();

  const eventsDocs = mapResultToDocs(eventsResult);
  return eventsDocs;
};
