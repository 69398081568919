import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import Card from '../ui/Card/Card';

export const CardCourse = styled(Card)`
  ${({ flexGrow }) => flexGrow && css`flex-grow: 1;`}

  padding: 41px 30px 34px 30px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);
  
  @media screen and (max-width: 575px) {
    margin: 0 !important;
    padding: 31px 28px 35px 28px;
  }
`;

export const CardName = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 31px;
  
  @media screen and (max-width: 575px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 18px;
  }
`;

export const CardDescription = styled.span`
  display: block;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  color: #3D4B55;
  margin-bottom: 31px;
  
  @media screen and (max-width: 575px) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 18px;
  }
`;

export const DownloadButton = styled(Button)`
  width: fit-content !important;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto auto 0 !important;
  padding: 0 26px !important;

  font-family: 'Roboto';
  color: #1499FE !important;
  font-size: 13px !important;
  line-height: 17px !important;

  border-radius: 3px;
  border: 1.5px solid white;
  box-shadow: 0px 2px 2px rgba(156, 39, 176, 0.14) !important;
  
  &:hover {
    box-shadow: 0px 2px 2px rgba(156, 39, 176, 0.14);
    
    border: 1.5px solid #1499FE;

    color: #1499FE;

    background-color: white;
  }
  
  ${({ disabled }) => (
    disabled
      ? css`
          span {
            color: silver;
          }
        `
      : null
  )}
  @media screen and (max-width: 575px) {
    height: 46px !important;
    padding: 0 20px;
    font-size: 12px !important;
  }
`;
