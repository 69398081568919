import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  rightContent: {
    backgroundColor: '#eaeff1',
  },
  buttonCreated: {
    color: 'white !important',
  },
}));
