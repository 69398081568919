import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  WrapperLink,
  LoaderWrapper,
  useStyles,
} from './styles';

const ListItemLink = ({
  to, children, ...other
}) => (
  <WrapperLink to={to} activeClassName="active">
    <ListItem button {...other}>
      { children }
    </ListItem>
  </WrapperLink>
);

const ListQuestions = ({ list, loadingQuestions }) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  const filterResultSearch = (e) => {
    const valueField = e.target.value;
    // eslint-disable-next-line max-len
    const filterArray = list.filter((item) => item.question.toLowerCase().indexOf(valueField.toLowerCase()) >= 0);
    setSearchValue(valueField);
    setSearchResult(filterArray);
  };

  useEffect(() => {
    setSearchResult(list);
  }, [list]);

  return (
    <Box mr={1}>
      <TextField
        id="filled-basic"
        label="Пошук тесту"
        fullWidth
        value={searchValue}
        onChange={(e) => filterResultSearch(e)}
        size="small"
        variant="filled"
      />
      {
        loadingQuestions
          ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )
          : (
            <List component="nav" aria-label="secondary mailbox folders" className={classes.navLink}>
              {
                searchResult.length === 0 && (
                  <strong>Співпадінь не знайдено</strong>
                )
              }
              {
                searchResult.map((item, index) => (
                  <ListItemLink
                    key={index}
                    to={`/admin/test/${item.id}`}
                  >
                    <ListItemText primary={item.question} className={classes.link} />
                  </ListItemLink>
                ))
              }
            </List>
          )
      }
    </Box>
  );
};

export default ListQuestions;
