import React, { useState } from 'react';
import { CourseTextArea } from '../../styles';
import { useStyles } from '../Glosariy/Glosariy';
import { CustomTabPanel } from '../../../../components/CustomTabs';
import Editor from '../../../../../components/Editor/Editor';

export default function Structure({ courseData, changeHandler }) {
  const cls = useStyles();

  // const dispatch = useDispatch();
  const { structureDescription, structureHtml } = courseData;

  const [initStructureHtml] = useState(structureHtml);

  const changeStructureHtml = (html) => changeHandler('structureHtml', html);
  const changeStructureDescription = (e) => changeHandler('structureDescription', e.target.value);

  return (
    <CustomTabPanel index={1}>
      <CourseTextArea
        className={cls.textarea}
        value={structureDescription}
        onChange={changeStructureDescription}
        label="Короткий опис"
      />
      <Editor
        onChange={changeStructureHtml}
        setContents={initStructureHtml}
      />
    </CustomTabPanel>
  );
}
