import React from 'react';
import {
  Card, CardContent, Typography, CardActionArea, Grid,
} from '@material-ui/core';
import { useStyles } from './styles';

export default function DashboardCard({
  onClick, title, icon, count,
}) {
  const cls = useStyles();

  return (
    <Grid
      item
      className={cls.card}
      xs={12}
      md={6}
      onClick={onClick}
      component={Card}
    >
      <CardActionArea>
        <CardContent>

          <Typography
            variant="h6"
            component="h2"
            gutterBottom
          >
            {title}
          </Typography>

          <Grid
            container
            className={cls.cardMiddle}
            justify="space-between"
            alignItems="center"
          >

            <Grid
              item
              component={icon}
            />

            <Grid
              item
            >
              {count}
            </Grid>
          </Grid>

        </CardContent>
      </CardActionArea>
    </Grid>
  );
}
