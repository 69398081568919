import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageWithList from '../../components/PageWithList';
import {
  TableItem, TableItemTitle, TableItemButtons, TableEditButton, TableDeleteButton,
} from '../../components/Table';
import { setAllLesons, deleteLessonById } from '../../store/actions/lessonsAction';
import { useLessons } from './useLessons';
import ConfirmDelete from '../../components/ConfirmDelete';

export default function Lessons() {
  const lessons = useLessons();
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [lessonId, setLessonId] = useState(null);

  useEffect(() => {
    dispatch(setAllLesons());

    // dispatch
    // eslint-disable-next-line
  }, []);

  const deleteCloseHandler = () => setShow(false);

  const deleteLessonHandler = () => {
    deleteCloseHandler();
    dispatch(deleteLessonById(lessonId));
    setLessonId(null);
  };

  const deleteButtonHandler = (lessonId) => () => {
    setLessonId(lessonId);
    setShow(true);
  };

  return (
    <>
      <PageWithList
        title="Модулі"
        tableTitle="Список модулів"
        createButtonProps={{
          onClick: () => history.push('/admin/lesson/create'),
        }}
        listItems={Object.values(lessons).map(({ title, id }, i) => (
          <TableItem key={i}>
            <TableItemTitle>{title}</TableItemTitle>

            <TableItemButtons>
              <TableEditButton onClick={() => history.push(`/admin/lesson/${id}/edit`)} />
              <TableDeleteButton onClick={deleteButtonHandler(id)} />
            </TableItemButtons>
          </TableItem>
        ))}
      />
      <ConfirmDelete
        title="Ви впевнені, що бажаєте видалити модуль?"
        description="Після видалення модуля відновити його буде неможливо"
        open={show}
        handleClose={() => {
          setShow(false);
        }}
        handleConfirm={deleteLessonHandler}
      />
    </>
  );
}
