import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import { selectCities } from 'const';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Person from '@material-ui/icons/Person';
import Password from '@material-ui/icons/Lock';
// layout
import Signup from 'cabinet/layout/Signup';
// core components
import Button from 'cabinet/components/ui/CustomButtons/Button';
import CardBody from 'cabinet/components/ui/Card/CardBody';
import CardHeader from 'cabinet/components/ui/Card/CardHeader';
import CardFooter from 'cabinet/components/ui/Card/CardFooter';
import CustomInput from 'cabinet/components/ui/CustomInput/CustomInput';
import styles from 'assets/jss/material-kit-react/views/signupPage';
import Select from 'cabinet/components/ui/Select';

import Grid from '@material-ui/core/Grid';
// store
import { useDispatch, useSelector } from 'react-redux';
// router
import { useHistory, Link } from 'react-router-dom';

// validate function
import { Box } from '@material-ui/core';
import {
  validateEmailField,
  validateFieldIsRequired,
  validatePasswordField,
  validatePhone,
  validateYearOfBirth,
} from '../../../utils/validate';
import {
  SelectBlock,
  ControlBlock,
  Checkbox,
  FormControlLabel,
  InputBox,
  AlertBox,
  CheckboxCityBox,
// eslint-disable-next-line import/named
} from './styles';
import { registrationUser } from '../../store/actions/userActions';

const useStyles = makeStyles(styles);

const defaultInputs = {
  name: {
    value: '',
    error: null,
    validate: true,
  },
  lastName: {
    value: '',
    error: null,
    validate: true,
  },
  yearOfBirth: {
    value: '',
    error: null,
    validate: true,
  },
  phone: {
    value: '',
    error: null,
    validate: true,
  },
  region: {
    value: null,
    error: null,
    validate: true,
  },
  city: {
    value: null,
    error: null,
    validate: true,
  },
  isCityInput: {
    value: false,
  },
  school: {
    value: '',
    error: null,
    validate: true,
  },
  class: {
    value: '',
    error: null,
  },
  isClassTeacher: {
    value: false,
    error: null,
  },
  countOfStudents: {
    value: '',
    error: null,
  },
  email: {
    value: '',
    error: null,
    validate: true,
  },
  password: {
    value: '',
    error: null,
    validate: true,
  },
  personalData: {
    value: false,
    error: null,
  },
};

const copyDefault = { ...defaultInputs };

const SignUpPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.client.user);
  const [isLoading, setLoading] = useState(false);
  const [inputs, setInputs] = useState(copyDefault);
  const [, setCardAnimation] = useState('cardHidden');
  setTimeout(() => {
    setCardAnimation('');
  }, 700);

  // regions and cities
  const region = inputs.region.value ? inputs.region.value.value : null;
  const citiesOptions = region !== null && selectCities.cities[region];
  const isCityDisabled = region === null;
  const isCityInput = inputs.isCityInput.value;

  const cityCheckboxRef = useRef();

  const validationField = () => {
    const newInputs = {};
    let countValidFiled = 0;
    Object.keys(inputs).forEach((item) => {
      let validValue = null;
      if (inputs[item].validate) {
        if (item === 'password') {
          validValue = validatePasswordField(inputs[item].value);
        } else if (item === 'email') {
          validValue = validateEmailField(inputs[item].value);
        } else if (item === 'yearOfBirth') {
          validValue = validateYearOfBirth(inputs[item].value);
        } else if (item === 'city') {
          validValue = validateFieldIsRequired(inputs[item].value?.value);
        } else if (item === 'phone') {
          validValue = validatePhone(inputs[item].value);
        } else {
          validValue = validateFieldIsRequired(inputs[item].value);
        }

        if (validValue.length > 0) {
          countValidFiled += 1;
        }

        newInputs[item] = {
          value: inputs[item].value,
          error: validValue.length === 0 ? null : validValue,
          validate: true,
        };
      } else {
        newInputs[item] = {
          value: inputs[item].value,
          error: null,
        };
      }
    });
    setInputs(newInputs);
    return countValidFiled === 0;
  };

  const validateIsTeacherForm = () => {
    let newInputs = {};
    let countValidFiled = 0;
    if (inputs.isClassTeacher.value) {
      ['class', 'countOfStudents'].forEach((item) => {
        const validValue = validateFieldIsRequired(inputs[item].value);
        if (validValue.length > 0) {
          countValidFiled += 1;
        }

        newInputs = {
          ...inputs,
          [item]: {
            value: inputs[item].value,
            error: validValue.length === 0 ? null : validValue,
          },
        };
      });
      setInputs(newInputs);
    }

    if (!inputs.personalData.value) {
      const data = {
        ...inputs,
        personalData: {
          value: inputs.personalData.value,
          error: ['Поле обов\'язкове'],
        },
      };
      setInputs(data);
      countValidFiled += 1;
    }
    return countValidFiled === 0;
  };

  const changeInput = (e, key) => {
    if (e !== null && e.target) {
      setInputs({
        ...inputs,
        [e.target.name]: {
          ...inputs[e.target.name],
          value: e.target.value,
        },
      });
    } else {
      if (key === 'region' || key === 'isCityInput') {
        setInputs({
          ...inputs,
          [key]: {
            ...inputs[key],
            value: e,
          },
          city: {
            ...inputs.city,
            value: null,
          },
        });
        return;
      }

      setInputs({
        ...inputs,
        [key]: {
          ...inputs[key],
          value: e,
        },
      });
    }
  };

  const changeIsTeacher = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: {
        value: e.target.checked,
      },
    });
  };

  const sendForm = () => {
    if (validationField() && validateIsTeacherForm() && !isLoading) {
      const sendData = {};
      const inputData = { ...inputs };
      Object.keys(inputData).forEach((item) => {
        sendData[item] = inputData[item].value;
      });

      setLoading(true);

      dispatch(registrationUser(sendData))
        .then(() => {
          setLoading(false);
          setInputs(copyDefault);
          history.push('/dashboard');
        });
    }
  };

  const classes = useStyles();

  return (
    <Signup>
      <Helmet>
        <title>Реєстрація | Навчальна платформа</title>
      </Helmet>
      <form className={classes.form}>
        <CardHeader color="primary" className={classes.cardHeader}>
          <h4>Реєстрація</h4>
          <p>Реєстрація починається 1 жовтня 2020 року</p>
        </CardHeader>
        <CardBody>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <CustomInput
                  labelText="Ім'я"
                  id="first"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'name',
                    value: inputs.name.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.name.error && (
                    <AlertBox severity="error">{ inputs.name.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <CustomInput
                  labelText="Прізвище"
                  id="second"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'lastName',
                    value: inputs.lastName.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.lastName.error && (
                    <AlertBox severity="error">{ inputs.lastName.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Alert severity="warning">Для отримання сертифікату будь ласка вказуйте правильне прізвище та ім'я українською мовою.</Alert>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'email',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'email',
                    value: inputs.email.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.email.error && (
                    <AlertBox severity="error">{ inputs.email.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <CustomInput
                  labelText="Пароль"
                  id="pass"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Password className={classes.inputIconsColor} />
                      </InputAdornment>
                    ),
                    autoComplete: 'off',
                    name: 'password',
                    value: inputs.password.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.password.error && (
                    <AlertBox severity="error">{ inputs.password.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <ControlBlock>
                  <CustomInput
                    labelText="Рік народження"
                    id="age"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'number',
                      name: 'yearOfBirth',
                      value: inputs.yearOfBirth.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                </ControlBlock>
                {
                  inputs.yearOfBirth.error && (
                    <AlertBox severity="error">{ inputs.yearOfBirth.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <ControlBlock>
                  <CustomInput
                    labelText="Номер телефону"
                    type="tel"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'tel',
                      name: 'phone',
                      value: inputs.phone.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                </ControlBlock>
                {
                  inputs.phone.error && (
                    <AlertBox severity="error">{ inputs.phone.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <SelectBlock>
                  <Select
                    label="Область"
                    id="region"
                    name="region"
                    placeholder="Виберіть область"
                    noOptionsMessage={() => 'Нічого не знайдено'}
                    options={selectCities.regions}
                    value={inputs.region.value}
                    onChange={(select) => {
                      changeInput(select, 'region');
                    }}
                  />
                </SelectBlock>
                {
                  inputs.region.error && (
                    <AlertBox $select severity="error">{ inputs.region.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                {
                  isCityInput
                    ? (
                      <SelectBlock style={{ marginBottom: 0 }}>
                        <CustomInput
                          labelText="Місто"
                          id="city"
                          formControlProps={{
                            fullWidth: true,
                            style: {
                              marginBottom: 8,
                            },
                          }}
                          inputProps={{
                            value: inputs.city.value?.label,
                            inputRef: cityCheckboxRef,
                            onChange: (e) => changeInput({ label: e.target.value, value: `${region}-${e.target.value}` }, 'city'),
                          }}
                        />
                      </SelectBlock>
                    )
                    : (
                      <SelectBlock>
                        <Select
                          label="Місто"
                          id="city"
                          name="city"
                          noOptionsMessage={() => 'Нічого не знайдено'}
                          isDisabled={isCityDisabled}
                          options={citiesOptions}
                          value={inputs.city.value}
                          placeholder={isCityDisabled ? 'Спочатку виберіть область!' : 'Виберіть місто'}
                          onChange={(select) => {
                            changeInput(select, 'city');
                          }}
                        />
                      </SelectBlock>
                    )

                }
                {
                  inputs.city.error && (
                    <AlertBox $select severity="error">{ inputs.city.error[0] }</AlertBox>
                  )
                }
                <CheckboxCityBox
                  label="Мого міста немає у списку"
                  disabled={isCityDisabled}
                  control={(
                    <Checkbox
                      checked={isCityInput}
                      color="primary"
                      onChange={(e, value) => {
                        changeInput(value, 'isCityInput');

                        if (value === true) {
                          setTimeout(() => {
                            cityCheckboxRef.current && cityCheckboxRef.current.focus();
                          }, 0);
                        }
                      }}
                    />
                  )}
                />
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <ControlBlock>
                  <CustomInput
                    id="school"
                    labelText="Школа"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'school',
                      value: inputs.school.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                </ControlBlock>
                {
                  inputs.school.error && (
                    <AlertBox severity="error">{ inputs.school.error[0] }</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
          </Grid>

          <Grid item xs={12} component={Box} padding={3} />

          <FormControlLabel
            control={<Checkbox color="primary" checked={inputs.isClassTeacher.value} onChange={(e) => changeIsTeacher(e)} name="isClassTeacher" />}
            label="Класний керівник"
          />
          {
            inputs.isClassTeacher.value && (
              <Grid container spacing={3}>
                <Grid item lg={6}>
                  <InputBox>
                    <CustomInput
                      labelText="Клас"
                      id="class"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'text',
                        name: 'class',
                        value: inputs.class.value,
                        onChange: (e) => changeInput(e),
                      }}
                    />
                    {
                      inputs.class.error && (
                        <AlertBox severity="error">{ inputs.class.error[0] }</AlertBox>
                      )
                    }
                  </InputBox>
                </Grid>
                <Grid item lg={6}>
                  <InputBox>
                    <CustomInput
                      labelText="Кількість учнів"
                      id="countOfStudents"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'number',
                        name: 'countOfStudents',
                        value: inputs.countOfStudents.value,
                        onChange: (e) => changeInput(e),
                      }}
                    />
                    {
                      inputs.countOfStudents.error && (
                        <AlertBox severity="error">{ inputs.countOfStudents.error[0] }</AlertBox>
                      )
                    }
                  </InputBox>
                </Grid>
              </Grid>
            )
          }
          <InputBox>
            <FormControlLabel
              styles={{ fontSize: '11px' }}
              control={<Checkbox color="primary" checked={inputs.personalData.value} onChange={(e) => changeIsTeacher(e)} name="personalData" />}
              label="Згода на обробку персональних даних"
            />
            {
              inputs.personalData.error && (
                <AlertBox severity="error">{ inputs.personalData.error[0] }</AlertBox>
              ) 
            }
          </InputBox>
        </CardBody>
        {
          error && <Alert severity="error">{ error }</Alert>
        }
        <CardFooter className={classes.cardFooter}>
          <Button disabled={isLoading} simple color="primary" size="lg" onClick={sendForm}>
            Реєстрація
          </Button>
          <Grid container>
            <Grid item xs={12} />
            <Grid item className={classes.textAlignRight} xs={12}>
              <Link to="/">Вхід</Link>
            </Grid>
          </Grid>
        </CardFooter>
      </form>
    </Signup>
  );
};

export default SignUpPage;
