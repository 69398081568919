import { setNotification } from 'store/actions/notificationAction';
import { generateQuestionnaireLog } from 'utils/events/questionnaire';
import { saveLog } from 'utils/events/utils';
import { questionnaireResultsCollection, questionnairesCollection } from 'utils/fire';
import { error } from 'utils/logger';
import { C_SET_QUESTIONNAIRE } from '../reducers/questionnaire';

const setQuestionnaire = (payload) => ({
  type: C_SET_QUESTIONNAIRE,
  payload,
});

export const setQuestionnaireById = (id) => async (dispatch) => {
  try {
    const docResult = await questionnairesCollection.doc(id).get();
    const questionnaire = { ...docResult.data(), id: docResult.id };

    dispatch(setQuestionnaire(questionnaire));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Під час загрузки анкети сталась помилка', variant: 'error' }));
  }
};

export const saveQuestionnaireResult = (data, showNotification = true) => async (dispatch) => {
  try {
    const surveResult = await questionnaireResultsCollection.add(data);

    const logData = {
      ...data,
      resultId: surveResult.id,
    };

    await saveLog(generateQuestionnaireLog(logData, logData.userId));

    if (showNotification) {
      dispatch(setNotification({ text: 'Результат анкети збережено', variant: 'success' }));
    }
  } catch (e) {
    error(e);

    if (showNotification) {
      dispatch(setNotification({ text: 'Під час зберігання результатів анкети сталась помилка', variant: 'error' }));
    }
  }
};
