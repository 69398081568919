import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, makeStyles, createStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: '100%',
    maxWidth: 800,
  },
}));

export default function UsersDialog({ dialogProps, title, children }) {
  const cls = useStyles();

  return (
    <Dialog
      {...dialogProps}
      classes={{
        paper: cls.paper,
      }}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}
