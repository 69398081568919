import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  TableItem,
  TableItemTitle,
  TableDeleteButton,
  TableItemButtons,
  TableEditButton,
  TableItemTitleRow
} from '../../components/Table';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import { deleteUserInBase, getAllUsersFromDateBase, getAllUsersCountFromDateBase } from '../../store/actions/usersAction';
import { setDashboardCertificateState } from 'admin/store/actions/logsActions';
import ConfirmDelete from '../../components/ConfirmDelete';
import PageWithList from '../../components/PageWithList';
import CustomInput from 'cabinet/components/ui/CustomInput/CustomInput';
import {
  makeStyles, createStyles, Button
} from '@material-ui/core';

const defaultInputs = {
  name: '',
  lastName: '',
  email: '',
  city: '',
};

const copyDefault = { ...defaultInputs };

const useStyles = makeStyles((theme) => createStyles({
  resetBtn: {
    cursor: 'pointer',
    marginLeft: '10px',
    '@media (max-width: 760px)':{
      display : 'block',
      margin : '0 auto'
    }
  },
  cellNumber: {
    maxWidth: '30px',
    '@media (max-width: 760xpx)':{
      maxWidth: '100%'
    }
  },
  tableRow: {
    '@media (max-width: 760px)':{
      marginBottom : '10px'
    }
  }
}));

export default function Users() {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.admin.user);
  const { logs } = useSelector((state) => state.admin);

  // state components
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteUser, setDeleteUser] = useState({});
  const [inputs, setInputs] = useState(copyDefault);
  const [searchInputs, setSearchInputs] = useState(copyDefault);
  const [page, setPage] = useState(1);
  const [countPages, setCountPages] = useState(0);

  const classes = useStyles();

  const dashboardState = logs.dashboard;

  useEffect(() => {
    dispatch(setDashboardCertificateState());
    // dispatch
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {
      name = '',
      email = '',
      lastName = '',
      city = ''
    } = searchInputs;
    const us = getAllUsersFromDateBase(page, name, lastName, city, email);
    dispatch(getAllUsersFromDateBase(page, name, lastName, city, email));
    getAllUsersCountFromDateBase(name, lastName, city, email)
      .then((res) => setCountPages(res));
  }, [
    page,
    searchInputs
  ])

  const paginations = (event, value) => {
    setPage(value);
  }

  const filterUsers = () => {
    setSearchInputs(inputs);
    setPage(1);
    //console.log(e);
  }

  const deleteUserAction = () => {
    dispatch(deleteUserInBase(deleteUser.id));
    setShowConfirm(false);
    setDeleteUser({});
  };

  const confirmDeleteUser = (user) => {
    setShowConfirm(true);
    setDeleteUser(user);
  };

  const changeInput = (e, key) => {
    if (e !== null && e.target) {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    } else {
      setInputs({
        ...inputs,
        [key]: e,
      });
    }
  };

  const resetValues = async () => {
    setInputs(defaultInputs);
  }

  return (
    <>
      <PageWithList
        title="Користувачі"
        tableTitle="Список користувачів"
        listItems={users.map((user, i) => {
          const {
            name = '',
            lastName = '',
            email = '',
            city = {}
          } = user;
          const {
            label: cityName = ''
          } = city;
          let checkSert = false;
          if (dashboardState && dashboardState[email]) {
            checkSert = true;
          }
          const num = i + 1;
          return (
            <React.Fragment key={`${i}_${name}_${lastName}`}>
              <TableItem>
                <TableItemTitleRow className={classes.tableRow}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item md={1} sm={12} xs={12} className={classes.cellNumber}>
                      {num}
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                      {name} {lastName}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      {email}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      {cityName}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      {checkSert && <Alert severity="success">Сертифікат отриманий</Alert>}
                    </Grid>
                  </Grid>
                </TableItemTitleRow>

                <TableItemButtons>
                  <Link to={{ pathname: `/admin/user/${user.id}/edit` }}>
                    <TableEditButton />
                  </Link>
                  <TableDeleteButton onClick={() => confirmDeleteUser(user)} />
                </TableItemButtons>
              </TableItem>
            </React.Fragment>
          )
        })}
        filter={
          <>
          <Grid container spacing={1} alignItems="center">
              <Grid item md={1} sm={12} xs={12}>
                <Typography>Фильтр :</Typography>
              </Grid>
              <Grid item md={2} sm={12} xs={12}>
                <CustomInput
                  labelText="Ім'я"
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'name',
                    value: inputs.name,
                    onChange: (e) => changeInput(e),
                  }}
                />
              </Grid>
              <Grid item md={2} sm={12} xs={12}>
                <CustomInput
                  labelText="Прізвище"
                  id="lastName"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'lastName',
                    value: inputs.lastName,
                    onChange: (e) => changeInput(e),
                  }}
                />
              </Grid>
              <Grid item md={2} sm={12} xs={12}>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'email',
                    name: 'email',
                    value: inputs.email,
                    onChange: (e) => changeInput(e),
                  }}
                />
              </Grid>
              <Grid item md={2} sm={12} xs={12}>
                <CustomInput
                  labelText="Місто"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'city',
                    value: inputs.city,
                    onChange: (e) => changeInput(e),
                  }}
                />
              </Grid>
              <Grid item md={1} sm={12} xs={12}>
                <Button
                  variant="contained"
                  className={classes.resetBtn}
                  onClick={ () => filterUsers() }
                >
                  Пошук
                </Button>
              </Grid>
              <Grid item md={1} sm={12} xs={12}>
                  <RotateLeftIcon className={classes.resetBtn} onClick={() => resetValues()}/>
              </Grid>
            </Grid>
            </>
        }
        pagination={
          <>
            {countPages && countPages > 1 && (
              <Grid container spacing={1}>
                <Pagination count={countPages} page={page} onChange={paginations} />
              </Grid>
            )}
          </>
        }
      />
      <ConfirmDelete
        title="Ви впевнені, що бажаєте видалити користувача?"
        description="Після видалення користувача відновити його буде не можливо"
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={deleteUserAction}
      /> 
    </>
  );
  
}
