import _ from 'lodash';

export const randomNumber = _.random;

export const { shuffle } = _;

export const generateQuestionsLecture = (questions, count) => {
  if (questions.length <= count) return shuffle(questions);

  return _.sampleSize(questions, count);
};
