import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';

export const AlertBox = styled(Alert)`
  position: absolute;
  left: 0;
  bottom: -13px;
  padding-left: 0 !important;

  &.MuiAlert-standardError {
    background-color: transparent;
  }

  .MuiAlert-message {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
  }
  .MuiAlert-icon {
    padding-top: 0;
    padding-bottom: 0;
    width: 17px;
    height: 17px; 

    .MuiSvgIcon-root {
      width: 17px;
      height: 17px; 
    }
  }
`;

export const InputBox = styled.div`
  position: relative;
`;

export const ResetPasswordContainer = styled.div`
  display: flex; 
  justify-content: flex-end; 
  
  margin-top: -10px;
`;

export const ResetPasswordLink = styled(Link)`
  font-size: 12px;
`;
