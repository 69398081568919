import React from 'react';
import {
  Typography, Button, makeStyles, Paper, List, ListItem, createStyles,
} from '@material-ui/core';
import clsx from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
  noTTU: {
    textTransform: 'none',
  },
  list: {
    paddingTop: theme.spacing(4),

    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column wrap',
      textAlign: 'center',

      '& > *:not(:first-child)': {
        paddingTop: 10,
      },
    },
  },
  buttons: {
    display: 'flex',
    '& button:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  button: {
    fontSize: '0.8rem',
    padding: '6px 12px',
    marginLeft: '10px',
  },
  tableRow: {
    width: '100%'
  }
}));

export const TableTitle = (props) => (
  <Typography
    {...props}
    align="center"
    variant="h5"
  />
);

export const TableList = (props) => {
  const cls = useStyles();

  return (
    <List
      {...props}
      className={clsx(cls.list, props.className)}
    />
  );
};

export const TableItem = (props) => {
  const cls = useStyles();

  return (
    <ListItem
      {...props}
      className={clsx(cls.listItem, props.className)}
      component={Paper}
    />
  );
};

export const TableItemTitle = (props) => {
  const cls = useStyles();

  return (
    <Typography
      {...props}
      className={clsx(cls.noTTU, props.className)}
      variant="subtitle1"
    />
  );
};

export const TableItemTitleRow = (props) => {
  const cls = useStyles();

  return (
    <Typography
      {...props}
      className={`${clsx(cls.noTTU, props.className)} ${cls.tableRow}`}
      variant="subtitle1"
    />
  );
};

export const TableItemButtons = (props) => {
  const cls = useStyles();

  return (
    <div
      {...props}
      className={clsx(cls.buttons, props.className)}
    />
  );
};

export const TableEditButton = (props) => {
  const cls = useStyles();

  return (
    <Button
      {...props}
      className={clsx(cls.button, props.className)}
      variant="contained"
    >
      Змінити
    </Button>
  );
};

export const TableDeleteButton = (props) => {
  const cls = useStyles();

  return (
    <Button
      {...props}
      className={clsx(cls.button, props.className)}
      variant="contained"
      color="secondary"
    >
      Видалити
    </Button>
  );
};
