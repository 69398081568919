import React from 'react';
import Box from '@material-ui/core/Box';

const TabPanel = ({
  children, value, index, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`wrapped-tabpanel-${index}`}
    aria-labelledby={`wrapped-tab-${index}`}
    {...other}
  >
    {value === index && (
    <Box p={2}>
      {children}
    </Box>
    )}
  </div>
);

export default TabPanel;
