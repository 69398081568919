import React, { useEffect } from 'react';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { setAllQuestionnaires } from 'admin/store/actions/questionnairesAction';
import { ConfigItem } from '../style';

const RegQuestionnaire = ({ config, changeHandler }) => {
  const dispatch = useDispatch();
  const { questionnaires } = useSelector((state) => state.admin);

  const { regQuestionnaire } = config;

  const options = questionnaires.map(({ id, title }) => ({ value: id, label: title }));
  const onSelectChange = ({ value }) => changeHandler('regQuestionnaire', value);
  const value = options.find(({ value }) => value === regQuestionnaire);

  useEffect(() => {
    dispatch(setAllQuestionnaires());

    // dispatch
    // eslint-disable-next-line
  }, []);

  return (
    <ConfigItem title="Регістраційна анкета">
      <ReactSelect
        options={options}
        value={value}
        onChange={onSelectChange}
      />
    </ConfigItem>
  );
};

export default RegQuestionnaire;
