import React, { useState } from 'react';
import axios from 'axios';
import {
  Dialog, DialogTitle, DialogContent, makeStyles, createStyles,
} from '@material-ui/core';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Person from '@material-ui/icons/Person';

import CustomInput from '../ui/CustomInput/CustomInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from 'cabinet/components/ui/CustomButtons/Button';
import CardFooter from 'cabinet/components/ui/Card/CardFooter';
import CardBody from 'cabinet/components/ui/Card/CardBody';

import {
  validateEmailField,
  validateFieldIsRequired,
  validatePhone
} from '../../../utils/validate';

import {
  InputBox, AlertBox
} from './styles.js'

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: '100%',
    maxWidth: 800,
  },
}));

// state components
const defaultInputs = {
  name: {
    value: '',
    error: null,
    validate: true,
  },
  email: {
    value: '',
    error: null,
    validate: true,
  },
  phone: {
    value: '',
    error: null,
    validate: true,
  },
  message: {
    value: '',
    error: null,
    validate: true,
  }
};
const copyDefault = { ...defaultInputs };

export default function HelpDialog({ dialogProps, title, children }) {
  const cls = useStyles();

  const [inputs, setInputs] = useState(copyDefault);
  const [isFinish, setIsFinish] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const validationField = () => {
    const newInputs = {};
    let countValidFiled = 0;
    Object.keys(inputs).forEach((item) => {
      let validValue = null;
      if (inputs[item].validate) {
        if (item === 'email') {
          validValue = validateEmailField(inputs[item].value);
        } else if (item === 'phone') {
          validValue = validatePhone(inputs[item].value);
        } else {
          validValue = validateFieldIsRequired(inputs[item].value);
        }

        if (validValue.length > 0) {
          countValidFiled += 1;
        }

        newInputs[item] = {
          value: inputs[item].value,
          error: validValue.length === 0 ? null : validValue,
          validate: true,
        };
      } else {
        newInputs[item] = {
          value: inputs[item].value,
          error: null,
        };
      }
    });
    setInputs(newInputs);
    return countValidFiled === 0;
  };

  const changeInput = (e, key) => {
    if (e !== null && e.target) {
      setInputs({
        ...inputs,
        [e.target.name]: {
          ...inputs[e.target.name],
          value: e.target.value,
        },
      });
    } else {
      setInputs({
        ...inputs,
        [key]: {
          ...inputs[key],
          value: e,
        },
      });
    }
  };

  const sendForm = async () => {
    if (validationField() && !isLoading) {
      const sendData = {};
      const inputData = { ...inputs };
      Object.keys(inputData).forEach((item) => {
        sendData[item] = inputData[item].value;
      });

      setLoading(true);
      setIsFinish(false);

      const message = `
        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:16px;font-family:roboto, 'helvetica neue', helvetica, arial, sans-serif;line-height:24px;color:#000000">
          <strong>Ім'я</strong> : ${sendData.first}
        </p>
        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:16px;font-family:roboto, 'helvetica neue', helvetica, arial, sans-serif;line-height:24px;color:#000000">
          <strong>Email</strong> : ${sendData.email}
        </p>
        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:16px;font-family:roboto, 'helvetica neue', helvetica, arial, sans-serif;line-height:24px;color:#000000">
          <strong>Номер телефону</strong> : ${sendData.phone}
        </p>
        <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:16px;font-family:roboto, 'helvetica neue', helvetica, arial, sans-serif;line-height:24px;color:#000000">
          <strong>Повідомлення</strong> : ${sendData.message}
        </p>
      `;
      const testUrl = 'http://localhost:5001/sappers-4605c/us-central1/sendEmail'; 
      const prodUrl = 'https://us-central1-sappers-4605c.cloudfunctions.net/sendEmail';
      const url = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? testUrl : prodUrl;

      const res1 = await axios.post(url, {
        email : 'sappers.association@gmail.com',
        name: sendData.first,
        message: message,
        type: 'messageForAdmin',
      });
      const res2 = await axios.post(url, {
        email : sendData.email,
        name: sendData.first,
        type: 'sendHelpMessageCallback',
      });
      setLoading(false);
      setInputs(copyDefault);
      setIsFinish(true);
    }
  };

  return (
    <Dialog
      {...dialogProps}
      classes={{
        paper: cls.paper,
      }}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        <form>
        <CardBody>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <CustomInput
                  labelText="Ім'я"
                  id="first"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Person className={cls.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'name',
                    value: inputs.name.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.name.error && (
                    <AlertBox severity="error">{inputs.name.error[0]}</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputBox>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'email',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={cls.inputIconsColor} />
                      </InputAdornment>
                    ),
                    name: 'email',
                    value: inputs.email.value,
                    onChange: (e) => changeInput(e),
                  }}
                />
                {
                  inputs.email.error && (
                    <AlertBox severity="error">{inputs.email.error[0]}</AlertBox>
                  )
                }
              </InputBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12} sm={12} xs={12}>
                <InputBox>
                  <CustomInput
                    labelText="Номер телефону"
                    type="tel"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'tel',
                      name: 'phone',
                      value: inputs.phone.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                  {
                    inputs.phone.error && (
                      <AlertBox severity="error">{inputs.phone.error[0]}</AlertBox>
                    )
                  }
                </InputBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={12} sm={12} xs={12}>
                <InputBox>
                  <CustomInput
                    labelText="Ваше повідомлення"
                    type="textarea"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: 'message',
                      value: inputs.message.value,
                      onChange: (e) => changeInput(e),
                    }}
                  />
                  {
                    inputs.message.error && (
                      <AlertBox severity="error">{inputs.message.error[0]}</AlertBox>
                    )
                  }
                </InputBox>
              </Grid>
            </Grid>
        </CardBody>
        <CardFooter className={cls.cardFooter}>
          <Button disabled={isLoading} color="primary" size="lg" onClick={sendForm}>
              Відправити
          </Button>
          {isFinish && <Alert severity="success">Ваше повідомлення надіслано успішно!</Alert>}
        </CardFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
