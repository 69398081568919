import UiCard from 'cabinet/components/Card';
import UiCardBody from 'cabinet/components/ui/Card/CardBody';
import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';

export const ProgressWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const ProgressBar = styled.div`
  position: relative;
  height: 13px;
  background-color: rgba(20, 153, 254, 0.2);

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${(props) => props.percent}%;
    background-color: #1499fe;
  }
`;

export const Progress = styled.div`
  padding: 0 30px;
  margin-bottom: -4px;
  font-size: 14px;
  color: #3d4b55;
`;

export const Card = styled(UiCard)`
  && {
    margin-top: 0;
    max-width: 346px;
    font-family: "Roboto", sans-serif;
    height: 100%;

    ${(props) => css`${props.theme.breakpoints.down('md')} {
      margin-left: auto;
      margin-right: auto;
    }`}

    ${(props) => props.theme.breakpoints.down('xs')} {
      margin-left: auto;
      margin-right: auto;

      max-width: 284px;
    }
  }
`;

export const CardBody = styled(UiCardBody)`
  && {
    padding: 30px;
    padding-bottom: 80px;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 30px 28px;
  }
`;

export const Title = styled.h3`
  margin: 0;
  padding-bottom: 31px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.02em;
  font-family: "Roboto Slab", "Roboto", sans-serif;
  color: #000000;

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-bottom: 18px;

    font-size: 15px;
    line-height: 20px;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding-bottom: 31px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #3d4b55;


  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-bottom: 18px;

    font-size: 12px;
    line-height: 14px;
  }
`;

export const SubmitButton = styled(Button)`
  && {
    padding: 16px 36px;
    border-radius: 3px;

    font-size: 12px;
    line-height: 17px;

    box-shadow: 0px 2px 2px rgba(156, 39, 176, 0.14);

    ${(props) => props.theme.breakpoints.down('xs')} {
      padding: 14px 33px;
    }
  }

  a {
    color: blue;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content : center;
  color: #1499fe;
  position: absolute;
  bottom: 30px;
  left: 0;
  margin: 0 auto;
  width: 100%;

  a {
    color: blue;
  }
`;

export const CompleteIcon = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #1499fe;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);
  border-radius: 50%;

  ${(props) => props.theme.breakpoints.down('xs')} {
    width: 24px;
    height: 24px;

    & svg {
      width: 11px;
      height: 11px;
    }
  }
`;
