export const C_LESSONS_SET_LESSONS = 'C_LESSONS_SET_LESSONS';

const initalState = null;

export const lessonsReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case C_LESSONS_SET_LESSONS:
      return [...payload];
    default:
      return state;
  }
};
