import styled from 'styled-components';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  inputBackground: {
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 200,
  },
  bottomAction: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  removeEvents: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  createdBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const AnswerLabel = styled.strong`
  display: block;
  font-size: 18px;
  margin-bottom: 25px;
`;
