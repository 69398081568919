import React, { useEffect, useState } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  Link,
} from 'react-router-dom';
import Button from 'cabinet/components/Button';
import { TitleSlice } from '../../../components/TitleSlice/TitleSlice';
import {
  Wrap,
  Container,
  ButtonWrap,
  ListDescriptionTheme,
  ListItem,
  ListContainer,
  PrevButton,
  TitleCard,
  ContentContainer,
} from './styles';
import RightContent from './RightContent';

const useQuery = () => new URLSearchParams(useLocation().search);

const LectureContent = ({ course, lesson, lecture }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { lectureSlug } = params;

  const [themesArray, setThemesArray] = useState([]);
  const [step, setStep] = useState(+query.get('step') || 0);

  const {
    // title = '',
    content = '',
    themes: lectureThemes = [],
  } = lecture;

  const themes = [
    {
      content,
      title: 'Вступ',
    },
    ...lectureThemes,
  ];

  const setFilterThemes = (stepValue) => {
    let stepCount = stepValue;
    if (stepValue > themes.length) {
      stepCount = themes.length - 1;
      setStep(stepCount);
    }
    const showTheme = themes.filter((item, index) => index === stepCount);
    setThemesArray(showTheme);
  };

  useEffect(() => {
    const stepValue = query.get('step');
    if (step === stepValue) return;

    setFilterThemes(Number(stepValue));
    setStep(Number(stepValue));

    // query, setFilterThemes
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    setFilterThemes(step);

    // setFilterThemes
    // eslint-disable-next-line
  }, [lecture]);

  const redirectToStep = (valueStep) => {
    history.push(`${location.pathname}?step=${valueStep}`);
  };

  const nextStep = () => {
    const newStep = step + 1;
    redirectToStep(newStep);
  };

  const prevStep = () => {
    const newStep = step - 1;
    redirectToStep(newStep);
  };

  const lectureCount = lesson.lectures.indexOf(lecture.id) + 1;

  const titleCard = (
    <TitleCard>
      <TitleSlice
        withoutMargin
        left={`Лекція ${lectureCount}`}
        right={lecture.title}
      />
    </TitleCard>
  );

  if (!lecture.themes.length) {
    return (
      <>
        {titleCard}

        <div style={{
          marginTop: 22, textAlign: 'center', fontSize: 22, color: '#000000',
        }}
        >
          Не додано жодної теми!
        </div>
      </>
    );
  }

  return (
    <>
      {titleCard}

      <ContentContainer>
        <ListDescriptionTheme>
          <ListContainer>
            {
            themes.map((item, index) => (
              <ListItem key={index} active={step === index}>
                <Link to={`${location.pathname}${index > 0 ? `?step=${index}` : ''}`}>
                  {
                    index !== 0 && step !== index && `${index}. `
                  }
                  { item.title }
                </Link>
              </ListItem>
            ))
          }
          </ListContainer>
        </ListDescriptionTheme>
        <Wrap>
          <Container>
            <RightContent themes={themesArray} listThemes={themes} activeStep={step} />

            <ButtonWrap>
              {
              step > 0 && (
                <PrevButton>
                  <Button onClick={prevStep}>Попередня тема</Button>
                </PrevButton>
              )
            }
              {
            themes.length === step + 1
              ? (
                <Link to={`${lectureSlug}/test`}>
                  <Button>Пройти тест</Button>
                </Link>
              )
              : (
                <Button onClick={nextStep}>
                  {
                  step === 0
                    ? 'Перейти до першої теми'
                    : 'Наступна тема'
                }
                </Button>
              )
          }
            </ButtonWrap>
          </Container>
        </Wrap>
      </ContentContainer>
    </>
  );
};

export default LectureContent;
