import moment from 'moment';
import { eventsCollection, mapResultToDocs } from '../fire';

export const getSearchDate = () => moment().format('DD.MM.YYYY');

export const generateLog = (data, action, userId) => {
  const searchDate = getSearchDate();
  const timestamp = moment().unix();

  return {
    ...data,
    __searchDate: searchDate,
    __timestamp: timestamp,
    __action: action,
    __userId: userId,
  };
};

export const whereUserId = (userId) => eventsCollection.where('__userId', '==', userId);

export const saveLog = async (log) => await eventsCollection.add(log);

export const getLogsByUserId = async (userId) => {
  const eventsResult = await whereUserId(userId)
    .orderBy('__timestamp', 'desc')
    .get();
  const docs = mapResultToDocs(eventsResult);

  return docs;
};
