/**
 * types not full
 * @param {{
    courseId: string,
    lessons: string[],
}} course

 * @param {{
    id: string,
    lectures: string[],
}} lesson

 * @param {{
   id: string,
   title: string,
   slug: string,
  }[]} lectures

 * @param {{
    courses: {
      [courseId: string]: {
          lessons: {
              [lessonId: string]: {
                  lectures: string[],
                  complete: boolean,
              },
          },
          complete: boolean,
      },
  },
}} userStory

 * @param {boolean} isSuperUser
 */
export const getLessonState = (course, lesson, lectures, userStory, isSuperUser) => {
  const { courseId } = course;
  const { lectures: lecturesIds, id: lessonId } = lesson;

  // all of these story can be undefined or null
  const courseStory = userStory.courses[courseId];
  const lessonsStory = courseStory.lessons ? courseStory.lessons : {};
  const lessonStory = !lessonsStory[lessonId] ? null : lessonsStory[lessonId];
  const lecturesStory = !lessonStory ? [] : lessonStory.lectures;

  /**
   * type not full
   * @type {{
      [lectureId: string]: {
        status: "done" | "inProgress" | 'disabled',
        id: string,
        title: string,
        slug: string,
      }
    }}
   */
  const state = {};

  // filter for optimization
  const filteredLectures = lectures.filter((lecture) => lecturesIds.includes(lecture.id));
  // set all filtered lectures to state
  filteredLectures.forEach((lecture) => state[lecture.id] = { ...lecture });

  // set correct status to all userStory lectures.
  lecturesStory.forEach((lectureId) => {
    // if invalidData
    if (!state[lectureId]) return;

    state[lectureId] = { ...state[lectureId], status: 'done' };
  });

  // mutate state to create rest lectures status
  lecturesIds.forEach((lectureId, i, array) => {
    const lecture = state[lectureId];

    if (lecture.status) return;

    // if first element -> open lecture.
    if (i - 1 < 0) {
      lecture.status = 'inProgress';
      return;
    }

    const prevLecture = state[array[i - 1]];

    // open next lecture
    if (prevLecture.status === 'done' || lessonStory?.complete || courseStory?.complete || isSuperUser) {
      lecture.status = 'inProgress';
    } else if (prevLecture.status === 'inProgress' || prevLecture.status === 'disabled') {
      lecture.status = 'disabled';
    }
  });

  return state;
};
