import { findActualTestBlockedLog } from 'utils/events/test_blocked';
import { C_CLEAR_TEST_BLOCKED, C_SET_TEST_BLOCKED } from '../reducers/logs';

export const setTestBlocked = (payload) => ({
  type: C_SET_TEST_BLOCKED,
  payload,
});

export const clearTestBlocked = () => ({
  type: C_CLEAR_TEST_BLOCKED,
});

export const checkTestBlockByUserId = (userId) => async (dispatch) => {
  try {
    const docs = await findActualTestBlockedLog(userId);

    if (docs.length) {
      dispatch(setTestBlocked(true));
    }
  } catch (e) {
    console.error(e);
  }
};
