import styled from 'styled-components';
import Card from '../../components/ui/Card/Card';

export const Title = styled.h1`
  font-size: 48px;
  line-height: 63px;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 41px 0 35px 0;
  
  @media screen and (max-width: 1200px) {
    font-size: 40px;
    line-height: 50px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
  
  @media screen and (max-width: 575px) {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 16px;
  }
`;

export const InfoWrapper = styled.div`
  max-width: 802px;
  margin: 0 auto 92px auto;
  width: 100%;
  
  @media screen and (max-width: 575px) {
    margin-bottom: 35px;
  }
`;

export const CardUI = styled(Card)`
  padding: 53px 108px;
  
  @media screen and (max-width: 768px) {
    padding: 40px 25px;
  }
  
  @media screen and (max-width: 575px) {
    padding: 0 14px;
    width: 100%;
    box-shadow: none !important;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  
   @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const ColLogo = styled.div`
  width: 199px;
  height: 199px;
  min-width: 199px;
  margin-right: 59px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.24));
  background-image: url(${({ url }) => url});
  background-size: cover;
  
  @media screen and (max-width: 575px) {
    margin: 0 auto 8px auto;
    width: 86px;
    min-width: 86px;
    height: 86px;
  }
`;

export const ColInputs = styled.div`
  flex-grow: 2;
`;

export const CourseWrapper = styled.div`
  padding-bottom: 199px;
  
  @media screen and (max-width: 768px) {
    padding-bottom: 152px;
  } 
`;

export const CourseTitle = styled.h2`
  font-size: 35px;
  line-height: 46px;
  color: #000000;
  margin-bottom: 34px;
  
  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 38px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 16px;
  }
`;

export const CourseRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px;
`;

export const CourseCol = styled.div`
  width: calc((100% / 3) - 40px);
  margin: 0 40px;
    
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SendWrapper = styled.div`
  margin-top: 39px;
  display: flex;
  justify-content: center;
`;
