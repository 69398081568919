export const NEWUSERS_SET_USERS = 'NEWUSERS_SET_USERS';
export const NEWUSERS_DELETE_USER = 'NEWUSERS_DELETE_USER';
export const SET_UPDATE_USERS = 'SET_UPDATE_USERS';

const initialState = {
  users: null,
};

export const newUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWUSERS_SET_USERS:
      return { ...state, users: action.payload };
    case NEWUSERS_DELETE_USER:
      return { ...state, users: state.users.filter((user) => !(user.id === action.payload)) };
    case SET_UPDATE_USERS: {
      const { id } = action.payload;
      const updateUsers = state.users.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
      return {
        ...state,
        users: updateUsers,
      };
    }
    default:
      return state;
  }
};
