import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Page from '../../components/Page';
import CustomTabs from '../../components/CustomTabs';
import Main from './Tabs/Main';
import Lecture from './Tabs/Lecture';
import ContentLoader from '../../components/ContentLoader';
import { useLessonRoot } from './useLesson';
import {
  setLessonById, saveLesson, createNewLesson, saveNewLesson, clearLesson,
} from '../../store/actions/lessonAction';
import { HeaderButton } from '../../components/Header';
import { lessonValidate } from '../../store/validations/lesson';
import { mapYupInnerToState } from '../../../utils/yupUtils';

const tabs = [
  'Основна інформація',
  'Лекціі',
];

export default function EditLesson(props) {
  const { id } = props.match.params;

  const history = useHistory();

  const dispatch = useDispatch();
  const {
    lesson, successCreate, errors: rootErrors,
  } = useLessonRoot();

  const [lessonData, setLessonData] = useState(null);
  const [initialSlug, setInitialSlug] = useState(null);
  const [errors, setErrors] = useState({});

  const isCreateMode = props.match.path === '/admin/lesson/create';

  useEffect(() => {
    if (isCreateMode) {
      dispatch(clearLesson());
      dispatch(createNewLesson());
      return;
    }

    dispatch(clearLesson());
    dispatch(setLessonById(id));

    return () => dispatch(clearLesson());

    // dispatch, id
    // eslint-disable-next-line
  }, [isCreateMode]);

  useEffect(() => {
    if (!lesson) return;

    setInitialSlug(lesson.slug);
    setLessonData(lesson);
  }, [lesson]);

  // rootErrors handler
  useEffect(() => {
    // rootErrors more important than errors
    setErrors(rootErrors);
  }, [rootErrors]);

  if (isCreateMode && successCreate) {
    history.push(`/admin/lesson/${lesson.id}/edit`);
  }

  if (!lessonData) return (<ContentLoader title={isCreateMode ? 'Створити модуль' : 'Редагування модуля'} />);

  const validate = async (lesson) => {
    const result = await lessonValidate(lesson);

    // insurance
    result.errors = mapYupInnerToState(result.errors);

    setErrors(result.errors);

    return result;
  };

  const saveHandler = async () => {
    const { errors } = await validate(lessonData);

    if (isCreateMode) {
      dispatch(saveNewLesson(lessonData, initialSlug, errors));
      return;
    }

    dispatch(saveLesson(lessonData, initialSlug, errors));
  };

  const changeHandler = (param, value) => {
    const copy = { ...lessonData };

    copy[param] = value;

    validate(copy);
    setLessonData(copy);
  };

  return (
    <Page
      headerButton={(
        <HeaderButton onClick={saveHandler}>
          {' '}
          {isCreateMode ? 'Створити' : 'Оновити'}
        </HeaderButton>
      )}
      title={isCreateMode ? 'Створити модуль' : 'Редагування модуля'}
    >
      <CustomTabs tabs={
          tabs.map((tab, i) => (
            <Tab key={i} label={tab} />
          ))
        }
      >
        {/* Основна інформація */}
        <Main errors={errors} lessonData={lessonData} changeHandler={changeHandler} />

        {/* Лекціі */}
        <Lecture errors={errors} lessonData={lessonData} changeHandler={changeHandler} />
      </CustomTabs>
    </Page>
  );
}
