import React from 'react';
import { TextField } from '@material-ui/core';

export const TextArea = (props) => (
  <TextField
    fullWidth
    multiline
    rows={6}
    rowsMax={6}
    variant="outlined"
    {...props}
  />
);
