import React, { useEffect } from 'react';
import {
  Route, useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

const UnregisteredRoute = (props) => {
  const { isLogin, loading } = useSelector((state) => state.client.user);
  const history = useHistory();

  const redirectAuth = () => {
    if (isLogin && !loading) {
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    redirectAuth();

    // redirectAuth
    // eslint-disable-next-line
  }, [isLogin, loading]);

  return <Route {...props} />;
};

export default UnregisteredRoute;
