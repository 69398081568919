import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  navLink: {
    paddingRight: '0',
    maxHeight: '500px',
    overflow: 'auto',
  },
  link: {
    color: '#000000 !important',
  },
}));

export const WrapperLink = styled(NavLink)`
  &.active {
    span {
      color: #009be5 !important;
    }
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 25px 0;
`;
