import React from 'react';
import UserTable from 'admin/components/UserTable';
import AccordionDialog from 'admin/components/AccordionDialog';

export default function User({ user, buttons }) {
  return (
    <AccordionDialog buttons={buttons} title={user.email}>
      <UserTable {...user} />
    </AccordionDialog>
  );
}
