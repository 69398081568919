import React, { createContext } from 'react';
import {
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Navigator from '../Navigation';
import Router from '../../router';
import {
  theme,
  drawerWidth,
  styles,
} from './styles';

export const HandleDrawerContext = createContext(null);

const Paperbase = ({ classes }) => {
  const history = useHistory();
  const { isSuperUser } = useSelector((state) => state.client.user);

  if (!isSuperUser) {
    history.push('/dashboard');
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div className={classes.root}>
          <CssBaseline />
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator
                PaperProps={{
                  style: {
                    width: drawerWidth,
                  },
                  className: classes.navigator,
                }}
              />
            </Hidden>
          </nav>
          <div className={classes.app}>
            <HandleDrawerContext.Provider value={handleDrawerToggle}>
              <Router />
            </HandleDrawerContext.Provider>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(Paperbase);
