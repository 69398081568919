import React, { useEffect } from 'react';
import Page from 'cabinet/components/Page';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import LectureContent from './LectureContent';
import ContentLoader from '../../components/ContentLoader';
import { setLectureBySlug } from '../../store/actions/lecturesActions';
import { setBySlug } from '../../store/actions/coursesActions';
import { setLessonBySlug } from '../../store/actions/lessonsAction';

const Lecture = () => {
  const dispatch = useDispatch();
  const {
    lectures, courses, lessons, user,
  } = useSelector((state) => state.client);
  const { courseSlug, lectureSlug, lessonSlug } = useParams();

  const lecture = lectures && lectures.find((lect) => lect.slug === lectureSlug);
  const course = courses && courses.find((cour) => cour.slug === courseSlug);
  const lesson = lessons && lessons.find((les) => les.slug === lessonSlug);

  const loading = !lecture ?? !course ?? !lesson ?? false;

  const isCourseUndefined = !!courses && !course;
  const isLessonUndefined = !!lessons && !lesson;
  const isLectureUndefined = !!lectures && !lecture;
  const isNotConfirm = user.userProfileData && user.userProfileData.isConfirmAdmin === false;

  const redirect = isNotConfirm || isCourseUndefined || isLessonUndefined || isLectureUndefined;

  useEffect(() => {
    dispatch(setLessonBySlug(lessonSlug));
    dispatch(setBySlug(courseSlug));
    dispatch(setLectureBySlug(lectureSlug));

  // dispatch, lessonSlug, courseSlug, lectureSlug
  // eslint-disable-next-line
  }, []);

  if (redirect) return (<Redirect to="/" />);
  if (loading) return (<ContentLoader />);

  return (
    <>
      <Header />
      <Page
        supTitle={course.title}
        subTitle={course.subTitle}
        secondTitle={lesson.title}
        paddingBottom="120px"
      >
        <LectureContent course={course} lecture={lecture} lesson={lesson} />
      </Page>
    </>
  );
};

export default Lecture;
