import React, { useEffect } from 'react';
import ContentLoader from 'admin/components/ContentLoader';
import ResultListQuestions from 'cabinet/views/Test/ResultListQuestions';
import Page from 'admin/components/Page';
import { setTestResultById } from 'admin/store/actions/testResultAction';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

export default function TestResult(props) {
  const { params } = useRouteMatch();
  const { id } = params;

  const dispatch = useDispatch();
  const testResult = useSelector((state) => state.admin.testResult);

  const title = `Результат тесту ${id}`;

  useEffect(() => {
    dispatch(setTestResultById(id));

    // dispatch
    // eslint-disable-next-line
  }, [id]);

  if (!testResult) {
    return <ContentLoader title={title} />;
  }

  const { questions, answersUser } = testResult;

  return (
    <Page title={title}>
      <ResultListQuestions userAnswer={answersUser} questions={questions} />
    </Page>
  );
}
