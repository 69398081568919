export const A_M_SET_MESSAGE = 'A_M_SET_MESSAGE';
export const A_M_REMOVE_MESSAGE = 'A_M_REMOVE_MESSAGE';

export const A_M_SET_MESSAGES = 'A_M_SET_MESSAGES';

const initialState = {};

export const messagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case A_M_SET_MESSAGE: {
      const messages = { ...state };
      messages[payload.id] = payload.data;

      return { ...messages };
    }

    case A_M_SET_MESSAGES:
      return { ...payload };

    case A_M_REMOVE_MESSAGE: {
      const filteredKeys = Object.keys(state).filter((id) => id !== payload);
      const messages = {};

      filteredKeys.forEach((key) => messages[key] = state[key]);

      return { ...messages };
    }
    default:
      return state;
  }
};
