import React, { useEffect, useState } from 'react';
import Button from 'cabinet/components/Button';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Questions,
  BottomActions,
  NextQuestions,
  Loader,
} from './styles';
import Question from '../Question';

const PassingTest = ({ questions, loader, onSendData }) => {
  // components state
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showElement, setShowElement] = useState({});
  const [showSendButton, setShowSendButton] = useState(false);
  const [answerUser, setAnswerUser] = useState([]);
  const [answer, setAnswer] = useState([null]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setShowElement(questions[activeQuestion]);

    // activeQuestion
    // eslint-disable-next-line
  }, [questions]);

  const showBlockError = (message) => {
    setError(message);

    setTimeout(() => {
      setError(null);
    }, 2000);
  };

  // Добавление ответа в state
  const addValueAnswerUser = (type, value, index) => {
    const addAnswer = [...answerUser];

    if (type === 'checkbox') {
      const checkboxValue = addAnswer[activeQuestion] || [];

      // create state
      const state = new Array(questions[activeQuestion].responses.length);
      // value can be NaN.
      checkboxValue.forEach((i) => state[i] = i);

      // when checkbox turn off he send NaN as value.
      if (isNaN(value)) {
        delete state[index];
      } else {
        state[index] = value;
      }

      addAnswer[activeQuestion] = state.filter((value) => value !== undefined);
    } else {
      addAnswer[activeQuestion] = [value];
    }

    setAnswerUser(addAnswer);
    return addAnswer;
  };

  const clearValueAnswerUser = () => {
    const answerCopy = [...answerUser];
    answerCopy[activeQuestion] = [undefined];

    setAnswerUser(answerCopy);
    return answerCopy;
  };

  // Проверка есть ли не отвеченые вопросы
  const isEmptyValue = (answerValue, index) => {
    let indexFind = -1;
    answerValue.forEach((answerItem, ind) => {
      if (index !== ind) {
        const findValue = answerItem.findIndex((item) => item === undefined);
        if (findValue > -1) {
          indexFind++;
        }
      }
    });

    // Если нет ни одного не заполненого значения показать кнопку "Завершить"
    if (indexFind === -1) {
      setShowSendButton(true);
    }

    return indexFind;
  };

  // Смена вопроса на следующий
  const newQuestion = (answerLocal) => {
    const questionsLength = questions.length;
    // Проверка при переходе на последний елемент
    if (activeQuestion + 1 === questionsLength - 1) {
      isEmptyValue(answerLocal, activeQuestion + 1);
    }

    // Если мы на последнем елементе и ещё есть не выбраные ответы
    if (activeQuestion === questionsLength - 1 || answerLocal.length === questionsLength) {
      let findUndefinedIndex = -1;
      // Присваиваем index елемента в коготого значение undefined
      answerLocal.forEach((answerItem, index) => {
        const findIndex = answerItem.findIndex((item) => item === undefined);
        if (findIndex > -1 && findUndefinedIndex === -1) {
          findUndefinedIndex = index;
        }
      });

      if (findUndefinedIndex === activeQuestion) {
        findUndefinedIndex += 1;
      } else if (findUndefinedIndex < activeQuestion && activeQuestion < questions.length - 1) {
        findUndefinedIndex = activeQuestion + 1;
      }

      if (findUndefinedIndex > -1) {
        setActiveQuestion(findUndefinedIndex);
        setShowElement(questions[findUndefinedIndex]);
        isEmptyValue(answerLocal, findUndefinedIndex);
      }
    } else {
      const newIndex = activeQuestion + 1;
      setActiveQuestion(newIndex);
      setShowElement(questions[newIndex]);
    }

    setAnswer([null]);
  };

  const skipQuestions = () => {
    const updatedAnswer = clearValueAnswerUser();
    newQuestion(updatedAnswer);
  };

  const hasUndefinedInAnswers = () => answerUser[activeQuestion].filter((item) => item !== undefined);
  const hasValueActiveQuestion = () => answerUser[activeQuestion] === undefined;

  const validateAnswer = () => {
    const hasValue = hasValueActiveQuestion();
    if (hasValue) {
      showBlockError('Оберіть одну з відповідей');
      return !hasValue;
    }

    const result = hasUndefinedInAnswers();
    if (result.length === 0) {
      showBlockError('Оберіть одну з відповідей');
    }

    return result.length >= 1;
  };

  const sendReply = () => {
    const validateValue = validateAnswer();
    if (validateValue) {
      newQuestion(answerUser);
    }
  };

  // Запись выбраного пользователем значение
  const changeAnswer = (type, value, index) => {
    if (index) {
      const answer = [];
      answer[index] = value;
      setAnswer(answer);
    } else {
      setAnswer(value);
    }
    addValueAnswerUser(type, parseInt(value), index);
  };

  // Финальная валидация вопроса и отправка даных
  const sendTotalData = () => {
    if (validateAnswer()) {
      onSendData(answerUser);
    }
  };

  return (
    <>
      <Questions>
        {
          loader
            ? (
              <Box mt={2} mb={2}>
                <Loader>
                  <CircularProgress />
                </Loader>
              </Box>
            )
            : (
              showElement && (
                <Question
                  type={showElement.type}
                  index={activeQuestion}
                  question={showElement.question}
                  responses={showElement.responses}
                  onChange={changeAnswer}
                  value={answer}
                />
              )
            )
        }
      </Questions>
      {
        error && (
          <Box mt={2} mb={1}>
            <Alert severity="error">{ error }</Alert>
          </Box>
        )
      }
      <BottomActions>
        {
          !showSendButton && (
            <Button
              $secondPadding
              onClick={skipQuestions}
            >
              Пропустити
            </Button>
          )
        }
        <NextQuestions>
          {
            showSendButton
              ? (
                <Button
                  $secondPadding
                  onClick={sendTotalData}
                >
                  Завершити
                </Button>
              )
              : (
                <Button
                  $secondPadding
                  onClick={sendReply}
                >
                  Відповісти
                </Button>
              )
          }
        </NextQuestions>
      </BottomActions>
    </>
  );
};

export default PassingTest;
