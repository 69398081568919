import firebase from '../../../utils/fire';
import { NEWUSERS_SET_USERS, NEWUSERS_DELETE_USER } from '../reducers/newUsers';

const db = firebase.firestore();

export const setUsers = (payload) => ({
  type: NEWUSERS_SET_USERS,
  payload,
});

export const deleteUser = (payload) => ({
  type: NEWUSERS_DELETE_USER,
  payload,
});

const newUsersRef = db.collection('newusers');
const usersRef = db.collection('users');

export const activeUserById = (id) => async (dispatch) => {
  try {
    await newUsersRef.doc(id).delete();

    dispatch(deleteUser(id));
  } catch (e) {
    console.error(e);
  }
};

export const getNewUsersFromDatabase = () => async (dispatch) => {
  try {
    const resultNewUsers = await newUsersRef.get();
    const newUsersId = [];

    resultNewUsers.forEach((user) => newUsersId.push(user.id));

    const resultUsers = await Promise.all(newUsersId.map((id) => usersRef.doc(id).get()));
    const users = [];

    resultUsers.forEach((user) => users.push({ id: user.id, ...user.data() }));

    dispatch(setUsers(users));
  } catch (e) {
    console.error(e);
  }
};
