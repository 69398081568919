export const SET_LOGIN_USER = 'SET_LOGIN_USER';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_MESSAGE_NOTIFICATION = 'SET_MESSAGE_NOTIFICATION';

const initialState = {
  isLogin: false,
  loading: true,
  user: {},
  updateMessage: {
    type: 'success',
    message: null,
  },
  userProfileData: null,
  error: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userProfileData: action.payload,
      };
    case SET_MESSAGE_NOTIFICATION:
      return {
        ...state,
        updateMessage: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
