import axios from 'axios';
import { setGlobalConfig } from 'store/actions/configAction';
import firebase from 'utils/fire';
import {
  SET_LOADING,
  SET_LOGIN_USER,
  SET_ERROR,
  SET_USER_DATA,
  SET_MESSAGE_NOTIFICATION,
} from '../reducers/user';
import { setUserStory, setUserStoryById } from './userStoryActions';

const db = firebase.firestore();
const timeShowErrorMessage = 4000;

export const setLoginUser = (payload) => ({
  type: SET_LOGIN_USER,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload,
});

export const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});

export const setMessage = (payload) => ({
  type: SET_MESSAGE_NOTIFICATION,
  payload,
});

const getNewUserStory = (user) => ({
  isFirstEnter: true,
  certifications: [],
  id: user.uid,
  courses: {
    dashboard: {
      complete: false,
    },
  },
});

export const loginUser = ({ email, password }) => async (dispatch) => {
  try {
    const isSuperUser = await findSuperUser(email, password);
    dispatch(setLoading(true));

    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    // set userstory
    await dispatch(setUserStoryById(result.user.uid));

    dispatch(
      setLoginUser({
        isLogin: true,
        user: result.user,
        isSuperUser,
      }),
    );
  } catch (error) {
    if (error.code === 'auth/user-not-found') {
      dispatch(setError('Користувач не зареєстрований в системі'));
    }
    console.log(error);
  } finally {
    setTimeout(() => {
      dispatch(setError(false));
    }, timeShowErrorMessage);
    dispatch(setLoading(false));
  }
};

export const registrationUser = (userData) => async (dispatch) => {
  try {
    const data = { ...userData };
    const { email, password } = data;

    // remove data which we dont need in db.
    delete data.password;

    const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const result = await db.collection('users').doc(user.uid).set({
      ...data,
      isConfirmAdmin: false,
    });
    // add userid to newusers
    await db.collection('newusers').doc(user.uid).set({});

    // add userStory
    const newUserStory = getNewUserStory(user);

    await db.collection('userstory').doc(user.uid).set(newUserStory);
    dispatch(setUserStory(newUserStory));

    // ???
    loginUser({ email, password });
    await axios.post('https://us-central1-sappers-4605c.cloudfunctions.net/sendEmail', {
      email,
      name: `${data.name} ${data.lastName}`,
      type: 'created',
    });
    return result;
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      dispatch(setError('Користувач зареєстрований в системі'));
    } else if (error.code === 'auth/weak-password') {
      dispatch(setError('Пароль повинен бути більше 6 символів'));
    }
    console.log(error);
  } finally {
    setTimeout(() => {
      dispatch(setError(false));
    }, timeShowErrorMessage);
  }
};

export const getInformationUser = (id) => async (dispatch, getState) => {
  try {
    let searchUserId = id;
    if (!id) {
      const state = getState();
      const { user } = state.client.user;
      searchUserId = user.uid;
    }
    const userRef = db.collection('users').doc(searchUserId);
    const result = await userRef.get();
    const dataUser = result.data();
    dispatch(setUserData(dataUser));
  } catch (error) {
    console.log(error);
  }
};

export const isLoginUser = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let time = 0;
    const minimumPreloadTime = 1500;
    const timer = setInterval(() => {
      time += 300;
    }, 300);
    firebase.auth().onAuthStateChanged((user) => {
      clearInterval(timer);
      const dataSet = user ? {
        isLogin: true,
        emailVerified: user.emailVerified,
        user,
      } : {
        isLogin: false,
        user: null,
      };

      const wait = minimumPreloadTime > time ? minimumPreloadTime - time : 0;
      setTimeout(async () => {
        try {
          // set userstory
          if (dataSet.user) {
            await dispatch(setUserStoryById(dataSet.user.uid));
            await dispatch(setGlobalConfig());
          }

          let isSuperUser = false;
          let emailValue = '';
          if (user) {
            emailValue = user.email;
          }
          const data = await isLoginSuperUser(emailValue);
          isSuperUser = data;

          dispatch(
            setLoginUser({
              ...dataSet,
              isSuperUser,
            }),
          );

          dispatch(setLoading(false));
        } catch (e) {
          console.error(e);
        }
      }, wait);
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateInformationUser = (data, id) => async (dispatch) => {
  try {
    const userRef = db.collection('users').doc(id);
    await userRef.update(data);
    dispatch(setMessage({ type: 'success', message: 'Дані оновлені успішно' }));
  } catch (error) {
    console.log(error);
    dispatch(setMessage({ type: 'error', message: 'Сталась помилка при оновленні' }));
  } finally {
    setTimeout(() => {
      dispatch(setMessage({ type: 'success', message: null }));
    }, timeShowErrorMessage);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    firebase.auth().signOut().then(() => {
      dispatch(
        setLoginUser({
          user: null,
          isLogin: false,
          isSuperUser: false,
        }),
      );
    });
  } catch (e) {
    console.log(e);
  }
};

async function isLoginSuperUser(email) {
  try {
    if (email === '') return false;

    const adminRef = db.collection('admin');
    const findUserAdmin = await adminRef.where('email', '==', email).get();
    let isSuperUser = false;
    findUserAdmin.forEach((user) => {
      if (user.data().isSuperUser) {
        isSuperUser = user.data().isSuperUser;
      }
    });

    return isSuperUser;
  } catch (e) {
    console.log(e);
  }
}

async function findSuperUser(email, password) {
  try {
    const adminRef = db.collection('admin');
    const findUserAdmin = await adminRef.where('email', '==', email).where('password', '==', password).get();
    let isSuperUser = false;
    findUserAdmin.forEach((user) => {
      if (user.data().superUser) {
        isSuperUser = user.data().superUser;
      }
    });

    return isSuperUser;
  } catch (e) {
    console.log(e);
  }
}

export const invalidDataSetUser = (data) => async (dispatch) => {
  try {
    const user = firebase.auth().currentUser;

    // set to users
    await db.collection('users').doc(user.uid).set({
      ...data,
      email: user.email,
      isConfirmAdmin: false,
    });

    // add userid to newusers
    await db.collection('newusers').doc(user.uid).set({});

    // add userStory
    const newUserStory = getNewUserStory(user);

    await db.collection('userstory').doc(user.uid).set(newUserStory);
    dispatch(setUserStory(newUserStory));
  } catch (e) {
    console.error(e);

    // need for catch method
    throw e;
  }
};
