import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Page from 'cabinet/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { checkTestBlockByUserId } from 'cabinet/store/actions/logsAction';
import { Alert } from '@material-ui/lab';
import PassingTest from './PassingTest';
import ResultTest from './ResultTest';
import Header from '../../components/Header';
import ResultListQuestions from './ResultListQuestions';
import {
  Title,
  Container,
} from './styles';
// for test
import {
  getQuestionFromTest,
  isPassedTestUser,
  sendResultTest,
} from '../../store/actions/testAction';
import { completeCourse, completeLecture, completeLesson } from '../../store/actions/userStoryActions';
import { useTest } from './useTest';
import { getCourseState, getLessonState } from '../../../utils/userStoryUtils';

export default function Test(props) {
  const { courseSlug, lectureSlug, lessonSlug } = useParams();
  const dispatch = useDispatch();
  const {
    test, userStory, user, logs,
  } = useSelector((state) => state.client);
  const { questions, loaderTest } = test;

  const isTestBlocked = user.isSuperUser
    ? false
    : logs.test_blocked;

  const { enqueueSnackbar } = useSnackbar();

  const {
    redirect, course, lesson, lessons, lectures, loading, lecture, type,
  } = useTest(courseSlug, lessonSlug, lectureSlug);

  const [showResultTest, setShowResultTest] = useState(false);
  const [counter, setCounter] = useState(0);
  const [isComposed, setIsComposed] = useState(false);
  const [usersAnswers, setUsersAnswers] = useState([]);
  const [idSlug, setIdSlug] = useState('');
  const [nameModule, setNameModule] = useState('');
  const [indexLecture, setIndexLecture] = useState(0);
  const [nextLectureLink, setNextLectureLink] = useState(null);

  const loadQuestions = () => {
    let id = '';
    if (lectureSlug) {
      id = lectureSlug;
    } else if (lessonSlug) {
      id = lessonSlug;
    } else {
      id = 'dashboard';
    }

    setIdSlug(id);
    dispatch(getQuestionFromTest(id, type));
    dispatch(isPassedTestUser(id));
  };

  useEffect(() => {
    loadQuestions();
    dispatch(checkTestBlockByUserId(user.user.uid));

    // loadQuestions (dispatch)
    // eslint-disable-next-line
  }, [courseSlug, lectureSlug, lessonSlug]); // for update test when redirect to lesson final test

  useEffect(() => {
    let name = '';
    let index = null;
    let nextSlugLecture = '';
    lessons && lessons.forEach((item) => {
      if (item.slug === lessonSlug) {
        name = item.title;
      }

      type === 'lecture' && item.lectures.forEach((id, i) => {
        const lecture = lectures && lectures.find((lecture) => lecture.id === id);

        if (lecture?.slug === lectureSlug) {
          const lecturesLength = item.lectures.length;
          const nextIndex = (i + 1) > item.lectures.length - 1 ? lecturesLength : i + 1;
          const nextLecture = lectures.find((lecture) => lecture.id === item.lectures[nextIndex]);

          nextSlugLecture = nextLecture ? nextLecture.slug : '';
          index = i;
        }
      });
    });

    const link = `/${courseSlug}/${lessonSlug}/${nextSlugLecture}`;

    setNextLectureLink(link);
    setIndexLecture(index);
    setNameModule(name);

    return () => {
      setShowResultTest(false);
      setIsComposed(false);
    };

    // lectureSlug, lessonSlug
    // eslint-disable-next-line
  }, [lessons, lectures, lectureSlug, lessonSlug]);

  if (redirect) {
    return (
      <Redirect to="/" />
    );
  }

  /* redirectToQuestionnaire */
  if (!loading && type === 'course') {
    const { courseId } = course;
    const courseStory = userStory.courses[courseId];

    if (courseStory.questionnaire === undefined) {
      return (
        <Redirect to={`/${courseId}/questionnaire`} />
      );
    }
  }

  // rules checks
  if (!loading) {
    const { isSuperUser } = user;

    if (!userStory || !Object.keys(userStory).length) return (<Redirect to="/" />);

    const storyCourse = userStory.courses[course.courseId];
    const isCourseComplete = storyCourse.complete;

    if (!isCourseComplete && !isSuperUser) {
      // fix the race bug
      const lessonsDiff = _.difference(course.lessons, lessons.map((lesson) => lesson.id));

      if (lessonsDiff.length === 0) {
        const courseState = getCourseState(course, lessons, userStory, isSuperUser);

        // course check layer
        if (type === 'course') {
          const isHaveUnDoneLessons = !Object.values(courseState).find((lesson) => lesson.status !== 'done');

          if (!isHaveUnDoneLessons) {
            return (
              <Redirect to="/" />
            );
          }
          // type can be '', keep it in mind.
        } else if (type === 'lecture' || type === 'lesson') {
          // lesson and lecture checks layer
          const isLessonComplete = storyCourse.lessons
            && storyCourse.lessons[lesson.id]
            && storyCourse.lessons[lesson.id].complete;

          if (!isLessonComplete) {
            const lessonFromState = courseState[lesson.id];

            if (lessonFromState.status === 'disabled') {
              return (
                <Redirect to="/" />
              );
            }

            if (type === 'lesson') {
              const isHaveUnDoneLectures = lessonFromState.steps[0] - lessonFromState.steps[1] < -1;

              if (isHaveUnDoneLectures) {
                return (
                  <Redirect to="/" />
                );
              }
            }

            // lecture check layer
            if (type === 'lecture') {
            // fix the race bug
              const lessonsDiff = _.difference(lesson.lectures, lectures.map((lecture) => lecture.id));

              if (lessonsDiff.length === 0) {
                const lessonsState = getLessonState(course, lesson, lectures, userStory, isSuperUser);
                const lectureFromState = lessonsState[lecture.id];

                if (lectureFromState.status === 'disabled') {
                  return (
                    <Redirect to="/" />
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  const isLastlectureInLesson = lesson && (lesson.lectures.length - 1) === indexLecture;

  const islectureInUserStory = !!(
    type === 'lecture'
      && !loading
      && userStory
      && userStory.courses[course.courseId]
      && userStory.courses[course.courseId].lessons
      && userStory.courses[course.courseId].lessons[lesson.id]
      && userStory.courses[course.courseId].lessons[lesson.id]
        .lectures.find((id) => id === lecture.id)

  );

  const sendData = (answers) => {
    let counter = 0;
    const dataBaseAnswers = [];
    questions.forEach((item) => {
      dataBaseAnswers.push(item.answers);
    });

    // console.log(dataBaseAnswers, answers);
    dataBaseAnswers.forEach((value, index) => {
      // sort for get one true form
      if (value.sort().toString() === answers[index].sort().toString()) {
        counter++;
      }
    });

    const interest = Math.floor((counter * 100) / questions.length);
    let status = isComposed;

    const sendResult = (status) => dispatch(sendResultTest({
      id: idSlug,
      answersUser: answers,
      questionsAnswers: dataBaseAnswers,
      status,
      course,
      lesson,
      lecture,
      type,
    }));

    if (interest >= 80) {
      status = true;
      setIsComposed(true);

      if (type === 'lecture') {
        dispatch(completeLecture(courseSlug, lessonSlug, lectureSlug))
          .then(() => sendResult(status))
          .then(() => {
            // if user opened lesson final test => notify him
            if (isLastlectureInLesson && !islectureInUserStory) {
              enqueueSnackbar('Ви відкрили контрольний тест', { variant: 'success' });
            }
          });
      } else if (type === 'lesson') {
        dispatch(completeLesson(courseSlug, lessonSlug)).then(() => sendResult(status));
      } else if (type === 'course') {
        dispatch(completeCourse(courseSlug)).then(() => sendResult(status));
      }
    } else {
      sendResult(status);
    }

    setUsersAnswers(answers);
    setCounter(counter);
    setShowResultTest(true);
  };

  const reloadTest = () => {
    setShowResultTest(false);
    setIsComposed(false);
    loadQuestions();
  };

  const title = type === 'course' ? 'Іспит' : nameModule;

  const isLoadedLectureTitle = indexLecture !== null;

  const testLoading = loaderTest
    ? true
    : type === 'lecture'
      ? !isLoadedLectureTitle
      : false;

  return (
    <>
      <Header />
      <Page
        supTitle={title}
        paddingBottom="150px"
      >
        <Alert style={{ marginBottom: 10 }} severity="warning">
          Зверніть увагу! Якщо ви за 12 годин "завалили" 5 тестів - ви отримаєте блокування проходження курсу на 12 годин.
        </Alert>
        <Container>
          {
            type === 'lesson' && (
              <Title>Контрольний тест</Title>
            )
          }
          {
            type === 'lecture' && (
              <Title>
                {
                  isLoadedLectureTitle && `Тест лекції ${indexLecture + 1}`
                }

              </Title>
            )
          }

          {
            isTestBlocked
              ? showResultTest
                ? (
                  <>
                    <Alert severity="error">
                      Ви використали усі спроби на сьогодні. Будь ласка, перечитайте лекції і спробуйте ще раз через 12 годин.
                    </Alert>
                    <ResultListQuestions questions={questions} userAnswer={usersAnswers} />
                  </>
                )
                : (
                  <Alert severity="error">
                    Ви використали усі спроби на сьогодні. Будь ласка, перечитайте лекції і спробуйте ще раз через 12 годин.
                  </Alert>
                )
              : showResultTest
                ? (
                  <>
                    <ResultTest
                      counter={counter}
                      totalQuestion={questions.length}
                      isComposed={isComposed}
                      reloadTest={reloadTest}
                      type={type}
                      nextLink={nextLectureLink}

                      courseSlug={courseSlug}
                      lessonSlug={lessonSlug}
                      islectureInUserStory={islectureInUserStory}
                      isLastlectureInLesson={isLastlectureInLesson}
                    />
                    <ResultListQuestions questions={questions} userAnswer={usersAnswers} />
                  </>
                )
                : (
                  <PassingTest
                    isTestBlocked={isTestBlocked}
                    questions={questions}
                    loader={testLoading}
                    onSendData={sendData}
                  />
                )

          }
        </Container>
      </Page>
    </>
  );
}
