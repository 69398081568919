import React from 'react';
import Header from 'cabinet/components/Header';
import Page from 'cabinet/components/Page';
import Button from 'cabinet/components/ui/CustomButtons/Button';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import { useForm, Controller } from 'react-hook-form';
import { RATING_TYPE, TEXT_TYPE } from 'utils/questionnaire';
import { Box } from '@material-ui/core';
import { log } from 'utils/logger';
import { Card, Input, useStyles } from './style';
import { Title } from '../Test/Question/styles';

const Questionnaire = ({
  onSubmit = log,
  onCancel = () => {},
  questionnaire,
}) => {
  const cls = useStyles();

  const { register, handleSubmit, control } = useForm({
    mode: 'all',
  });

  const { title, questions, description } = questionnaire;

  return (
    <>
      <Header />
      <Page
        supTitle={title}
        subTitle={description}
        subWithoutLogos
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {questions.map(({ question, answer }, i) => (
            <Card
              key={i}
            >
              <Title>
                {`${i + 1}.`}
                {' '}
                {question}
              </Title>

              <Box>
                {
                    answer === TEXT_TYPE && (
                      <Input
                        inputProps={{
                          name: `${i}`,
                          inputRef: register({ required: true }),
                          multiline: true,
                        }}
                        formControlProps={{
                          fullWidth: true,
                          className: cls.inputControl,
                        }}
                      />
                    )
                  }
                {
                    answer === RATING_TYPE && (
                      <Controller
                        name={`${i}`}
                        control={control}

                        rules={{ required: true }}

                        defaultValue={5}

                        render={(props) => (
                          <Rating
                            {...props}

                            value={Number(props.value)}

                            name="test"
                            size="large"

                            classes={{
                              label: cls.starLabel,
                            }}

                            icon={<StarIcon />}
                          />
                        )}
                      />

                    )
                  }
              </Box>
            </Card>
          ))}

          <Box
            display="flex"
            justifyContent="center"
            className={cls.buttonsBox}
          >
            <Button
              color="primary"
              size="lg"
              type="submit"
            >
              Відправити
            </Button>

            <Box marginLeft={2} />

            <Button
              color="rose"
              size="lg"
              onClick={onCancel}
            >
              Відмовитись
            </Button>
          </Box>
        </form>
      </Page>
    </>
  );
};

export default Questionnaire;
