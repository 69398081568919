import React, { useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'cabinet/components/ui/Grid/GridContainer';
import GridItem from 'cabinet/components/ui/Grid/GridItem';
import Card from 'cabinet/components/ui/Card/Card';
// style
import styles from 'assets/jss/material-kit-react/views/loginPage';
// background
import image from 'assets/img/bg7.jpg';

const useStyles = makeStyles(styles);

const Auth = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCardAnimation('');
    }, 700);

    return () => clearTimeout(timeout);
  }, []);
  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>{props.children}</Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default Auth;
