import React from 'react';
import { Helmet } from 'react-helmet';
import DashboardCard from './DashboardCard';
import GridItem from '../../components/ui/Grid/GridItem';
import Page from '../../components/Page';
import Header from '../../components/Header';
import ContentLoader from '../../components/ContentLoader';

import {
  CardsTitle,
  CardsWrap,
  Alert,
  CardsContainer,
} from './styles';
import { useDashboard } from './useDashboard';
import { getCourseState } from '../../../utils/userStoryUtils';

export default function Dashboard() {
  const {
    course, lessons, userStory, verifyUser, loading, user,
  } = useDashboard();

  const mapCards = (card, i) => (
    <GridItem key={i} md={6} lg={4} sm={12}>
      <DashboardCard {...card} status={!verifyUser ? 'disabled' : card.status} />
    </GridItem>
  );

  if (loading) return (<ContentLoader />);

  const { courseId, lessons: courseLessonsIds } = course;
  const { isSuperUser } = user;
  const storyCourse = userStory.courses[courseId];

  const state = getCourseState(course, lessons, userStory, isSuperUser);

  const {
    title, subTitle, structureDescription, glosariyDescription, slug,
  } = course;

  const finalTestStatus = storyCourse.complete
    ? 'done'
    : isSuperUser
      ? 'other'
      : Object.values(state).find((lesson) => !(lesson.status === 'done'))
        ? 'disabled'
        : 'other';

  const main = [
    {
      slug,
      title: 'Структура курсу',
      text: structureDescription,
      to: `/${slug}/structure`,
      status: 'other',
    },
    ...courseLessonsIds.map((id) => {
      const {
        title: titleLesson, description, slug: lessonSlug, status, steps,
      } = state[id];

      return {
        slug: lessonSlug,
        title: titleLesson,
        status,
        steps,
        text: description,
        to: `/${slug}/${lessonSlug}`,
      };
    }),
    {
      slug,
      title: 'Іспит',
      text: 'Доступний після завершення всіх модулів.',
      to: `/${slug}/test`,
      status: finalTestStatus,
    },
  ];

  const additional = [
    {
      slug,
      status: 'other',
      title: 'Глосарій',
      text: glosariyDescription,
      to: `/${slug}/glosariy`,
    },
    {
      slug,
      status: 'other',
      title: 'Бібліотека',
      text: 'Додаткові матеріали для навчання',
      to: `/${slug}/library`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Кабінет | Навчальна платформа</title>
      </Helmet>
      <Header />
      <Page
        paddingBottom="150px"
        header={(
          <>
            {
              !verifyUser && (<Alert severity="error">Ваш аккаунт не підтверджений</Alert>)
            }

            <Alert severity="info">
              Платформа працює у тестовому режимі! У разі виявлення помилки, прохання відправити лист з описом помилки на
              {' '}
              <a href="malito:sappers.association@gmail.com">sappers.association@gmail.com</a>
              .
              {' '}
              Дякуємо за співпрацю!
            </Alert>
          </>
        )}
        supTitle={title}
        subTitle={subTitle}
      >
        <CardsWrap>
          <CardsContainer>
            {main.map(mapCards)}
          </CardsContainer>
        </CardsWrap>

        <CardsWrap paddingTop paddingBottom>
          <CardsTitle>Матеріали</CardsTitle>
          <CardsContainer>
            {additional.map(mapCards)}
          </CardsContainer>
        </CardsWrap>
      </Page>
    </>
  );
}
