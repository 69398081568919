import styled, { css } from 'styled-components';

export { Container } from '../../components/Page/styles';

export const Wrapper = styled.div`
  background-color: white;

  ${(props) => props.$paddingBottom && css`
    padding-bottom: 150px;
  `}
`;

export const FrameBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;

  margin-top: 13px;
  padding: 42px 0;
  
  background-image: url(${({ url }) => url});
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  
  @media screen and (max-width: 768px) {
    background-position: center center;

    padding: 20px;
  }
`;

export const CardLecture = styled.div`
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 40px 36px 21px;
  
  @media screen and (max-width: 768px) {
    padding: 16px 14px 24px 14px;
  }
`;

export const TitleBlock = styled.div`
  padding-left: 41px;
  display: flex;
  align-items: center;
  margin-bottom: 41px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    padding-left: 35px;
  }
`;

export const TitleValue = styled.h2`
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  color: #000000;
  margin: 0 14px 0 0;
  
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 20px;
    margin-right: 10px;
  }
`;

export const TitleDescription = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: #3D4B55;
  
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const LectureList = styled.div``;

export const CheckBlock = styled.div`
  margin-right: 17px;
  width: 24px;
  min-width: 24px;
  
  @media screen and (max-width: 768px) {
    width: 18px;
    min-width: 18px;
    margin-right: 16px;
  }
`;

export const CheckIconBlock = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #1499FE;
  display: none;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 12px;
    height: 8px;
  }
  
  @media screen and (max-width: 768px) {
    width: 18px;
    height: 18px;
    
    svg {
      width: 8px;
      height: 6px;
    }
  }
`;

export const ContentBlock = styled.div`
  display: flex;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LectureName = styled.span`
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  white-space: nowrap;
  display: inline-block;
  margin-right: 53px;
  
  @media screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 17px;
    margin-right: 0;
    margin-bottom: 4px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  
  @media screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 17px;
  }
`;

export const LectureItem = styled.div`
  margin-bottom: 32px;
  
  &:last-child {
    margin-bottom: 35px;
  }
  
  a {
    display: flex;
  }
  
  ${({ status }) => (
    status === 'disabled'
      ? css`
          pointer-events: none;
          
          ${LectureName},
          ${Description} {
            color: #3D4B55;
          }
          
          ${CheckIconBlock} {
            display: none;
          }
        `
      : null
  )}
  
  ${({ status }) => (
    status === 'done'
      ? css`
            ${CheckIconBlock} {
              display: flex;
            }
          `
      : null
  )};
  
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 15px;
    }
  }
`;

export const ControllTest = styled.div`
  a {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
  
  ${({ status }) => (
    status === 'disabled'
      ? css`
          pointer-events: none;
          a {
            color: #3D4B55;
          }
        `
      : null
  )}
  
  @media screen and (max-width: 768px) {
    padding-left: 35px;
    
    a {
      font-size: 13px;
      line-height: 17px;
    }
  }
`;
