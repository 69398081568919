import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab } from '@material-ui/core';
import Page from '../../components/Page';
import Main from './Tabs/Main';
import Structure from './Tabs/Structure';
import Glosariy from './Tabs/Glosariy';
import CustomTabs from '../../components/CustomTabs';
import Lessons from './Tabs/Lessons';
import Library from './Tabs/Library';
import ContentLoader from '../../components/ContentLoader';
import { useCourseRoot } from './useCourse';
import { HeaderButton } from '../../components/Header';
import {
  setCourseById, saveCourse,
} from '../../store/actions/courseAction';
import { courseValidate } from '../../store/validations/course';
import { mapYupInnerToState } from '../../../utils/yupUtils';
import Questionnaire from './Tabs/Questionnaire';

const tabs = [
  'Основна інформація',
  'Структура курсу',
  'Модулі',
  'Бібліотека',
  'Глосарій',
  'Анкета',
];

export default function EditCourse(props) {
  const dispatch = useDispatch();
  const { course, errors: rootErrors } = useCourseRoot();

  const [courseData, setCourseData] = useState(null);
  const [initialSlug, setInitialSlug] = useState(null);
  const [errors, setErrors] = useState({});

  const { id } = props.match.params;
  const loading = !courseData;

  useEffect(() => {
    dispatch(setCourseById(id));

    // dispatch
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!course) return;

    setInitialSlug(course.slug);
    setCourseData(course);
  }, [course]);

  // rootErrors handler
  useEffect(() => {
    // rootErrors more important than errors
    setErrors(rootErrors);
  }, [rootErrors]);

  if (loading) return (<ContentLoader title="Редагувати курс" />);

  const validate = async (course) => {
    const result = await courseValidate(course);

    // insurance
    result.errors = mapYupInnerToState(result.errors);
    setErrors(result.errors);

    return result;
  };

  const changeHandler = (param, value) => {
    const copy = { ...courseData };
    copy[param] = value;

    setCourseData(copy);
    validate(copy);
  };

  const saveHandler = async () => {
    // insurance
    const { errors } = await validate(courseData);

    dispatch(saveCourse(courseData, initialSlug, errors));
  };

  return (
    <Page
      title="Редагувати курс"
      headerButton={<HeaderButton onClick={saveHandler}>Зберегти</HeaderButton>}
    >
      <CustomTabs
        tabs={tabs.map((tab, i) => (<Tab label={tab} key={i} />))}
      >
        {/* Основна інформація */}
        <Main errors={errors} courseData={courseData} changeHandler={changeHandler} />

        {/* Структура курсу */}
        <Structure errors={errors} courseData={courseData} changeHandler={changeHandler} />

        {/* Модулі */}
        <Lessons errors={errors} courseData={courseData} changeHandler={changeHandler} />

        {/* Бібліотека */}
        <Library errors={errors} courseData={courseData} changeHandler={changeHandler} />

        {/* Глосарій */}
        <Glosariy errors={errors} courseData={courseData} changeHandler={changeHandler} />

        {/* Анкета */}
        <Questionnaire errors={errors} courseData={courseData} changeHandler={changeHandler} />
      </CustomTabs>
    </Page>
  );
}
