import styled, { css } from 'styled-components';
import UiAlert from '@material-ui/lab/Alert';
import Grid from '../../components/ui/Grid/GridContainer';

export const CardsWrap = styled.div`
  ${(props) => props.paddingTop && css`
    padding-top: 92px;

    ${props.theme.breakpoints.down('xs')} {
      padding-top: 27px;
    }
  `}

  margin-bottom: -63px;

  ${(props) => css`${props.theme.breakpoints.down('xs')} {
    margin-bottom: -15px;
  }`}

  ${(props) => props.paddingBottom && css`padding-bottom: 92px;`}
`;

export const CardsContainer = styled(Grid)`
& > * {
  padding-bottom: 63px;
}

${(props) => css`${props.theme.breakpoints.down('xs')} {
    & > * {
      padding-bottom: 15px;
    }
  }`}
`;

export const Alert = styled(UiAlert)`
  margin-top: 20px;
`;

export const CardsTitle = styled.div`
  font-family: "Roboto Slab", "Roboto", sans-serif;
  font-size: 23px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding-bottom: 46px;
  color: #000000;
  
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 13px;
    line-height: 17px;

    padding-bottom: 17px;
  }
`;
