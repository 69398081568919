import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => createStyles({
  card: {
    maxWidth: 400,
    marginRight: 10,
    marginBottom: 10,
  },
  cardMiddle: {
    padding: theme.spacing(3),
    fontSize: 40,

    [theme.breakpoints.down('xs')]: {
      fontSize: 30,

      '& svg': {
        fontSize: 40,
      },
    },

    '& svg': {
      fontSize: 60,
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));
