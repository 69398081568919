import Page from 'admin/components/Page';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderButton } from 'admin/components/Header';
import { saveGlobalConfig } from 'store/actions/configAction';
import RegQuestionnaire from './RegQuestionnaire';

const Config = () => {
  const dispatch = useDispatch();
  const { config } = useSelector((state) => state);

  const [configData, setConfigData] = useState(config);

  useEffect(() => {
    setConfigData(config);
  }, [config]);

  const changeHandler = (key, value) => {
    const copy = { ...configData };
    copy[key] = value;

    setConfigData(copy);
  };

  const saveHandler = () => {
    dispatch(saveGlobalConfig(configData));
  };

  return (
    <Page
      title="Налаштування"
      headerButton={(
        <HeaderButton onClick={saveHandler}>
          Зберегти
        </HeaderButton>
      )}
    >
      <RegQuestionnaire config={config} changeHandler={changeHandler} />
    </Page>
  );
};

export default Config;
