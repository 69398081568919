import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import firebase from '../../../utils/fire';
import {
  COURSE_SET_COURSE,
  COURSE_CLEAR_COURSE,
  COURSE_SET_ERRORS,
} from '../reducers/course';
import { courseValidate } from '../validations/course';
import { mapYupInnerToState } from '../../../utils/yupUtils';
import { setMessage } from './messagesAction';

const db = firebase.firestore();
const coursesRef = db.collection('courses');

export const setCourse = (payload) => ({
  type: COURSE_SET_COURSE,
  payload,
});

export const clearCourse = () => ({
  type: COURSE_CLEAR_COURSE,
});

export const setErrors = (payload) => ({
  type: COURSE_SET_ERRORS,
  payload,
});

export const FIREBASE = 'firebase';
export const LINK = 'link';
export const UPLOAD = 'UPLOAD';

const storageRef = firebase.storage().ref();
const lessonsRef = db.collection('lessons');

export const saveCourse = (course, initialSlug, errors) => async (dispatch, getState) => {
  try {
    const stateCourse = getState().admin.course.course;
    const { courseId, lessons, library } = course;

    // if errors;
    if (Object.values(errors).length) {
      dispatch(setMessage({
        text: 'Не збережено! У вас помилки!',
        variant: 'error',
      }));
      return;
    }

    if (initialSlug !== course.slug) {
      const result = await courseValidate(course, true);

      if (!result.success) {
        dispatch(setMessage({
          text: 'Не збережено! У вас помилки!',
          variant: 'error',
        }));

        dispatch(setErrors(mapYupInnerToState(result.errors)));

        return;
      }
    }

    const newLibrary = await Promise.all(library.map((card) => new Promise(async (res) => {
      if (card.type !== UPLOAD) {
        return res(card);
      }

      const copyCard = { ...card };
      const { file } = copyCard.input;

      const extMatch = file.name.match(/.+\.(.+)$/);
      const ext = extMatch[1];

      const path = `${courseId}/${uuid()}.${ext}`;

      await storageRef.child(path).put(file);

      delete copyCard.input.file;
      copyCard.input.path = path;
      copyCard.type = FIREBASE;

      return res(copyCard);
    })));

    const saveCourse = {
      ...course,
      lessons,
      library: newLibrary,
    };

    const libraryDif = _.difference(stateCourse.library, newLibrary);

    // if need delete some trash files
    if (libraryDif.length) {
      await Promise.all(libraryDif.map((card) => {
        if (card.type !== FIREBASE) return Promise.resolve();

        return storageRef.child(card.input.path).delete();
      }));
    }

    const binded = await lessonsRef.where('courseId', '==', courseId).get();

    // clear binding to course;
    await Promise.all(binded.docs.map((doc) => doc.data())
      .map((lesson) => lessonsRef.doc(lesson.id).update({ courseId: null })));

    // create binding to course;
    await Promise.all(lessons.map((id) => lessonsRef.doc(id).update({ courseId })));

    // save course;
    await coursesRef.doc(courseId).set(saveCourse);

    await dispatch(setCourse(saveCourse));

    dispatch(setMessage({
      text: 'Збережено!',
      variant: 'success',
    }));
  } catch (e) {
    dispatch(setMessage({
      text: 'Сталась невідома помилка при зберіганні',
      variant: 'error',
    }));

    console.error(e);
  }
};

export const setCourseById = (id) => async (dispatch) => {
  try {
    const resultCourse = await coursesRef.doc(id).get();

    const course = resultCourse.data();

    dispatch(setCourse({ library: [], ...course }));
  } catch (e) {
    dispatch(setMessage({
      text: 'Сталась невідома помилка при завантаженні курсу!',
      variant: 'error',
    }));

    console.error(e);
  }
};
