import { getTestResultById } from 'utils/result/utils';
import { setMessage } from './messagesAction';

const { TEST_RESULT_SET } = require('../reducers/testResult');

const setTestResult = (payload) => ({
  type: TEST_RESULT_SET,
  payload,
});

export const setTestResultById = (id) => async (dispatch) => {
  try {
    const testResultJson = await getTestResultById(id);
    const testResult = {
      ...testResultJson,
      questions: JSON.parse(testResultJson.questions),
      answersUser: JSON.parse(testResultJson.answersUser),
      questionsAnswers: JSON.parse(testResultJson.questionsAnswers),
    };

    dispatch(setTestResult(testResult));
  } catch (e) {
    dispatch(setMessage({ text: 'Помилка під час загрузки результата теста!', variant: 'error' }));
    console.error(e);
  }
};
