import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import User from './User';
import UsersDialog from '../UsersDialog';
import { deactivateUser, updateUserInBase } from '../../store/actions/usersAction';
import { useAccordionStyles } from '../AccordionDialog/AccordionDialog';

export default function NewUsersDialog({ users, dialogProps }) {
  const cls = useAccordionStyles();

  const dispatch = useDispatch();

  const deleteClickHandler = (id) => (e) => {
    // need for stop accordion animation!
    e.stopPropagation();

    dispatch(updateUserInBase({ isConfirmAdmin: true }, id));
  };

  const deactivateClickHanlder = (id, isConfirmAdmin) => (e) => {
    // need for stop accordion animation!
    e.stopPropagation();

    dispatch(deactivateUser(id, isConfirmAdmin));
  };

  return (
    <UsersDialog
      title="Нові користувачі"
      dialogProps={dialogProps}
    >
      {
          users.length
            ? users.map((user) => (
              <User
                user={user}
                key={user.id}
                buttons={(
                  <>
                    <Button
                      className={cls.button}
                      onClick={deactivateClickHanlder(user.id, user.isConfirmAdmin)}
                      variant="outlined"
                    >
                      Деактивувати
                    </Button>

                    <Button
                      className={cls.button}
                      onClick={deleteClickHandler(user.id)}
                      variant="outlined"
                      disabled={user.isConfirmAdmin}
                    >
                      {
                          user.isConfirmAdmin
                            ? 'Активовано'
                            : 'Активувати'
                      }
                    </Button>
                  </>
                )}
              />
            ))
            : 'Нових користувачів не знайдено!'
        }
    </UsersDialog>
  );
}
