import React, { useEffect } from 'react';
import {
  Redirect, Route, useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = (props) => {
  const { isLogin, loading } = useSelector((state) => state.client.user);
  const history = useHistory();

  const redirectAuth = () => {
    if (!isLogin && !loading) {
      history.push('/');
    }
  };

  useEffect(() => {
    redirectAuth();

    // redirectAuth
    // eslint-disable-next-line
  }, [isLogin, loading]);
  return (
    isLogin
      ? <Route {...props} />
      : <Redirect to={{ pathname: '/' }} />
  );
};

export default ProtectedRoute;
