import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const LogOutModal = ({ open, handleClose, handleConfirm }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Вихід з системи?</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Ви впевнені, що хочете вийти з системи?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Скасувати
      </Button>
      <Button onClick={handleConfirm} color="primary">
        Вийти
      </Button>
    </DialogActions>
  </Dialog>
);

export default LogOutModal;
