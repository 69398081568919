import React from 'react';
import Content from '../../../../components/Content';
import { ThemeTitle } from './styles';

export default function RightContent({ themes, activeStep }) {
  return (
    <>
      {
        themes.map((item, index) => (
          <React.Fragment key={index}>
            <ThemeTitle>
              {
                index === 0
                  ? item.title
                  : `ТЕМА ${activeStep}. ${item.title}`
              }
            </ThemeTitle>
            <Content dangerouslySetInnerHTML={{ __html: item.content }} />
          </React.Fragment>
        ))
      }
    </>
  );
}
