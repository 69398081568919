import React from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  createStyles,
} from '@material-ui/core';

export const useAccordionStyles = makeStyles((theme) => createStyles({
  button: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  buttons: {
    '& > *:not(:first-child)': {
      marginLeft: 10,
    },
  },
}));

export default function AccordionDialog({ title, buttons, children }) {
  const cls = useAccordionStyles();

  return (
    <Accordion>

      <AccordionSummary>
        <Grid container justify="space-between" alignItems="center">
          <Typography>{title}</Typography>

          <div className={cls.buttons}>
            {buttons}
          </div>
        </Grid>

      </AccordionSummary>
      { children }
      <AccordionDetails />

    </Accordion>
  );
}
