import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useDispatch, useSelector } from 'react-redux';
import NewUsersDialog from 'admin/components/NewUsersDialog';
import { setDashboardCertificateState } from 'admin/store/actions/logsActions';
import CertificationsDialog from 'admin/components/CertificationsDialog';
import { getNewUsersFromDatabase } from '../../store/actions/newUsersAction';
import Page from '../../components/Page';
import DashboardCard from './DashboardCard';

export default function Dashboard() {
  // in future can create []render state
  const [openModalIndex, setOpenModalIndex] = useState(null);

  const dispatch = useDispatch();
  const { newUsers: newUsersRoot, logs } = useSelector((state) => state.admin);

  const newUsers = newUsersRoot.users;
  const dashboardState = logs.dashboard;

  useEffect(() => {
    dispatch(getNewUsersFromDatabase());
    dispatch(setDashboardCertificateState());

    // dispatch
    // eslint-disable-next-line
  }, []);

  const newUsersCardClickHandler = () => {
    if (!newUsers) return setOpenModalIndex(null);

    setOpenModalIndex(0);
  };

  const certificatesCardClickHandler = () => {
    setOpenModalIndex(1);
  };

  return (
    <Page title="Панель керування">
      <Grid container>

        <DashboardCard
          onClick={newUsersCardClickHandler}
          title="Нові користувачі"
          icon={GroupAddIcon}
          count={
            newUsers === null
              ? <CircularProgress />
              : newUsers.length
          }
        />

        <DashboardCard
          onClick={certificatesCardClickHandler}
          title="Сертифікати"
          icon={InsertDriveFileIcon}
          count={
            dashboardState === null
              ? <CircularProgress />
              : Object.keys(dashboardState).length
          }
        />
      </Grid>

      {
        newUsers && (
          <NewUsersDialog
            users={newUsers}
            dialogProps={{
              onClose: () => { setOpenModalIndex(null); },
              open: openModalIndex === 0,
            }}
          />
        )
      }
      {
        dashboardState && (
          <CertificationsDialog
            state={dashboardState}
            dialogProps={{
              onClose: () => { setOpenModalIndex(null); },
              open: openModalIndex === 1,
            }}
          />
        )
      }
    </Page>
  );
}
