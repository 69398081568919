import { configsCollection } from 'utils/fire';
import { error } from 'utils/logger';
import { SET_CONFIG } from '../reducers/config';
import { setNotification } from './notificationAction';

const setConfig = (payload) => ({
  type: SET_CONFIG,
  payload,
});

export const setGlobalConfig = () => async (dispatch) => {
  try {
    const configsResult = await configsCollection.get();
    const configResult = configsResult.docs[0];

    const config = { ...configResult.data(), id: configResult.id };
    dispatch(setConfig(config));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час завантаження налаштувань', variant: 'error' }));
  }
};

export const saveGlobalConfig = (configData) => async (dispatch) => {
  try {
    await configsCollection.doc(configData.id).update(configData);

    dispatch(setConfig(configData));
    dispatch(setNotification({ text: 'Налаштування успішно збережені', variant: 'success' }));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час зберігання налаштувань', variant: 'error' }));
  }
};
