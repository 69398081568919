import React, { useEffect, useState } from 'react';
import PageWithList from 'admin/components/PageWithList';
import {
  TableDeleteButton, TableEditButton, TableItem, TableItemButtons, TableItemTitle,
} from 'admin/components/Table';
import { setAllQuestionnaires } from 'admin/store/actions/questionnairesAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmDelete from 'admin/components/ConfirmDelete';
import { deleteQuestionnaire } from 'admin/store/actions/questionnaireAction';

const Questionnaires = () => {
  const dispatch = useDispatch();
  const { questionnaires } = useSelector((state) => state.admin);
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const editButtonClick = (id) => (e) => {
    history.push(`/admin/questionnaire/${id}/edit`);
  };
  const deleteButtonClick = (id) => (e) => {
    setId(id);
    setOpen(true);
  };

  const closeDeleteModal = () => setOpen(false);
  const confirmDelete = () => {
    dispatch(deleteQuestionnaire(id));
    closeDeleteModal();
    setId(null);
  };

  useEffect(() => {
    dispatch(setAllQuestionnaires());

    // dispatch
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWithList
        title="Анкети"
        tableTitle="Список анкет"
        createButtonProps={{
          onClick: () => history.push('/admin/questionnaire/create'),
        }}
        listItems={questionnaires.map(({ title, id }) => (
          <TableItem key={id}>
            <TableItemTitle>{title}</TableItemTitle>

            <TableItemButtons>
              <TableEditButton onClick={editButtonClick(id)} />
              <TableDeleteButton onClick={deleteButtonClick(id)} />
            </TableItemButtons>
          </TableItem>
        ))}
      />
      <ConfirmDelete
        title="Ви впевнені, що бажаєте видалити анкету?"
        description="Після видалення анкети відновити її буде неможливо"
        open={open}
        handleClose={closeDeleteModal}
        handleConfirm={confirmDelete}
      />
    </>
  );
};

export default Questionnaires;
