import ReactSelect from 'react-select';
import React, { useEffect } from 'react';
import { CustomTabPanel } from 'admin/components/CustomTabs';
import { useDispatch, useSelector } from 'react-redux';
import { setAllQuestionnaires } from 'admin/store/actions/questionnairesAction';

const Questionnaire = ({ courseData, changeHandler }) => {
  const dispatch = useDispatch();
  const { questionnaires } = useSelector((state) => state.admin);

  const { questionnaire } = courseData;

  const options = questionnaires.map(({ id, title }) => ({ value: id, label: title }));
  const onSelectChange = ({ value }) => changeHandler('questionnaire', value);

  const value = options.find(({ value }) => value === questionnaire);

  useEffect(() => {
    dispatch(setAllQuestionnaires());

    // dispatch
    // eslint-disable-next-line
  }, []);

  return (
    <CustomTabPanel index={5}>
      <ReactSelect
        options={options}
        value={value}

        onChange={onSelectChange}
      />
    </CustomTabPanel>
  );
};

export default Questionnaire;
