import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Alert } from '@material-ui/lab';

// router
import { useParams, useLocation, useHistory } from 'react-router-dom';

// store dispatch
import { useDispatch, useSelector } from 'react-redux';

// styles components
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  useStyles,
  AnswerLabel,
} from './styles';
import Answer from './Answer';

// utils
import { validateFieldIsRequired } from '../../../../utils/validate';

// actions
import {
  addQuestion,
  getQuestion,
  removeQuestion,
  updateQuestionInDataBase,
} from '../../../store/actions/testQuestionAction';
import ConfirmDelete from '../../../components/ConfirmDelete';

const typeOptions = [
  {
    type: 'radio',
    label: 'Одна відповідь',
  },
  {
    type: 'checkbox',
    label: 'Декілька відповідей',
  },
];

const defaultQuestion = {
  question: '',
  type: 'radio',
  answers: [],
  responses: [
    {
      value: '',
    },
  ],
};

const CreatedAndEditForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { questionData, questionLoading } = useSelector((state) => state.admin.test);
  const { id } = useParams();

  // is page edit question
  const isEdit = !!id;

  // state components
  const [hasErrorName, setHasErrorName] = useState([]);
  const [questionError, setQuestionError] = useState([]);
  const [question, setQuestion] = useState(defaultQuestion);
  const [answersRadios, setAnswers] = useState(null);
  const [typeQuestion, setTypeQuestion] = useState(question.type);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    if (isEdit) {
      dispatch(getQuestion(id));
    } else {
      setQuestion({
        ...defaultQuestion,
        responses: [
          {
            value: '',
          },
        ],
      });
      setAnswers(null);
    }

    // dispatch
    // eslint-disable-next-line
  }, [location.pathname]);

  const editQuestionResponse = (response) => response.map((item) => ({
    value: item,
  }));

  useEffect(() => {
    if (isEdit) {
      const { responses = [], type, answers } = questionData;
      if (type === 'radio') {
        setAnswers(answers[0]);
      }

      setQuestion({
        ...questionData,
        responses: editQuestionResponse(responses),
      });
    }
    // isEdit
    // eslint-disable-next-line
  }, [questionData]);

  const handleChange = ({ target }) => {
    setTypeQuestion(target.value);

    setQuestion({
      ...question,
      type: target.value,
      answers: [],
    });
    setAnswers(null);
  };

  const changeNameQuestion = ({ target }) => {
    setQuestion({
      ...question,
      question: target.value,
    });
  };

  const addResponse = () => {
    const resonsesQuestion = [...question.responses];
    resonsesQuestion.push({
      value: '',
    });
    setQuestion({
      ...question,
      responses: resonsesQuestion,
    });
  };

  const changeRadioValue = (value) => {
    setQuestion({
      ...question,
      answers: [value],
    });
    setAnswers(value);
  };

  const changeCheckBoxValue = (value) => {
    let newAnswers = [];
    const answer = [...question.answers];
    const hasValueQuestion = answer.findIndex((item) => item === value);
    if (hasValueQuestion > -1) {
      const filterAnswer = answer.filter((item) => item !== value);
      newAnswers = filterAnswer;
    } else {
      answer.push(value);
      newAnswers = answer;
    }
    setQuestion({
      ...question,
      answers: newAnswers,
    });
  };

  const changeAnswer = (type, value) => {
    if (type === 'radio') {
      changeRadioValue(value);
    } else if (type === 'checkbox') {
      changeCheckBoxValue(value);
    }
  };

  const changeFieldQuestion = (value, index) => {
    const responses = [...question.responses];
    responses[index].value = value;
    setQuestion({
      ...question,
      responses,
    });
  };

  const removeAnswer = (index) => {
    if (question.responses.length > 1) {
      const newResponse = question.responses.filter((item, ind) => ind !== index);
      const answerFilter = question.answers.filter((item) => item !== index);

      if (answersRadios === index) {
        setAnswers(null);
      }

      setQuestion({
        ...question,
        responses: newResponse,
        answers: answerFilter,
      });
    }
  };

  const validateQuestion = () => {
    const responseLength = question.responses.length;
    const answerLength = question.answers.length;
    const { type } = question;

    setQuestionError([]);

    if (responseLength < 2) {
      setQuestionError(['Відповідей повинно бути, мінімум дві']);
      return false;
    }

    if (answerLength === 0) {
      setQuestionError(['Оберіть правильну відповідь']);
      return false;
    }

    if (type === 'checkbox' && answerLength < 2) {
      setQuestionError(['Для даного типу питання, додайте мінімум дві правильні відповіді']);
      return false;
    }

    // validation value responses
    let countError = 0;
    const newResponses = question.responses.map((item) => {
      const validate = validateFieldIsRequired(item.value);
      if (validate.length > 0) {
        countError++;
      }

      return {
        ...item,
        error: validate,
      };
    });

    setQuestion({
      ...question,
      responses: newResponses,
    });

    return countError === 0;
  };

  const validationField = () => {
    const nameQuestionValidate = validateFieldIsRequired(question.question);
    setHasErrorName(nameQuestionValidate);

    return nameQuestionValidate.length === 0;
  };

  const saveQuestion = () => {
    const resultValidateName = validationField();
    const resultQuestionValidate = validateQuestion();

    if (resultValidateName && resultQuestionValidate) {
      const { responses } = question;
      const convertToDataBaseValue = responses.map((item) => item.value);
      const sendData = {
        ...question,
        lectureId: 0,
        responses: convertToDataBaseValue,
      };

      if (isEdit) {
        dispatch(updateQuestionInDataBase(id, sendData));
      } else {
        dispatch(addQuestion(sendData))
          .then(() => {
            setAnswers(null);
            setQuestion({
              ...defaultQuestion,
              responses: [
                {
                  value: '',
                },
              ],
            });
            setTypeQuestion('radio');
          });
      }
    }
  };

  const removeQuestionAction = () => {
    dispatch(removeQuestion(id))
      .then(() => {
        setShowConfirm(false);
        history.push('/admin/tests');
      });
  };

  const cancelCreated = () => {
    history.push('/admin/tests');
  };

  // render function
  const renderAnswer = () => (
    question.responses.map((item, index) => (
      <Box mb={2} key={index}>
        <Answer
          type={question.type}
          value={index}
          answers={question.answers}
          valueField={item.value}
          changeField={(value) => changeFieldQuestion(value, index)}
          handleChange={changeAnswer}
          removeAnswer={() => removeAnswer(index)}
          error={item.error || []}
        />
      </Box>
    ))
  );

  return (
    <Box p={2}>
      <div>
        <Grid container>
          <Grid item lg={12}>
            <Box mb={2} className={classes.createdBox}>
              {
                !isEdit && (
                  <h4>Створення запитання:</h4>
                )
              }
              <Button color="secondary" onClick={cancelCreated}>Скасувати</Button>
            </Box>
          </Grid>
          <Grid item lg={10}>
            <TextField
              label="Заголовок питання"
              size="small"
              fullWidth
              variant="outlined"
              color="primary"
              value={question.question}
              error={hasErrorName.length > 0}
              helperText={hasErrorName[0]}
              onChange={(e) => changeNameQuestion(e)}
              className={classes.inputBackground}
            />
          </Grid>
          <Grid
            item
            lg={2}
            className={classes.removeEvents}
          >
            {
              isEdit && (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => setShowConfirm(true)}
                >
                  Видалити
                </Button>
              )
            }
          </Grid>
          <Grid item lg={6}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Тип запитання:
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={typeQuestion}
                onChange={handleChange}
                size="small"
              >
                {
                  typeOptions.map((item, index) => (
                    <MenuItem value={item.type} key={index}>
                      { item.label }
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <Box mt={3} mb={3}>
              <AnswerLabel>
                Список варіантів:
              </AnswerLabel>
              {
                question.type === 'radio'
                  ? (
                    <RadioGroup aria-label="gender" name="gender1" value={answersRadios}>
                      { renderAnswer() }
                    </RadioGroup>
                  )
                  : renderAnswer()
              }
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={addResponse}
            >
              Додати варіант
            </Button>
          </Grid>
        </Grid>
        {
          questionError.length > 0 && (
            <Box mt={3} mb={3}>
              <Alert severity="error">
                { questionError[0] }
              </Alert>
            </Box>
          )
        }
        <Box mt={3} className={classes.bottomAction}>
          {
            questionLoading
              ? <CircularProgress />
              : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveQuestion}
                >
                  { isEdit ? 'Оновити' : 'Зберегти' }
                </Button>
              )
          }
        </Box>
      </div>

      <ConfirmDelete
        title="Ви впевнені, що бажаєте видалити запитання?"
        description="Після видалення запитання відновити його буде не можливо"
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleConfirm={removeQuestionAction}
      />
    </Box>
  );
};

export default CreatedAndEditForm;
