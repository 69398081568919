import React from 'react';
import styled from 'styled-components';
import clsx from 'classnames';
import 'suneditor/dist/css/suneditor.min.css';

export const Content = (props) => (<EditorContent {...props} className={clsx('sun-editor-editable', props.className)} />);

export const EditorContent = styled.div`
  padding-bottom: 10px;
`;
