import styled, { css } from 'styled-components';
import AppContainer from 'cabinet/components/Container';

export const Wrap = styled.div`
  background-color: white;

  ${(props) => props.paddingBottom && css`
    padding-bottom: ${props.paddingBottom};
  `}
`;

export const Container = styled(AppContainer)`
  min-height: 100vh;
`;

const TitleStyles = `
    font-family: "Roboto Slab", "Roboto", sans-serif;

    color: #000000;

    margin: 0;
`;

export const SupTitle = styled.h1`
    ${TitleStyles}

    padding-top: 62px;

    ${(props) => props.padding && css`
      padding-bottom: 40px;
    `}

    font-size: 43px;
    line-height: 63px;
    letter-spacing: 0.02em;

    ${(props) => props.big && css`font-size: 48px;`}

    ${(props) => css`${props.theme.breakpoints.down('sm')} {
      text-align: center;
    }`}

    ${(props) => `${props.theme.breakpoints.down('xs')} {
            margin-left: auto;
            margin-right: auto;
            word-break: break-all;
            padding-top: 15px;
            
            font-size: 24px;
            line-height: 24px;

            & span {
                display: block;
            }
        }
    `}

    ${(props) => props.styles && props.styles}
`;

export const SubTitle = styled.h2`
    ${TitleStyles}

    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding-top: 15px;
    padding-bottom: 50px;

    font-size: 18px;
    line-height: 18px;

    color: #000;

    & > img {
      width: 26px;
      height: 26px;
    }

    & > img:first-child {
      margin-right: 10px;
    }

    & > img:last-child {
      margin-left: 10px;
    }

    ${(props) => css`${props.theme.breakpoints.down('sm')} {
      text-align: center;
    }`}

    ${(props) => `${props.theme.breakpoints.down('xs')} {
      font-size: 14px;
      line-height: 14px;

      padding-top: 24px;
    }
  `}
`;

export const SecondTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 25px;

  text-align: center;

  font-family: "Roboto Slab", "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 63px;

  color: #000000;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 24px;
      line-height: 24px;

      text-align: center;
    }
  `}
`;
