import styled, { css } from 'styled-components';
import selectedTheme from 'assets/img/SelectedTheme.svg';

export const ThemeTitle = styled.div`
  font-family: "Roboto Slab", "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 30px;

  color: #000000;

  margin-bottom: 38px;
`;

export const ContentContainer = styled.div`
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-flow: wrap column;
    }
  `}
`;

export const ListDescriptionTheme = styled.div`
  position: relative;
  z-index: 1000;

  padding: 100px 25px 20px 30px;
  
  max-width: 302px;
  width: 40%;

  background: #FFFFFF;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.24);
  text-align: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      margin-top: 22px;

      padding: 30px 20px;

      max-width: 100%;
      width: 100%;

      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);
    }
  `}
`;

export const ListTitle = styled.strong`
  display: block;
  color: #000000;
  font-size: 22px;
  margin-bottom: 20px;
`;

export const ListContainer = styled.div`
  position: sticky;
  top: 125px;

  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  text-align: left;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column wrap;

      & > *:not(:first-child) {
        margin-top: 10px;
      }
    }
  `}
`;

export const ListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  text-transform: uppercase;

  a {
    position: relative;

    font-size: 16px;
    color: #000000;
  
  ${({ active }) => active && css`
    font-weight: 600;

    padding-left: 15px;

    &::after {
      content: "";

      position: absolute;
      left: -12px;
      top: 0;

      width: 20px;
      height: 16px;

      background: url(${selectedTheme});
    }
  `}
`;

export const PrevButton = styled.div`
  margin-right: 35px;
  
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const Wrap = styled.div`
  flex-grow: 1;

  margin-top: 22px;

  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);
  padding-top: 69px;
  padding-bottom: 69px; 
  ${(props) => `${props.theme.breakpoints.down('xs')} {
      padding-top: 45px;
      padding-bottom: 45px;
    }`
}
`;

export const Container = styled.div`
  max-width: 758px;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleCard = styled.div`
  padding: 67px 18% 59px;

  background: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 30px 15%;
    }
  `}
`;
