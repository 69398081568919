import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  TextField, FormControlLabel, Switch, Box,
} from '@material-ui/core';
import { useSetUserData } from 'hooks/useSetUserData';
import { setUserLogsByUserId } from 'admin/store/actions/logsActions';
import moment from 'moment';
import EditPage from '../../components/EditPage/EditPage';
import { getInformationUser, updateUserInBase } from '../../store/actions/usersAction';
import Log from './Log';

const Param = ({ label, value }) => (
  <TextField
    variant="outlined"
    label={label}
    value={value}
    disabled
  />
);

const timestamp = moment().unix();
const MAX_INACTIVE_DAYS = 3;

export default function EditUser() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user: userRoot, logs: logsRoot } = useSelector((state) => state.admin);
  const { user } = userRoot;
  const inputs = useSetUserData(user);
  const { isConfirmAdmin = false } = user;

  const logs = logsRoot.user;

  const isUserInactive = logs
    ? logs.length > 0
      ? moment.unix(logs[0].__timestamp).add(MAX_INACTIVE_DAYS, 'd').unix() <= timestamp
      : false
    : false;

  useEffect(() => {
    dispatch(getInformationUser(id));
    dispatch(setUserLogsByUserId(id));

    // dispatch, id
    // eslint-disable-next-line
  }, []);

  const changeActivation = (e) => {
    dispatch(updateUserInBase({ isConfirmAdmin: e.target.checked }, id));
  };

  const params = [
    {
      label: 'Ідентифікатор',
      value: user.id,
    },
    {
      label: "Ім'я",
      value: inputs.name.value,
    },
    {
      label: 'Прізвище',
      value: inputs.lastName.value,
    },
    {
      label: 'Рік народження',
      value: inputs.yearOfBirth.value,
    },
    {
      label: 'E-mail',
      value: inputs.email.value,
    },
    {
      label: 'Номер телефону',
      value: inputs.phone.value ?? 'Немає',
    },
    {
      label: 'Місто',
      value: inputs.city.value?.label,
    },
    {
      label: 'Школа',
      value: inputs.school.value,
    },
    {
      label: 'Вчитель',
      value: inputs.isClassTeacher.value ? 'Так' : 'Ні',
    },
    {
      label: 'Кількість учнів',
      value: inputs.countOfStudents.value ? inputs.countOfStudents.value : 'Немає',
    },
  ];

  return (
    <EditPage
      title="Користувач"
      left={(
        <>
          {params.map(({ label, value }, i) => (
            <Param key={i} label={label} value={value || ''} />
          ))}
        </>
      )}
      right={(
        <>
          <FormControlLabel
            label="Деактивувати / Активувати"
            control={(
              <Switch onChange={changeActivation} checked={isConfirmAdmin} color="primary" />
            )}
          />
        </>
      )}
    >
      <Box paddingTop={2}>
        <h3>Активність користувача: </h3>
        {
          logs === null && 'Завантаження логів!'
        }
        {
          logs?.length === 0 && 'Логів не знайдено!'
        }
        {
          isUserInactive && <Box paddingTop={1} paddingBottom={1}>У користовача мала активність!</Box>
        }
        {
          logs?.length > 0 && logs.map((log, i) => (
            <Log
              key={i}
              log={log}
              user={user}
            />
          ))
        }
      </Box>
    </EditPage>
  );
}
