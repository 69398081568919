import { combineReducers } from 'redux';
import loginReducer from './user';
import testReducer from './test';
import { coursesReducer } from './courses';
import { lessonsReducer } from './lessons';
import { lecturesReducer } from './lectures';
import { userStoryReducer } from './userStory';
import { newUsersReducer } from './newUsers';
import { logsReducer } from './logs';
import { questionnaireReducer } from './questionnaire';

export default combineReducers({
  user: loginReducer,
  courses: coursesReducer,
  test: testReducer,
  lessons: lessonsReducer,
  lectures: lecturesReducer,
  userStory: userStoryReducer,
  newUsers: newUsersReducer,
  logs: logsReducer,
  questionnaire: questionnaireReducer,
});
