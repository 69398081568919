import _ from 'lodash';
import { error } from 'utils/logger';
import { setNotification } from 'store/actions/notificationAction';
import { USER_STORY_SET } from '../reducers/userStory';
import { db } from '../../../utils/fire';

export const setUserStory = (payload) => ({
  type: USER_STORY_SET,
  payload,
});

const userStoryRef = db.collection('userstory');
const coursesRef = db.collection('courses');
const lessonsRef = db.collection('lessons');
const lecturesRef = db.collection('lectures');

export const updateUserStory = (userStory) => async (dispatch) => {
  try {
    await userStoryRef.doc(userStory.id).update(userStory);
    dispatch(setUserStory(userStory));
  } catch (e) {
    error(e);
    dispatch(setNotification({ text: 'Помилка під час оновлення історії користовача', variant: 'error' }));
  }
};

export const removeFirstEnter = () => async (dispatch, getState) => {
  try {
    const { userStory } = getState().client;
    const { isFirstEnter } = userStory;

    // already removed
    if (!isFirstEnter) return;

    const userStoryCopy = { ...userStory };
    userStoryCopy.isFirstEnter = false;

    await userStoryRef.doc(userStoryCopy.id).update(userStoryCopy);
    dispatch(setUserStory(userStoryCopy));
  } catch (e) {
    console.error(e);
  }
};

const getCourseBySlug = async (courseSlug) => {
  const courseResult = await coursesRef.where('slug', '==', courseSlug).get();
  const course = courseResult.docs.map((doc) => doc.data())[0];

  return course;
};

const getLessonBySlug = async (lessonSlug) => {
  const lessonsResult = await lessonsRef.where('slug', '==', lessonSlug).get();
  const lesson = lessonsResult.docs.map((doc) => doc.data())[0];

  return lesson;
};

const getLectureBySlug = async (lessonSlug) => {
  const lecturesResult = await lecturesRef.where('slug', '==', lessonSlug).get();
  const lecture = lecturesResult.docs.map((doc) => doc.data())[0];

  return lecture;
};

export const completeCourse = (courseSlug) => async (dispatch, getState) => {
  try {
    const { courseId } = await getCourseBySlug(courseSlug);
    const { userStory: original } = getState().client;

    const userStory = { ...original };

    const storyCourse = userStory.courses[courseId];

    // TODO add no course in userStory handling.
    if (!storyCourse) {

    }

    storyCourse.complete = true;

    await userStoryRef.doc(userStory.id).update(userStory);
  } catch (e) {
    console.error(e);
  }
};

export const completeLecture = (courseSlug, lessonSlug, lectureSlug) => async (dispatch, getState) => {
  try {
    const { courseId } = await getCourseBySlug(courseSlug);
    const { id: lectureId } = await getLectureBySlug(lectureSlug);
    const { id: lessonId } = await getLessonBySlug(lessonSlug);

    const { userStory: original, lessons } = getState().client;

    // for avoid mutation state
    const userStory = { ...original };

    // storyLectures can be undefined.
    let storyLessons = userStory.courses[courseId].lessons;

    if (!storyLessons) {
      userStory.courses[courseId].lessons = {};
      storyLessons = userStory.courses[courseId].lessons;
    }

    // storyLesson can be undefined
    let storyLesson = storyLessons[lessonId];

    if (!storyLesson) {
      storyLessons[lessonId] = { lectures: [], complete: false };
      storyLesson = storyLessons[lessonId];
    }

    const { lectures } = storyLesson;

    // if already in lectures
    if (lectures.includes(lectureId)) return;

    lectures.push(lectureId);

    // validate;
    const lesson = lessons.find((lesson) => lesson.id === lessonId);
    const invalidLectures = _.difference(lectures, lesson.lectures);

    let correctLectures;

    if (invalidLectures.length) {
      correctLectures = _.remove(lectures, (n) => invalidLectures.includes(n));
    } else {
      correctLectures = [...lectures];
    }

    storyLesson.lectures = correctLectures;

    await userStoryRef.doc(userStory.id).set(userStory);

    dispatch(setUserStory(userStory));
  } catch (e) {
    console.error(e);
  }
};

export const completeLesson = (courseSlug, lessonSlug) => async (dispatch, getState) => {
  try {
    const { courseId } = await getCourseBySlug(courseSlug);
    const { id: lessonId } = await getLessonBySlug(lessonSlug);

    const { userStory: original, lessons } = getState().client;
    const userStory = { ...original };

    // storyLectures can be undefined.
    let storyLessons = userStory.courses[courseId].lessons;

    if (!storyLessons) {
      userStory.courses[courseId].lessons = {};
      storyLessons = userStory.courses[courseId].lessons;
    }

    // storyLesson can be undefined
    let storyLesson = storyLessons[lessonId];
    if (!storyLesson) {
      storyLessons[lessonId] = { lectures: [], complete: false };
      storyLesson = storyLessons[lessonId];
    }

    const { lectures } = storyLesson;

    // validate;
    const lesson = lessons.find((lesson) => lesson.id === lessonId);
    const invalidLectures = _.difference(lectures, lesson.lectures);

    let correctLectures;

    if (invalidLectures.length) {
      correctLectures = _.remove(lectures, (n) => invalidLectures.includes(n));
    } else {
      correctLectures = [...lectures];
    }
    storyLesson.lectures = correctLectures;
    storyLesson.complete = true;

    await userStoryRef.doc(userStory.id).set(userStory);

    dispatch(setUserStory(userStory));
  } catch (e) {
    console.error(e);
  }
};

export const setUserStoryById = (id) => async (dispatch) => {
  try {
    const userStoryResult = await userStoryRef.doc(id).get();

    dispatch(setUserStory(userStoryResult.data()));
  } catch (e) {
    console.error(e);
  }
};
