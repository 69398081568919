import React from 'react';
import { TextField, makeStyles, createStyles } from '@material-ui/core';
import TextArea from '../../../../components/TextArea';
import { CustomTabPanel } from '../../../../components/CustomTabs';
import { mapErrorsToHelperText } from '../../../../../utils/yupUtils';
import { replaceInvalidSlug } from '../../../../../utils/validate';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '& .MuiTextField-root:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function Main({ lessonData, changeHandler, errors }) {
  const { title, description, slug } = lessonData;

  const titleChangeHandler = (e) => changeHandler('title', e.target.value);

  const descriptionChangeHandler = (e) => changeHandler('description', e.target.value);

  const slugChangeHandler = (e) => {
    const value = replaceInvalidSlug(e.target.value);

    // optimization
    if (value === slug) return;

    changeHandler('slug', value);
  };

  const cls = useStyles();

  return (
    <CustomTabPanel className={cls.root} index={0}>
      <TextField
        fullWidth

        label="Найменування"
        variant="outlined"

        value={title}
        onChange={titleChangeHandler}

        error={!!errors.title}
        helperText={mapErrorsToHelperText(errors.title)}
      />
      <TextField
        fullWidth

        label="Slug-id"
        variant="outlined"

        value={slug}
        onChange={slugChangeHandler}

        error={!!errors.slug}
        helperText={mapErrorsToHelperText(errors.slug)}
      />
      <TextArea
        label="Короткий опис"

        value={description}
        onChange={descriptionChangeHandler}

        error={!!errors.description}
        helperText={mapErrorsToHelperText(errors.description)}
      />
    </CustomTabPanel>
  );
}
