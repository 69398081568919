const text = {
  required: 'Обов\'язкове поле',
  email: 'Введіть правильний email',
  yearBirth: 'Введіть правильний рік народження',
  phone: 'Введіть правильний номер телефону',
};

export const replaceInvalidSlug = (value) => value.replace(/[^a-z0-9-]+?/gi, '');

const phoneCheck = [
  {
    reg: '',
    validation: (val, compare) => val === compare,
    message: text.required,
  },
  {
    reg: /^\+38\(0\d\d\) \d\d\d-\d\d\d\d$/,
    validation: (val, compare) => compare.test(val) === false,
    message: text.phone,
  },
];

const emailCheck = [
  {
    reg: '',
    validation: (val, compare) => val === compare,
    message: text.required,
  },
  {
    reg: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    validation: (val, compare) => compare.test(val) === false,
    message: text.email,
  },
];

const passwordCheck = [
  {
    reg: '',
    validation: (val, compare) => val === compare,
    message: text.required,
  },
];

const fieldCheck = [
  {
    reg: '',
    validation: (val, compare) => !val,
    message: text.required,
  },
];

export const yearBirthCheck = [
  {
    reg: '',
    validation: (val, compare) => val === compare,
    message: text.required,
  },
  {
    reg: '',
    validation: (val) => {
      const date = new Date();
      const year = date.getFullYear();
      const value = Number(val);
      if (value > 1950 && value <= (year - 18)) {
        return false;
      }
      return true;
    },
    message: text.yearBirth,
  },
];

export const validatePhone = (phone) => {
  const error = [];
  phoneCheck.forEach((el) => {
    if (el.validation(phone, el.reg)) error.push(el.message);
  });
  return error;
};

export const validateEmailField = (email) => {
  const error = [];
  emailCheck.forEach((el) => {
    if (el.validation(email, el.reg)) error.push(el.message);
  });
  return error;
};

export const validatePasswordField = (password) => {
  const error = [];
  passwordCheck.forEach((el) => {
    if (el.validation(password, el.reg)) error.push(el.message);
  });
  return error;
};

export const validateFieldIsRequired = (field) => {
  const error = [];

  fieldCheck.forEach((el) => {
    if (el.validation(field, el.reg)) error.push(el.message);
  });
  return error;
};

export const validateYearOfBirth = (value) => {
  const error = [];
  yearBirthCheck.forEach((el) => {
    if (el.validation(value, el.reg)) error.push(el.message);
  });
  return error;
};
