export const SET_TEST_LIST = 'SET_TEST_LIST';
export const SET_REMOVE_ITEM = 'SET_REMOVE_ITEM';

// question type
export const SET_GET_ALL_QUESTION = 'SET_GET_ALL_QUESTION';
export const SET_LOADIND_QUESTIONS = 'SET_LOADIND_QUESTIONS';
export const SET_NEW_QUESTION = 'SET_NEW_QUESTION';
export const SET_QUESTION_MESSAGE = 'SET_QUESTION_MESSAGE';
export const SET_QUESTION_DATA = 'SET_QUESTION_DATA';
export const SET_LOADING_QUESTION = 'SET_LOADING_QUESTION';
export const SET_REMOVE_QUESTION = 'SET_REMOVE_QUESTION';

// created test type
export const SET_UN_SELECTED_QUESTION = 'SET_UN_SELECTED_QUESTION';
export const SET_ADD_QUESTION_FROM_LECTURE = 'SET_ADD_QUESTION_FROM_LECTURE';
export const SET_REMOVE_QUESTION_FROM_LECTURE = 'SET_REMOVE_QUESTION_FROM_LECTURE';
export const SET_LECTURE_QUESTIONS = 'SET_LECTURE_QUESTIONS';

const initialState = {
  testsList: [],
  questions: [],
  loadingQuestions: true,
  questionMessage: {
    type: 'success',
    message: null,
  },
  questionData: {},
  questionLoading: false,
  unSelectedQuestion: [],
  lectureQuestions: [],
};

const testsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TEST_LIST:
      return {
        ...state,
        testsList: action.payload,
      };
    case SET_REMOVE_ITEM: {
      const newArray = state.testsList.filter((item) => item.id !== action.payload);
      return {
        ...state,
        testsList: newArray,
      };
    }
    case SET_GET_ALL_QUESTION:
      return {
        ...state,
        questions: action.payload,
      };
    case SET_LOADIND_QUESTIONS:
      return {
        ...state,
        loadingQuestions: action.payload,
      };
    case SET_NEW_QUESTION: {
      const newQuestions = [...state.questions];
      newQuestions.push(action.payload);
      return {
        ...state,
        questions: newQuestions,
      };
    }
    case SET_QUESTION_MESSAGE:
      return {
        ...state,
        questionMessage: {
          ...state.questionMessage,
          ...action.payload,
        },
      };
    case SET_QUESTION_DATA:
      return {
        ...state,
        questionData: action.payload,
      };
    case SET_LOADING_QUESTION:
      return {
        ...state,
        questionLoading: action.payload,
      };
    case SET_REMOVE_QUESTION: {
      const newQuestions = state.questions.filter((item) => item.id !== action.payload);
      return {
        ...state,
        questions: newQuestions,
        questionData: {},
      };
    }
    case SET_UN_SELECTED_QUESTION:
      return {
        ...state,
        unSelectedQuestion: action.payload,
      };
    case SET_ADD_QUESTION_FROM_LECTURE: {
      const newQuestion = state.unSelectedQuestion.filter((item) => item.id !== action.payload);
      return {
        ...state,
        unSelectedQuestion: newQuestion,
      };
    }
    case SET_LECTURE_QUESTIONS:
      return {
        ...state,
        lectureQuestions: action.payload,
      };
    default:
      return state;
  }
};

export default testsReducer;
