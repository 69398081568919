import { C_SET_LECTURES } from '../reducers/lectures';
import { db } from '../../../utils/fire';

export const setLectures = (payload) => ({
  type: C_SET_LECTURES,
  payload,
});

const lecturesRef = db.collection('lectures');

export const setLecturesByIds = (ids) => async (dispatch) => {
  try {
    // fix firebase not empty array required <error></error>
    if (ids.length === 0) {
      dispatch(setLectures([]));
      return;
    }

    const lecturesResult = await lecturesRef.where('id', 'in', ids).get();
    const lectures = lecturesResult.docs.map((doc) => doc.data());

    dispatch(setLectures(lectures));
  } catch (e) {
    console.error(e);
  }
};

export const setLectureBySlug = (lectureSlug) => async (dispatch) => {
  try {
    const lectureResult = await lecturesRef.where('slug', '==', lectureSlug).get();
    const lectures = lectureResult.docs.map((doc) => doc.data());

    dispatch(setLectures(lectures));
  } catch (e) {
    console.error(e);
  }
};
