import styled, { css } from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';

const primaryProps = {
  variant: 'contained',
  color: 'primary',
};

export const PrimaryButton = styled(MaterialButton, primaryProps)`
    && {
        padding: 19px 30px;

        ${(props) => props.$secondPadding && css`
            padding: 19px 35px;
        `}

        font-family: "Roboto", sans-serif;
        font-weight: 500;
    
        font-size: 13px;

        border-radius: 3px;
    
        background-color: #1499fe;
    
        text-align: center;
        text-transform: uppercase;
    
        color: #FFFFFF;
    }

    && span:first-child {
        line-height: 17px;
    }

    &&:hover {
        background-color: #1976d2;
    }
`;
