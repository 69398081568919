export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_PASSED_TEST = 'SET_PASSED_TEST';
export const SET_ID_RESULT_TEST = 'SET_ID_RESULT_TEST';
export const SET_LOADER_TEST = 'SET_LOADER_TEST';

const initialState = {
  questions: [],
  isPassedTest: false,
  idResultTest: null,
  loaderTest: false,
};

const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONS:
      return {
        ...state,
        questions: [...action.payload],
      };
    case SET_PASSED_TEST:
      return {
        ...state,
        isPassedTest: action.payload,
      };
    case SET_ID_RESULT_TEST:
      return {
        ...state,
        idResultTest: action.payload,
      };
    case SET_LOADER_TEST:
      return {
        ...state,
        loaderTest: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default testReducer;
