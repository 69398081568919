import React from 'react';
import {
  Grid, Paper, makeStyles, createStyles,
} from '@material-ui/core';
import Page from '../Page';
import { HeaderButton } from '../Header';

const useStyles = makeStyles((theme) => createStyles({
  title: {
    marginBottom: theme.spacing(5),
  },
  container: {
    padding: theme.spacing(5),
  },
  secondContainer: {
    flexGrow: 1,
  },
  left: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  right: {
    flexGrow: 1,
  },
}));

export default function EditPage({
  title, left, right, saveButtonProps, children,
}) {
  const cls = useStyles();

  return (
    <Page
      title={title}
      headerButton={(
        <HeaderButton
          {...saveButtonProps}
        >
          Зберегти
        </HeaderButton>
      )}
    >
      <Grid
        container
        variant="outlined"
        className={cls.container}
        component={Paper}
        direction="column"
      >

        <Grid
          item
          container
          className={cls.secondContainer}
          justify="space-between"
        >

          <Grid
            lg={4}
            md={5}
            item
            container
            direction="column"
            className={cls.left}
          >
            {left}
          </Grid>

          {/* space block */}

          <Grid
            item
            lg={2}
            md={1}
          />

          <Grid
            lg={6}
            md={6}
            className={cls.right}
            item
          >
            {right}
          </Grid>

        </Grid>

        {children}

      </Grid>
    </Page>
  );
}
