import React from 'react';

export const mapYupInnerToState = (inner) => {
  if (!inner) return {};

  return inner.reduce((state, { path, errors }) => {
    if (state[path]) {
      state[path].push(...errors);
    } else {
      state[path] = errors;
    }

    return state;
  }, {});
};

export const mapErrorsToHelperText = (errors) => (
  <>
    {errors && errors.map((error) => (<span key={error} style={{ display: 'block' }}>{error}</span>))}
  </>
);
