import { setNotification } from 'store/actions/notificationAction';
import { questionnairesCollection } from 'utils/fire';
import { error } from 'utils/logger';
import { A_SET_QUESTIONNAIRES } from '../reducers/questionnaires';

const setQuestionnaires = (payload) => ({
  type: A_SET_QUESTIONNAIRES,
  payload,
});

export const setAllQuestionnaires = () => async (dispatch) => {
  try {
    const questionnairesResult = await questionnairesCollection.get();
    const questionnaires = questionnairesResult.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch(setQuestionnaires(questionnaires));
  } catch (e) {
    dispatch(setNotification({ text: 'Помилка під зас завантаження списку анкет', variant: 'error' }));
    error(e);
  }
};
