import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../../cabinet/components/ui/Select';
import {
  DragContextWrapper,
  useStyles,
} from './styles';
import DragContainer from './DragContainer';
import { getAllLectureInBase } from '../../../store/actions/lectureAction';
import { getCoursesFromDataBase } from '../../../store/actions/coursesAction';
import { getAllUnSelectedQuestions, loadSelectedQuestion } from '../../../store/actions/createdTestAction';

const CreatedTest = () => {
  const dispatch = useDispatch();
  const { lectures } = useSelector((state) => state.admin.lecture);
  const { coursesList } = useSelector((state) => state.admin.courses);

  const cls = useStyles();

  // state components
  const [lecturesOptions, setLectureOptions] = useState([]);
  const [lectureValue, setLectureValue] = useState('');
  const [courseOptions, setCourseOptions] = useState([]);
  const [courseValue, setCourseValue] = useState('');

  useEffect(() => {
    dispatch(getAllLectureInBase());
    dispatch(getCoursesFromDataBase());

    // dispatch
    // eslint-disable-next-line
  }, []);

  const formatDataOptions = (arrayValue) => arrayValue.map((item) => ({
    ...item,
    label: item.title,
    value: item.id,
  }));

  useEffect(() => {
    setLectureOptions(formatDataOptions(lectures));
    setCourseOptions(formatDataOptions(coursesList));
  }, [lectures, coursesList]);

  const changeLectureOption = (value) => {
    setLectureValue(value);

    dispatch(loadSelectedQuestion(value.id));
    dispatch(getAllUnSelectedQuestions());
  };

  const changeCourseOption = (value) => {
    setCourseValue(value);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={5}>
          <Box p={1}>
            <Select
              className={cls.select}
              label="Курс"
              placeholder="Оберіть курс"
              value={courseValue}
              options={courseOptions}
              onChange={changeCourseOption}
            />
          </Box>
        </Grid>
        <Grid item lg={1} />
        <Grid item lg={5}>
          <Box p={1}>
            <Select
              className={courseValue !== '' && cls.select}
              label="Лекція"
              placeholder="Оберіть лекцію"
              value={lectureValue}
              options={lecturesOptions}
              isDisabled={courseValue === ''}
              onChange={changeLectureOption}
            />
          </Box>
        </Grid>
      </Grid>
      <DragContextWrapper disabled={lectureValue === ''}>
        <DragContainer lecture={lectureValue} />
      </DragContextWrapper>
    </>
  );
};

export default CreatedTest;
