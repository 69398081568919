import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HelpIcon from '@material-ui/icons/Help';
import BrandImg from 'assets/img/brand.png';
import { useSelector, useDispatch } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  Brand,
  Placeholder,
  useStyles,
} from './styles';
import UiHeader from '../ui/Header/EditedHeader/index';
import HeaderLinks from './HeaderLinks';
import { getInformationUser, logoutUser } from '../../store/actions/userActions';
import LogOutModal from '../LogOutModal';
import HelpDialog from '../HelpDialog';
import { FirstEnterModalContext } from '../../../App';

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSuperUser, userProfileData, user } = useSelector((state) => state.client.user);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const [links, setLinks] = useState([
    {
      to: '/dashboard',
      content: 'Курси',
    },
    {
      to: '/profile',
      content: 'Мій профіль',
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [avatarLetters, setAvatarLetters] = useState('');

  // firstEnter
  // modalWindow
  // eslint-disable-next-line
  const { showModal: showEnterModal } = useContext(FirstEnterModalContext);

  // modalWindow
  // eslint-disable-next-line
  const helpButtonRef = useRef();

  const generateName = (name, lastName) => `${name[0]}${lastName[0]}`;

  useEffect(() => {
    dispatch(getInformationUser(user.uid));

    // dispatch
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const valueAvatar = !userProfileData
      ? ''
      : generateName(userProfileData.name, userProfileData.lastName);

    setAvatarLetters(valueAvatar);
  }, [userProfileData]);

  useEffect(() => {
    if (isSuperUser) {
      const link = [...links];
      link.unshift({
        to: '/admin',
        content: 'Панель адміністратора',
      });
      setLinks(link);
    }

    // links
    // eslint-disable-next-line
  }, [isSuperUser]);

  const logOut = () => {
    dispatch(logoutUser());
  };

  const buttons = [
    // {
    //   text: 'Допомога',
    //   ref: helpButtonRef,
    //   onClick: () => {
    //     // fix button view bug.
    //     helpButtonRef.current && helpButtonRef.current.blur();

    //     showEnterModal();
    //   },
    // },
  ];

  return (
    <>
      <UiHeader
        fixed
        brand={(
          <Link to="/dashboard">
            <Brand src={BrandImg} alt="brand" />
          </Link>
        )}
        rightLinks={(<HeaderLinks buttons={buttons} links={links} />)}
        avatar={(
          <>
            <Link to="/profile">
              <Avatar className={classes.purple}>
                { avatarLetters }
              </Avatar>
            </Link>
            <HelpIcon className={classes.helperIcon} onClick={() => setOpenModalIndex(1)} color="inherit" />
            <ExitToAppIcon onClick={() => setShowModal(true)} />
          </>
        )}
      />
      <Placeholder />
      <HelpDialog
        dialogProps={{
          onClose: () => { setOpenModalIndex(null); },
          open: openModalIndex === 1,
        }}
        title='Потрібна допомога?'
      />
      <LogOutModal
        open={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={logOut}
      />
    </>
  );
};

export default Header;
