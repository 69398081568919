import styled, { css } from 'styled-components';
import SecondaryButton from '@material-ui/core/Button';

export const Wrapper = styled.div``;

export const Title = styled.h2`
  text-align: center;
  color: black;
`;

export const Description = styled.div`
  font-size: 28px;
  line-height: 40px;
  color: black;
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const TotalCount = styled.span`
  font-size: 36px;
  font-weight: bold;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  max-width: 200px;
  margin: 0 auto;
`;

export const ResultDescription = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #f32727;
  
  ${({ composed }) => (
    composed
      ? css`
          color: #0f8c0f;
      `
      : null
  )}
`;

export const CertificateButton = styled(SecondaryButton)`
  &&.Mui-disabled {
    background-color: #f1c40f;
    color: white;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  
  & > *:not(:first-child) {
    margin-left: 30px;
  }
  
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    
    button {
      width: fit-content;
    }
    
    & > button {
      margin-left: 0;
      margin-bottom: 30px;
    }
  }
`;
