import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TestResult from 'admin/views/TestResult';
import Questionnaires from 'admin/views/Questionnaires/Questionnaires';
import EditQuestionnaire from 'admin/views/EditQuestionnaire';
import QuestionnaireResult from 'admin/views/QuestionnaireResult';
import Config from 'admin/views/Config';
import About from '../views/About';
import Courses from '../views/Courses/Courses';
import Lectures from '../views/Lectures/Lectures';
import Tests from '../views/Tests/Tests';
import Users from '../views/Users';
import EditCourse from '../views/EditCourse';
import Dashboard from '../views/Dashboard';
import EditUser from '../views/EditUser';
import CreatedLecture from '../views/CreatedLecture';
import Lessons from '../views/Lessons';
import EditLesson from '../views/EditLesson';

const Router = () => (
  <Switch>
    <Route path="/admin" exact component={Dashboard} />
    <Route path="/admin/about" exact component={About} />
    <Route path="/admin/courses" exact component={Courses} />
    <Route path="/admin/users" exact component={Users} />
    <Route path="/admin/lessons" exact component={Lessons} />
    <Route path="/admin/lectures" exact component={Lectures} />
    <Route path="/admin/course/created" exact component={EditCourse} />
    <Route path="/admin/lecture-created" exact component={CreatedLecture} />
    <Route path="/admin/lesson/create" exact component={EditLesson} />
    <Route path="/admin/lesson/:id/edit" exact component={EditLesson} />
    <Route path="/admin/course/:id/edit" exact component={EditCourse} />
    <Route path="/admin/user/:id/edit" exact component={EditUser} />
    <Route path="/admin/lecture/:id/edit" exact component={CreatedLecture} />

    <Route path="/admin/config" exact component={Config} />

    <Route path="/admin/questionnaire-result/:id" exact component={QuestionnaireResult} />

    <Route path="/admin/questionnaires" exact component={Questionnaires} />
    <Route path="/admin/questionnaire/:id/edit" exact component={EditQuestionnaire} />
    <Route path="/admin/questionnaire/create" exact component={EditQuestionnaire} />

    <Route path="/admin/test-result/:id" exact component={TestResult} />

    <Route path="/admin/tests" exact component={Tests} />
    <Route path="/admin/tests/created" exact component={Tests} />
    <Route path="/admin/test/:id" exact component={Tests} />
  </Switch>
);

export default Router;
