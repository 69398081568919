// jsx-factory
import React from 'react';

// style
import AuthLayout from 'cabinet/layout/Auth';
import CustomInput from 'cabinet/components/ui/CustomInput/CustomInput';
import Button from 'cabinet/components/ui/CustomButtons/Button';
import CardBody from 'cabinet/components/ui/Card/CardBody';
import CardHeader from 'cabinet/components/ui/Card/CardHeader';
import CardFooter from 'cabinet/components/ui/Card/CardFooter';
import { Grid } from '@material-ui/core';

// router
import { Link } from 'react-router-dom';

// <head /> editor
import { Helmet } from 'react-helmet';

// business logic
import { useDispatch } from 'react-redux';
import { resetPasswordByEmail } from 'cabinet/store/actions/resetPassword';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useStyles } from '../Login/Login';
import { AlertBox, InputBox } from '../Login/styles';

const schema = yup.object().shape({
  email: yup.string().email('email повинен бути валідним!').required('email це обов`язкове поле!'),
});

const ResetPassword = () => {
  const cls = useStyles();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const submitHandler = async (data) => {
    await dispatch(resetPasswordByEmail(data.email));
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Забули пароль | Навчальна платформа</title>
      </Helmet>

      <form className={cls.form} onSubmit={handleSubmit(submitHandler)}>
        <CardHeader color="primary" className={cls.cardHeader}>
          <h4>Забули пароль</h4>
        </CardHeader>

        <CardBody>
          <InputBox>
            <CustomInput
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                name: 'email',
                inputRef: register(),
              }}
            />
            {
            errors.email && <AlertBox severity="error">{errors.email.message}</AlertBox>
          }
          </InputBox>
        </CardBody>

        <CardFooter className={cls.cardFooter}>
          <Button
            simple
            id="login-btn"
            color="primary"
            size="lg"
            type="submit"
          >
            Підтвердити
          </Button>
          <Grid container>
            <Grid item xs={12} />
            <Grid item className={cls.textAlignRight} xs={12}>
              <Link to="/">Вхід</Link>
            </Grid>
          </Grid>
        </CardFooter>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
