// types for collection events [LOGS].
export const TEST = 'TEST';
export const TEST_BLOCKED = 'TEST_BLOCKED';
export const CERTIFICATE = 'CERTIFICATE';
export const QUESTIONNAIRE = 'QUESTIONNAIRE';
export const QUESTIONNAIRE_CANCEL = 'QUESTIONNAIRE_CANCEL';

// event collection
// {
//   events: {
//     eventId: {
//       ...data,
//       __action: TYPE,
//       __userId: user.uid,
//       __timestamp: number (unix timestamp),
//       __searchDate: "DD.MM.YYYY" (date),
//     }
//   }
// }
