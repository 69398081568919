import styled from 'styled-components';

export const SubmitWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
`;

export const Title = styled.div`
  margin-top: 91px;
  margin-bottom: 35px;
  font-family: "Roboto Slab", "Roboto", sans-serif;
  text-align: center;
  font-size: 25px;
  line-height: 33px;
  color: #000000;

  ${(props) => props.theme.breakpoints.down('xs')} {
      margin-top: 35px;
  }
`;

export const Container = styled.div`
  max-width: 742px;
  margin-left: auto;
  margin-right: auto;
`;
