import { C_NEW_USERS_SET } from '../reducers/newUsers';
import { db } from '../../../utils/fire';

export const setNewUsers = (payload) => ({
  type: C_NEW_USERS_SET,
  payload,
});

const newUsersRef = db.collection('newusers');

export const setAllNewUsers = () => async (dispatch) => {
  try {
    const newUsersResult = await newUsersRef.get();
    const newUsers = newUsersResult.docs.map((doc) => doc.id);

    dispatch(setNewUsers(newUsers));
  } catch (e) {
    console.error(e);
  }
};
