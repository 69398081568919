import { combineReducers } from 'redux';
import coursesReducer from './courses';
import usersReducer from './users';
import testsReducer from './tests';
import { newUsersReducer } from './newUsers';
import { courseReducer } from './course';
import { lessonsReducer } from './lessons';
import lectureReducer from './lecture';
import { lessonReducer } from './lesson';
import { messagesReducer } from './messages';
import { logsReducer } from './logs';
import { testResultReducer } from './testResult';
import { questionnairesReducer } from './questionnaires';
import { questionnaireReducer } from './questionnaire';
import { questionnaireResultReducer } from './questionnaireResult';

export default combineReducers({
  courses: coursesReducer,
  user: usersReducer,
  test: testsReducer,
  newUsers: newUsersReducer,
  course: courseReducer,
  lecture: lectureReducer,
  lessons: lessonsReducer,
  lesson: lessonReducer,
  messages: messagesReducer,
  logs: logsReducer,
  testResult: testResultReducer,
  questionnaires: questionnairesReducer,
  questionnaire: questionnaireReducer,
  questionnaireResult: questionnaireResultReducer,
});
