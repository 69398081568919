import { getLogsByUserId } from 'utils/events/utils';
import { getAllCertificatesLogs } from 'utils/events/certificate';
import { getFormatedDateFromTimestamp } from 'utils/momentUtils';
import firebase, { db } from 'utils/fire';
import axios from 'axios';
import { setMessage } from './messagesAction';
import { SET_DASHBOARD_STATE, SET_USER_LOGS } from '../reducers/logs';

const setUserLogs = (payload) => ({
  type: SET_USER_LOGS,
  payload,
});

const setDashboardState = (payload) => ({
  type: SET_DASHBOARD_STATE,
  payload,
});

export const setUserLogsByUserId = (userId) => async (dispatch) => {
  try {
    dispatch(setUserLogs(await getLogsByUserId(userId)));
  } catch (e) {
    dispatch(setMessage({
      text: 'Помилка при загрузці логів користовача!',
      variant: 'error',
    }));
    console.error(e);
  }
};

const getStateFromCertificateLogs = (logs) => {
  const generateRenderDataFromLog = ({
    title,
    isNew,
    pathToCertificate,
    __timestamp,
  }) => ({
    title,
    isNew,
    pathToCertificate,
    date: getFormatedDateFromTimestamp(__timestamp),
  });

  return logs.reduce((state, log) => {
    const { email, __userId } = log;
    const inState = state[email];

    if (inState) {
      inState.renderData.push(generateRenderDataFromLog(log));

      return state;
    }

    state[email] = {
      renderData: [generateRenderDataFromLog(log)],
      userId: __userId,
    };

    return state;
  }, {});
};

export const setDashboardCertificateState = () => async (dispatch) => {
  try {
    const logs = await getAllCertificatesLogs();

    const state = getStateFromCertificateLogs(logs);

    dispatch(setDashboardState(state));
  } catch (e) {
    dispatch(setMessage({
      text: 'Помилка при загрузці логів о сертифікатах!',
      variant: 'error',
    }));
    console.error(e);
  }
};

const getDashboardState = (getState, email) => {
  const { logs } = getState().admin;

  const state = { ...logs.dashboard };

  const userState = state[email];
  const { renderData } = userState;

  return { state, userState, renderData };
};

const getUserById = async (userId) => {
  const userResult = await db.collection('users').doc(userId).get();
  const user = { ...userResult.data(), id: userResult.id };

  return user;
};

export const getFileLinkFromFirebasePath = (firebasePath) => firebase.storage().ref(firebasePath).getDownloadURL();

export const getObjectURLLink = async (path) => {
  const { data } = await axios.get(path, { responseType: 'blob' });

  return URL.createObjectURL(data);
};

export const getAbsolutePathToFile = (index, email) => async (dispatch, getState) => {
  try {
    const { state, userState, renderData } = getDashboardState(getState, email);

    const copyRenderData = [...renderData];
    const executor = copyRenderData[index];
    const firebasePath = executor.pathToCertificate;

    if (!userState.user) {
      const user = await getUserById(userState.userId);

      userState.user = user;
      userState.showUser = false;
    }

    const absolutePathToFile = await getFileLinkFromFirebasePath(firebasePath);

    const { user } = userState;

    executor.absolutePathToFile = await getObjectURLLink(absolutePathToFile);
    executor.fileName = `${user.name}_${user.lastName}.pdf`;

    dispatch(setDashboardState(state));
  } catch (e) {
    dispatch(setMessage({
      text: 'Помилка під час завантаження посилання на сертифікат!',
      variant: 'error',
    }));
    console.log(e);
  }
};

export const getUserData = (email) => async (dispatch, getState) => {
  try {
    const { state, userState } = getDashboardState(getState, email);
    const { userId } = userState;

    if (!userState.user) {
      const user = await getUserById(userId);

      userState.user = user;
    }

    userState.showUser = true;

    dispatch(setDashboardState(state));
  } catch (e) {
    dispatch(setMessage({
      text: 'Помилка під час загрузки данних користовача у "Сертифікати"!',
      variant: 'error',
    }));
    console.error(e);
  }
};
