import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Status = styled.legend`
  ${({ status }) => (status
    ? css`color: #29804b;`
    : css`color: red;`)
}
`;

export const Block = styled.fieldset`
  position: relative;

  border-color: #404854;
  border-radius: 5px;
  border-width: 1px;

  margin-bottom: 20px;
`;

export const DateBlock = styled.div`
  position: absolute;
  top: -4px;
  right: 8px;
  z-index: 2;

  background-color: #fff;
  padding: 2px 4px;
`;

export const Link = styled(RouterLink)`
  color: #009be5;
`;

export const Footer = styled.div`
  padding-top: 5px;
  
  text-align: right;
`;
