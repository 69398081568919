import UiInputLabel from '@material-ui/core/InputLabel';
import styled from 'styled-components';

export const InputLabel = styled(UiInputLabel)`
  && {
    font-size: 14px;

    transform: scale(0.75);
  }
`;
