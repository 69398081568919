import styled from 'styled-components';

export const Links = styled.div`
    display: flex;
    align-items: center;
`;

export const Avatar = styled.div`
  padding-left: 38px;
  display: flex;
  align-items: center;
  
  svg {
    cursor: pointer;
    margin-left: 20px;
  }
`;

export const BrandWrap = styled.div`
    display: flex;
`;
