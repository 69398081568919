import styled, { css } from 'styled-components';
import UiCheckbox from '@material-ui/core/Checkbox';
import UiFormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

export const SelectBlock = styled.div`
  padding-top: 27px;
  margin-bottom: 10px;
`;

export const ControlBlock = styled.div`
  > div {
    width: 100%;
  }
`;

export const FormControlLabel = styled(UiFormControlLabel)`
  .MuiFormControlLabel-label {
    font-size 12px;
  }
`;

export const Checkbox = styled(UiCheckbox)`
  &&.Mui-checked {
    color: #1499FE;
  }
`;

export const styleCheckbox = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const AlertBox = styled(Alert)`
  position: absolute;
  left: 0;
  bottom: -13px;
  padding-left: 0 !important;

  ${(props) => props.$select && css`
    bottom: -32px;
  `}

  &.MuiAlert-standardError {
    background-color: transparent;
  }

  .MuiAlert-message {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
  }
  .MuiAlert-icon {
    padding-top: 0;
    padding-bottom: 0;
    width: 17px;
    height: 17px; 

    .MuiSvgIcon-root {
      width: 17px;
      height: 17px; 
    }
  }
`;

export const InputBox = styled.div`
  position: relative;
`;

export const CheckboxCityBox = styled(FormControlLabel)`
  position: absolute;
  top: 10px;
  right: 0;
`;
