export const SET_LECTURE = 'SET_LECTURE';
export const SET_LOADING_LECTURE = 'SET_LOADING_LECTURE';
export const SET_LECTURE_ITEM_DATA = 'SET_LECTURE_ITEM_DATA';
export const SET_REMOVE_LECTURE = 'SET_REMOVE_LECTURE';

const initialState = {
  lectures: [],
  loading: false,
  lectureItemData: {},
};

const lectureReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LECTURE:
      return {
        ...state,
        lectures: action.payload,
      };
    case SET_LOADING_LECTURE:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_LECTURE_ITEM_DATA:
      return {
        ...state,
        lectureItemData: action.payload,
      };
    case SET_REMOVE_LECTURE: {
      const newDataLecture = state.lectures.filter((item) => item.id !== action.payload);
      return {
        ...state,
        lectures: newDataLecture,
      };
    }
    default:
      return state;
  }
};

export default lectureReducer;
