import {
  Button, createStyles, makeStyles, TextField, FormHelperText, IconButton,
} from '@material-ui/core';
import { CustomTabPanel } from 'admin/components/CustomTabs';
import React, { useState } from 'react';
import { mapErrorsToHelperText } from 'utils/yupUtils';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDelete from 'admin/components/ConfirmDelete';
import { FIREBASE, LINK, UPLOAD } from 'admin/store/actions/courseAction';
import { CourseTextArea } from '../../styles';
import Select from '../../../../../cabinet/components/ui/Select';

const useStyles = makeStyles((theme) => createStyles({
  input: {
    display: 'none',
  },
  add__wrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(4),
  },
  card: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(10),
    },
    '& > *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
  card__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card__footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  delete: {
    float: 'right',
  },
}));

const options = [
  {
    value: UPLOAD,
    label: 'Файл',
  },
  {
    value: LINK,
    label: 'Посилання',
  },
  {
    value: FIREBASE,
    label: 'Firebase',
  },
];

const valueOptions = {
  [UPLOAD]: options[0],
  [LINK]: options[1],
  [FIREBASE]: options[2],
};

export default function Library({ courseData, changeHandler, errors }) {
  const cls = useStyles();

  const [open, setOpen] = useState(false);
  const [activeFileIndex, setActiveFileIndex] = useState(null);

  const addFileHandler = () => {
    changeHandler('library', [
      ...courseData.library,
      {
        title: '',
        description: '',
        type: UPLOAD,
        input: {},
      },
    ]);
  };

  const changeFileParam = (index, param, value) => {
    const libraryCopy = [...courseData.library];
    const fileCopy = libraryCopy[index];

    fileCopy[param] = value;

    changeHandler('library', libraryCopy);
  };

  const changeType = (index) => (select) => {
    const libraryCopy = [...courseData.library];
    const fileCopy = libraryCopy[index];

    fileCopy.type = select.value;
    fileCopy.input = {};

    changeHandler('library', libraryCopy);
  };

  const changeFileTitle = (index) => (e) => {
    changeFileParam(index, 'title', e.target.value);
  };

  const changeFileDescription = (index) => (e) => {
    changeFileParam(index, 'description', e.target.value);
  };

  const changeFileInput = (index) => (e) => {
    if (!e.target.files[0]) {
      changeFileParam(index, 'input', {});
      return;
    }

    const file = e.target.files[0];

    changeFileParam(index, 'input', { file, path: file.name });
  };

  const changePath = (index) => (e) => {
    changeFileParam(index, 'input', { path: e.target.value });
  };

  /* remove */
  const removeButtonHandler = (index) => () => {
    setOpen(true);
    setActiveFileIndex(index);
  };

  const removeFile = () => {
    // insurance
    if (!open) return;

    const libraryFiltered = [...courseData.library].filter((file, i) => activeFileIndex !== i);
    changeHandler('library', libraryFiltered);
    setOpen(false);
  };

  return (
    <CustomTabPanel index={3}>

      <div>

        {
          courseData.library.map(({
            title, description, input, type,
          }, i) => (
            <div className={cls.card} key={i}>

              <div className={cls.card__header}>
                <h4>
                  Картка №
                  {i + 1}
                  :
                </h4>

                <IconButton className={cls.delete} onClick={removeButtonHandler(i)}>
                  <DeleteIcon />
                </IconButton>
              </div>

              <TextField
                fullWidth

                value={title}
                onChange={changeFileTitle(i)}

                variant="outlined"
                label="Найменування картки"

                error={!!errors[`library[${i}].title`]}
                helperText={mapErrorsToHelperText(errors[`library[${i}].title`])}
              />

              <CourseTextArea
                value={description}
                onChange={changeFileDescription(i)}

                variant="outlined"
                label="Опис картки"

                error={!!errors[`library[${i}].description`]}
                helperText={mapErrorsToHelperText(errors[`library[${i}].description`])}
              />

              <div>
                <Select
                  label="Тип картки"
                  options={options}

                  value={valueOptions[type]}
                  onChange={changeType(i)}
                />
              </div>

              {
                type === UPLOAD && (
                  <>
                    <input
                      type="file"
                      id={`file-${i}`}
                      className={cls.input}
                      onChange={changeFileInput(i)}
                    />

                    <div className={cls.card__footer}>

                      <label htmlFor={`file-${i}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Вибрати файл
                        </Button>
                      </label>

                      <div>
                        {
                          errors[`library[${i}].input.path`]
                            ? (
                              <FormHelperText error>
                                {mapErrorsToHelperText(errors[`library[${i}].input.path`])}
                              </FormHelperText>
                            )
                            : input.path
                        }
                      </div>

                    </div>
                  </>
                )
              }

              {
                (type === FIREBASE || type === LINK) && (
                  <TextField
                    fullWidth

                    label="Шлях до файлу"

                    onChange={changePath(i)}
                    value={input.path}

                    error={!!errors[`library[${i}].input.path`]}
                    helperText={mapErrorsToHelperText(errors[`library[${i}].input.path`])}
                  />
                )
              }

            </div>
          ))
        }

        <div className={cls.add__wrap}>
          <Button
            variant="contained"
            color="primary"
            onClick={addFileHandler}
          >
            Додати картку
          </Button>
        </div>

        <ConfirmDelete
          title="Ви впевнені, що бажаєте видалити картку?"
          description="Після видалення картки її відновити буде неможливо"
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          handleConfirm={removeFile}
        />

      </div>

    </CustomTabPanel>
  );
}
