import UiCard from 'cabinet/components/ui/Card/Card';
import styled from 'styled-components';

export const Card = styled(UiCard)`
    && {
        background: #FFFFFF;
    
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.24);

        border-radius: 0;
    }
`;
