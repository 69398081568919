import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog, DialogTitle, DialogContent, makeStyles, createStyles,
} from '@material-ui/core';

import Certificate from './Certificate';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    width: '100%',
    maxWidth: 800,
  },
  openTable: {
    marginLeft: '10px',
  },
  headerDialog: {
    '& h2': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  circ: {
    marginLeft: '5px'
  }
}));

export default function CertificationsDialog({ dialogProps, title, state }) {
  const [sendSuccess, setSendSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const cls = useStyles();
  const keys = Object.keys(state);

  const testUrl = 'http://localhost:5001/sappers-4605c/us-central1/generateTableCertificates'; 
  const prodUrl = 'https://us-central1-sappers-4605c.cloudfunctions.net/generateTableCertificates';
  const url = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? testUrl : prodUrl;

  const generateTable = () => {
    console.log(url);
    setLoading(true);
    axios.get(url)
      .then((res) => {
        if (
          res && res.data
          && res.data.status
          && res.data.status === 'success'
        ) setSendSuccess(true);
        setLoading(false);
        console.log(res);
      });
  };

  return (
    <Dialog
      {...dialogProps}
      classes={{
        paper: cls.paper,
      }}
    >
      <DialogTitle className={cls.headerDialog}>
        Сертифікати
        <div>
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={() => generateTable()}
            disabled={loading}
          >
            Згенерувати звіт
            {loading && <CircularProgress size={20} className={cls.circ}/>}
          </Button>
          {sendSuccess && (
            <a
              className={cls.openTable}
              target="_blank"
              href="https://docs.google.com/spreadsheets/d/19Jedtz-jt---ydxPxYjEuB5StmAYSwEGMZd01PHv5Hc/edit?usp=sharing"
            >
              <Button
                variant="outlined"
                size="small"
                color="secondary"
              >
                Відкрити таблицю
              </Button>
            </a>
          )}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {
          keys.length
            ? keys.map((key, i) => (
              <Certificate
                key={key + i}
                email={key}
                renderData={state[key].renderData}
                user={state[key].user}
                showUser={state[key].showUser}
              />
            ))
            : 'Сертифікатів не знайдено!'
        }
      </DialogContent>
    </Dialog>
  );
}
