import React from 'react';
import { FormControl } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _, { isNumber } from 'lodash';
import {
  Container,
  Title,
  Message,
  useStyles,
} from './styles';
import { Questions } from '../../PassingTest/styles';

export const SUCCESS = 'Правильно';
export const ERROR = 'Не правильно';
export const SEMIERROR = 'Не правильно (Відповідь не повна)';

export default function Question({
  index,
  question = {
    responses: [],
  },
  type = 'radio',
  answersUser,
}) {
  let status = null;

  const statusResponse = (indexValue) => {
    // 0 because is radio type.
    const rightAnswer = question.answers[0];
    const userAnswer = answersUser[0];

    // if this right answer
    if (indexValue === rightAnswer) {
      // if user get right answer
      if (userAnswer === rightAnswer) {
        status = SUCCESS;
        return 'user_success';
      }

      return 'success';
    }

    // if it not userAnswer
    if (indexValue !== userAnswer) {
      return 'default';
    }

    // if it not right answer
    status = ERROR;
    return 'error';
  };

  const setStatusByCheckboxs = () => {
    const { answers } = question;
    const userAnswers = answersUser;

    const diff = _.difference(answers, userAnswers);

    // if full correct
    if (answers.length === userAnswers.length && diff.length === 0) {
      status = SUCCESS;
      return;
    }

    // if full incorrect
    if (diff.length === answers.length) {
      status = ERROR;
      return;
    }

    // if semi correct
    status = SEMIERROR;
  };

  const statusResponseChecked = (indexValue) => {
    const { answers } = question;
    const userAnswers = answersUser;

    // 0 can be right answer
    const isRightAnswer = isNumber(answers.find((index) => index === indexValue));
    const isUserAnswer = isNumber(userAnswers.find((index) => index === indexValue));

    // console.log(isRightAnswer, isUserAnswer, indexValue, userAnswers);

    if (isRightAnswer) {
      // if rightAnswer === userAnswer
      if (isUserAnswer) {
        return 'user_success';
      }

      return 'success';
    }

    if (!isUserAnswer) {
      return 'default';
    }

    return 'error';
  };

  const classes = useStyles();
  return (
    <Container>
      <Title>
        {index + 1}
        .
        {' '}
        { question.question }
      </Title>
      <FormControl component="fieldset">
        <Questions>
          {
            type === 'radio'
              ? (
                question.responses.map((response, ind) => {
                  const status = statusResponse(ind);

                  return (
                    <div key={ind}>
                      <FormControlLabel
                        control={(
                          <Radio
                            className={classes[status]}
                            disabled
                            checked={status !== 'default'}
                          />
                        )}
                        label={response}
                      />
                    </div>
                  );
                })
              )
              : (
                (() => {
                  setStatusByCheckboxs();

                  return question.responses.map((response, ind) => {
                    const status = statusResponseChecked(ind);

                    return (
                      <div key={ind}>
                        <FormControlLabel
                          control={(
                            <Checkbox
                              className={classes[status]}
                              disabled
                              checked={status !== 'default'}
                            />
                          )}
                          label={response}
                        />
                      </div>
                    );
                  });
                })()
              )
          }
        </Questions>
      </FormControl>
      <Message status={status}>
        {status}
      </Message>
    </Container>
  );
}
