export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const initialState = {};

export const notification = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATION: {
      const messages = { ...state };
      messages[payload.id] = payload.data;

      return { ...messages };
    }

    case SET_NOTIFICATIONS: {
      return { ...payload };
    }

    case REMOVE_NOTIFICATION: {
      const filteredKeys = Object.keys(state).filter((id) => id !== payload);
      const messages = {};

      filteredKeys.forEach((key) => messages[key] = state[key]);

      return { ...messages };
    }
    default:
      return state;
  }
};
