import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ContentLoader from 'cabinet/components/ContentLoader';
import { REG_TYPE } from 'utils/events/questionnaire';
import { useHistory } from 'react-router-dom';
import { saveQuestionnaireResult, setQuestionnaireById } from 'cabinet/store/actions/questionnaireActions';
import { updateUserStory } from 'cabinet/store/actions/userStoryActions';
import Questionnaire from '../Questionnaire';

const RegQuestionnaire = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, client } = useSelector((state) => state);
  const { regQuestionnaire: id } = config;
  const { questionnaire, user, userStory } = client;

  useEffect(() => {
    dispatch(setQuestionnaireById(id));

    // dispatch
    // eslint-disable-next-line
  }, [id]);

  const isLoading = !questionnaire;
  if (isLoading) return <ContentLoader />;

  const getData = (success) => {
    const { uid: userId } = user.user;
    const { title: questionnaireTitle, id: questionnaireId } = questionnaire;

    return {
      userId,
      success,
      questionnaireId,
      questionnaireTitle,
      type: REG_TYPE,
    };
  };

  const getNewUserStory = (success) => {
    const copy = { ...userStory };
    copy.regQuestionnaire = success;

    return copy;
  };

  const submitHandler = (answers) => {
    const { questions } = questionnaire;

    const data = {
      ...getData(true),
      answers: questions.map((question, i) => ({ ...question, value: answers[i] })),
    };

    const newUserStory = getNewUserStory(true);

    dispatch(saveQuestionnaireResult(data))
      .then(() => dispatch(updateUserStory(newUserStory)))
      .then(() => history.push('/'));
  };

  const cancelHandler = () => {
    const data = getData(false);

    const newUserStory = getNewUserStory(false);

    dispatch(saveQuestionnaireResult(data, false))
      .then(() => dispatch(updateUserStory(newUserStory)))
      .then(() => history.push('/'));
  };

  return (
    <Questionnaire
      questionnaire={questionnaire}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    />
  );
};

export default RegQuestionnaire;
