import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import TabPanel from './TabPanel';
import ListQuestions from './ListQuestions';
import CreatedAndEditForm from './CreatedAndEditForm';
import CreatedTest from './CreatedTest';

import {
  useStyles,
} from './styles';
import { getAllQuestions } from '../../store/actions/testQuestionAction';

const Tests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { questions, questionMessage, loadingQuestions } = useSelector((state) => state.admin.test);

  // state components
  const [value, setValue] = useState(0);
  const [showForm, setShowForm] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      history.push('/admin/tests');
    }
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getAllQuestions());

    // dispatch
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pathName = location.pathname;
    const hasTestPage = pathName.includes('/admin/test/');
    if (value === 1 && hasTestPage) {
      setValue(0);
    }

    if (pathName.includes('/created') || hasTestPage) {
      setShowForm(true);
    } else if (pathName.includes('/tests')) {
      setShowForm(false);
    }

    // value
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <>
      <Page
        title="Тести"
        headerButton={false}
      >
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab label="Список питань" />
            <Tab label="Присвоїти питання до лекції" />
          </Tabs>
          <Box>
            {/* Created Questions */}
            <TabPanel value={value} index={0}>
              <Grid container>
                <Grid item lg={3}>
                  <Box mb={2}>
                    <Button color="primary" variant="contained">
                      <Link to="/admin/tests/created" className={classes.buttonCreated}>Створити питання</Link>
                    </Button>
                  </Box>
                  <ListQuestions list={questions} loadingQuestions={loadingQuestions} />
                </Grid>
                <Grid item lg={9} className={classes.rightContent}>
                  {
                    questionMessage.message && (
                      <Box p={2}>
                        <Alert severity={questionMessage.type}>
                          { questionMessage.message }
                        </Alert>
                      </Box>
                    )
                  }
                  {
                    showForm && (
                      <CreatedAndEditForm />
                    )
                  }
                </Grid>
              </Grid>
            </TabPanel>

            {/* Created Test */}
            <TabPanel value={value} index={1}>
              <Grid container>
                <Grid item lg={12} className={classes.rightContent}>
                  <CreatedTest />
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Paper>
      </Page>
    </>
  );
};

export default Tests;
