import firebase from '../../../utils/fire';
import {
  SET_COURSES_LIST, SET_DELETE, SET_ADD_COURSE, SET_UPDATE_COURSE,
} from '../reducers/courses';

const db = firebase.firestore();

export const setCourse = (payload) => ({
  type: SET_COURSES_LIST,
  payload,
});

export const setDeleteCourse = (payload) => ({
  type: SET_DELETE,
  payload,
});

export const addCourse = (payload) => ({
  type: SET_ADD_COURSE,
  payload,
});

export const updateCourse = (payload) => ({
  type: SET_UPDATE_COURSE,
  payload,
});

export const getCoursesFromDataBase = () => async (dispatch) => {
  try {
    const coursesRef = db.collection('courses');
    const dataCourses = await coursesRef.get();
    const resultCourses = [];
    dataCourses.forEach((doc) => {
      resultCourses.push(doc.data());
    });
    dispatch(setCourse(resultCourses));
  } catch (error) {
    console.log(error);
  }
};

export const createCourse = (data) => async (dispatch) => {
  try {
    const result = await db.collection('courses').add({});
    const courseId = result.id;
    const updatedCourse = db.collection('courses').doc(courseId);
    await updatedCourse.update({
      courseId: result.id,
      lessonsIds: [1, 2, 3],
      testId: 'test Id',
      title: 'Title',
    });
    dispatch(addCourse({
      courseId,
      lessonsIds: [1, 2, 3],
      testId: 'test Id',
      title: 'Title',
    }));
  } catch (error) {
    console.log(error);
  }
};

export const updateCourses = (data, id) => async (dispatch) => {
  try {
    const coursesRef = db.collection('courses').doc(id);
    await coursesRef.update(data);
    dispatch(updateCourse({
      id,
      data,
    }));
  } catch (error) {
    console.log(error);
  }
};

export const deleteCourseFromDateBase = ({ courseId }) => async (dispatch) => {
  try {
    await db.collection('courses').doc(courseId).delete();
    dispatch(setDeleteCourse(courseId));
  } catch (error) {
    console.log(error);
  }
};
