export const C_SET_TEST_BLOCKED = 'C_SET_TEST_BLOCKED';
export const C_CLEAR_TEST_BLOCKED = 'C_CLEAR_TEST_BLOCKED';

const initialState = {
  test_blocked: null,
};

export const logsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case C_SET_TEST_BLOCKED:
      return { ...state, test_blocked: payload };
    case C_CLEAR_TEST_BLOCKED:
      return { ...state, test_blocked: null };
    default:
      return state;
  }
};
