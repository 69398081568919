export const C_SET_QUESTIONNAIRE = 'C_SET_QUESTIONNAIRE';

const initialState = null;

export const questionnaireReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case C_SET_QUESTIONNAIRE:
      return { ...payload };
    default:
      return state;
  }
};
