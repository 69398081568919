import {
  SET_LECTURE,
  SET_LOADING_LECTURE,
  SET_LECTURE_ITEM_DATA,
  SET_REMOVE_LECTURE,
} from '../reducers/lecture';
import firebase from '../../../utils/fire';
import { setMessage } from './messagesAction';

const db = firebase.firestore();

export const setAllLecture = (payload) => ({
  type: SET_LECTURE,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING_LECTURE,
  payload,
});

export const setLectureItemData = (payload) => ({
  type: SET_LECTURE_ITEM_DATA,
  payload,
});

export const removeLecture = (payload) => ({
  type: SET_REMOVE_LECTURE,
  payload,
});

export const getAllLectureInBase = () => async (dispatch) => {
  try {
    const lectureRef = db.collection('lectures');
    const arrayLecture = await lectureRef.get();
    const result = [];
    arrayLecture.forEach((item) => {
      result.push({
        ...item.data(),
        id: item.id,
      });
    });

    dispatch(setAllLecture(result));
  } catch (e) {
    console.log(e);
  }
};

export const saveLectureInDataBase = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { id } = await db.collection('lectures').add({});

    // save in db.
    const result = await db.collection('lectures').doc(id).set({ ...data, id });

    dispatch(setMessage({
      text: 'Лекцію успішно створено',
      variant: 'success',
    }));

    return result;
  } catch (e) {
    console.log(e);

    dispatch(setMessage({
      text: 'Сталась помилка при створенні лекції',
      variant: 'error',
    }));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getLectureData = (id) => async (dispatch) => {
  try {
    const lecturesRef = db.collection('lectures').doc(id);
    const resultData = await lecturesRef.get();
    dispatch(setLectureItemData({
      ...resultData.data(),
      id: resultData.id,
    }));
  } catch (e) {
    console.log(e);
  }
};

export const updatedLecture = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await db.collection('lectures').doc(data.id).update(data);

    dispatch(setMessage({
      text: 'Лекцію успішно оновлено',
      variant: 'success',
    }));
  } catch (e) {
    console.log(e);

    dispatch(setMessage({
      text: 'Сталась помилка під час оновлення лекції',
      variant: 'error',
    }));
  } finally {
    dispatch(setLoading(false));
  }
};

const lessonsRef = db.collection('lessons');

export const removeLectureInDateBase = (id) => async (dispatch, getState) => {
  try {
    const { lectures } = getState().admin.lecture;
    const lecture = lectures.find((lecture) => lecture.id === id);

    if (lecture.lessonId) {
      const lessonResult = await lessonsRef.doc(lecture.lessonId).get();
      const lesson = lessonResult.data();
      lesson.lectures = lesson.lectures.filter((id) => id !== lecture.id);

      await lessonsRef.doc(lesson.id).set(lesson);
    }

    await db.collection('lectures').doc(id).delete();
    dispatch(removeLecture(id));
  } catch (e) {
    console.log(e);
  }
};
