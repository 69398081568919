import React from 'react';
import ContentLoader from 'cabinet/components/ContentLoader';
import { saveQuestionnaireResult } from 'cabinet/store/actions/questionnaireActions';
import { EXAM_TYPE } from 'utils/events/questionnaire';
import { updateUserStory } from 'cabinet/store/actions/userStoryActions';
import { useHistory } from 'react-router-dom';
import { useCourseQuestionnaire } from './useCourseQuestionnaire';
import Questionnaire from '../Questionnaire';

const CourseQuestionnaire = () => {
  const {
    isLoading, questionnaire, course, user, dispatch, userStory,
  } = useCourseQuestionnaire();
  const history = useHistory();

  if (isLoading) return <ContentLoader />;

  const { courseId } = course;

  const getData = (success) => {
    const { title: courseTitle } = course;
    const { id: questionnaireId, title: questionnaireTitle } = questionnaire;
    const { uid: userId } = user.user;

    return {
      userId,
      courseId,
      courseTitle,
      questionnaireId,
      questionnaireTitle,
      type: EXAM_TYPE,
      success,
    };
  };

  const getUserStoryWithQuestionnaireStatus = (success) => {
    const copyUserStory = { ...userStory };
    const { courses } = copyUserStory;
    const course = courses[courseId];

    course.questionnaire = success;

    return copyUserStory;
  };

  const submitHandler = (answers) => {
    const data = {
      ...getData(true),
      answers: questionnaire.questions.map((question, i) => ({ ...question, value: answers[i] })),
    };

    const newUserStory = getUserStoryWithQuestionnaireStatus(true);

    dispatch(saveQuestionnaireResult(data))
      .then(() => dispatch(updateUserStory(newUserStory)))
      .then(() => history.push(`/${courseId}/test`));
  };

  const cancelHandler = () => {
    const data = getData(false);

    const newUserStory = getUserStoryWithQuestionnaireStatus(false);

    dispatch(saveQuestionnaireResult(data, false))
      .then(() => dispatch(updateUserStory(newUserStory)))
      .then(() => history.push(`/${courseId}/test`));
  };

  return (
    <Questionnaire
      questionnaire={questionnaire}
      onSubmit={submitHandler}
      onCancel={cancelHandler}
    />
  );
};

export default CourseQuestionnaire;
