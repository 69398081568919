export const C_SET_LECTURES = 'C_SET_LECTURES';
export const C_SET_LECTURE = 'C_SET_LECTURE';

const initialState = null;

export const lecturesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case C_SET_LECTURES:
      return [...payload];
    default:
      return state;
  }
};
