import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const FireBaseApp = firebase.initializeApp(config);

// for local cloud functions
// FireBaseApp.functions().useFunctionsEmulator('http://localhost:5001');

export const db = FireBaseApp.firestore();
export const questionnairesCollection = db.collection('questionnaires');
export const questionnaireResultsCollection = db.collection('questionnairesResults');
export const eventsCollection = db.collection('events');
export const resultsCollection = db.collection('resultsTest');
export const configsCollection = db.collection('config');

// not in all of app
export const userStoryCollection = db.collection('userstory');

export const mapResultToDocs = (result) => result.docs.map((doc) => doc.data());

export default FireBaseApp;
